import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import TablePagination from "./TablePagination";
import classes from "../Styles/general.module.css";
import LoadingState from "./LoadingState";

export const TableView = (props) => {
  const { t, tableOptions } = props;
  const { openDialog, rowsData, colomnsData, loading, paginationOptions } =
    tableOptions;
  useEffect(() => {}, [tableOptions.dial]);
  return (
    <TableContainer
      component={Paper}
      style={{
        width: openDialog ? "60%" : "100%",
        transition: "width 0.5s ease",
        overflow: "hidden",
        overflowX: "auto",
        boxShadow: "none",
      }}
    >
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {rowsData?.map((label, i, arrayOfData) => {
              return (
                <TableCell
                  key={i}
                  className={
                    i === 0 || i === arrayOfData.length - 1
                      ? classes.tableRow
                      : classes.tableCellFullBorder
                  }
                >
                  <Typography className={classes.textBold14}>
                    {t(label)}
                  </Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {colomnsData?.length && !loading ? (
            // array of values from the rows data
            colomnsData.map((data, i) => {
              const tableCellResults = data.map((item, j, arrayOfData) => {
                return (
                  <TableCell
                    key={j}
                    className={
                      j === 0 || j === arrayOfData.length - 1
                        ? classes.tableRow
                        : classes.tableCellFullBorder
                    }
                  >
                    <Typography className={classes.textSemiBold14}>
                      {typeof item === "string" ? t(item) : item}
                    </Typography>
                  </TableCell>
                );
              });

              return <TableRow key={i}>{tableCellResults}</TableRow>;
            })
          ) : (
            <TableRow>
              <TableCell
                colSpan={rowsData?.length}
                align="center"
                className={classes.tableRow}
              >
                {loading ? <LoadingState /> : t("Records Not Found")}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {colomnsData?.length ? (
        <TablePagination
          t={t}
          rowsPerPage={paginationOptions?.rowsPerPage}
          currentPage={paginationOptions?.page}
          totalPages={paginationOptions?.totalPages}
          handlePageChange={paginationOptions?.handlePageChange}
          handleRowsPerPage={paginationOptions?.handleRowsPerPage}
        />
      ) : null}
    </TableContainer>
  );
};
