import { TextField } from "@mui/material";
import classes from "../Styles/general.module.css";

const SearchFilter = ({ t, searchValue, handleSearchValue, reassignStyle }) => {
  return (
    <TextField
      autoComplete={"false"}
      className={`${classes.searchField} ${reassignStyle?.width}`}
      name="searchData"
      value={searchValue}
      onChange={handleSearchValue}
      variant="outlined"
      placeholder={
        reassignStyle?.placeholder
          ? t(reassignStyle.placeholder)
          : t("Search...")
      }
      sx={{
        "& fieldset": { border: "none" },
      }}
      InputProps={{
        className: classes.inputSearchField,
        classes: { input: classes.inputSearchOnly },
        startAdornment: (
          <svg
            style={{ margin: "0 8px 0 0" }}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M16.1479 15.352L12.6273 11.8322C13.6477 10.6071 14.1566 9.03583 14.048 7.44518C13.9394 5.85453 13.2217 4.36699 12.0443 3.29199C10.8668 2.21699 9.32029 1.63731 7.72635 1.67354C6.13241 1.70976 4.6138 2.3591 3.48642 3.48648C2.35904 4.61386 1.7097 6.13247 1.67348 7.72641C1.63725 9.32035 2.21693 10.8669 3.29193 12.0443C4.36692 13.2218 5.85447 13.9394 7.44512 14.048C9.03577 14.1566 10.6071 13.6478 11.8321 12.6274L15.3519 16.1479C15.4042 16.2002 15.4663 16.2417 15.5345 16.2699C15.6028 16.2982 15.676 16.3128 15.7499 16.3128C15.8238 16.3128 15.897 16.2982 15.9653 16.2699C16.0336 16.2417 16.0956 16.2002 16.1479 16.1479C16.2001 16.0957 16.2416 16.0336 16.2699 15.9654C16.2982 15.8971 16.3127 15.8239 16.3127 15.75C16.3127 15.6761 16.2982 15.6029 16.2699 15.5346C16.2416 15.4663 16.2001 15.4043 16.1479 15.352ZM2.81242 7.87498C2.81242 6.87371 3.10933 5.89493 3.6656 5.0624C4.22188 4.22988 5.01253 3.58101 5.93758 3.19784C6.86263 2.81467 7.88053 2.71441 8.86256 2.90975C9.84459 3.10509 10.7466 3.58725 11.4546 4.29525C12.1626 5.00325 12.6448 5.9053 12.8401 6.88733C13.0355 7.86936 12.9352 8.88726 12.5521 9.81231C12.1689 10.7374 11.52 11.528 10.6875 12.0843C9.85497 12.6406 8.87618 12.9375 7.87492 12.9375C6.53271 12.936 5.24591 12.4021 4.29683 11.4531C3.34775 10.504 2.81391 9.21718 2.81242 7.87498Z"
              fill="#707070"
            />
          </svg>
        ),
      }}
    />
  );
};

export default SearchFilter;
