import axios from "axios";

import Cookies from "js-cookie";
import Configuration from "../Constant/configAuth";

const RequestHttpAuth = axios.create({
  baseURL: Configuration.urlMiddleware,
});

RequestHttpAuth.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error?.response?.data?.response_message
        ?.toLowerCase()
        .includes("token authorization")
    ) {
      Cookies.remove("access_token");
      Cookies.remove("user_email");
      Cookies.remove("mitra_id");
      Cookies.remove("client_id");
      Cookies.remove("client_email");
      Cookies.remove("mitra_email");
      localStorage.clear();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default RequestHttpAuth;
