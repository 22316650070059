import { Card, IconButton, Typography } from "@mui/material";
import classes from "../Styles/general.module.css";
import { useState } from "react";
import ExpandMoreIcon from "../Icon/ExpandMoreIcon";
import ExpandLessIcon from "../Icon/ExpandLessIcon";
import { useTranslation } from "react-i18next";
import strings from "../Constant/Locales/Strings/DashboardStrings";

const CardInfo = ({ cardData }) => {
  const [expandData, setExpandData] = useState(false);
  const { t } = useTranslation("Dashboard");

  return (
    <div className={classes.divCard}>
      {cardData?.length > 0 &&
        cardData.map((data, index) => {
          return (
            <Card key={index} className={classes.card}>
              <div className={classes.cardContainer}>
                <div className={classes.cardData}>
                  <div>
                    <Typography className={classes.textBold16}>
                      {data.type}
                    </Typography>
                    <Typography className={classes.textGray14Color60}>
                      {t(strings.textYearlyTotalActivity)} {data.type}
                    </Typography>
                  </div>
                  <Typography className={classes.textBold36}>
                    {data.count}
                  </Typography>
                </div>
                {/* Kalau ada detail, akan muncul expand action */}
                {data.detail && (
                  <div className={classes.divIconEnd}>
                    <IconButton onClick={() => setExpandData(!expandData)}>
                      {expandData ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  </div>
                )}
              </div>

              {/* Kalau ada detail, akan muncul expand action */}
              <div className={classes.detailCard}>
                {expandData &&
                  data.detail?.map((data, index) => {
                    return (
                      <div key={index} className={classes.detailTextCard}>
                        <Typography
                          className={
                            !data.amount
                              ? classes.textBold12
                              : classes.textGray12Color60
                          }
                        >
                          {data.label}
                        </Typography>
                        <Typography className={classes.textBold12}>
                          {data.amount || null}
                        </Typography>
                      </div>
                    );
                  })}
              </div>
            </Card>
          );
        })}
    </div>
  );
};
export default CardInfo;
