import { Button } from "@mui/material";
import classes from "../Styles/general.module.css";
import DeleteIcon from "../Icon/DeleteIcon";
import { useTranslation } from "react-i18next";
import EditWhiteIcon from "../Icon/EditWhiteIcon";

const ButtonAction = (props) => {
  const { text, handleAction, translation } = props;
  const { t } = useTranslation(translation);
  return (
    <>
      <Button className={classes.buttonAction} onClick={handleAction}>
        {t(text)}
      </Button>
    </>
  );
};

const DeleteButton = ({ handleOpenDialog, text }) => {
  return (
    <Button
      onClick={handleOpenDialog}
      className={classes.deleteButton}
      fullWidth
    >
      <DeleteIcon />
      {text}
    </Button>
  );
};

const EditButton = ({ handleOpenDialog, text }) => {
  return (
    <Button
      onClick={handleOpenDialog}
      className={classes.containedButton}
      fullWidth
    >
      <EditWhiteIcon />
      {text}
    </Button>
  );
};

export { ButtonAction, DeleteButton, EditButton };
