import {
  Button,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import classes from "../Styles/general.module.css";
import { useEffect, useState } from "react";
import i18n from "../../i18n";

const LanguageSettings = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [selectedLanguage, setSelectedLanguage] = useState("Bahasa");
  const languages = [
    { code: "Bahasa", name: "Bahasa" },
    { code: "English", name: "English" },
    // Add more languages as needed
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    i18n.changeLanguage("id");
    localStorage.setItem("lang", "id");
  }, []);

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    if (language === "English") {
      i18n.changeLanguage("en");
      localStorage.setItem("lang", "en");
    } else if (language === "Bahasa") {
      i18n.changeLanguage("id");
      localStorage.setItem("lang", "id");
    }
    handleClose();
  };

  const showLanguageButton = () => {
    return (
      <div>
        <Button
          className={classes.languageButton}
          aria-describedby={id}
          onClick={handleClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.747 9.41505 20.7188 6.93683 18.891 5.109C17.0632 3.28116 14.585 2.25298 12 2.25ZM20.25 12C20.2507 12.7608 20.1456 13.5181 19.9378 14.25H16.3275C16.5575 12.7588 16.5575 11.2412 16.3275 9.75H19.9378C20.1456 10.4819 20.2507 11.2392 20.25 12ZM9.5625 15.75H14.4375C13.9572 17.3237 13.1235 18.7667 12 19.9688C10.877 18.7664 10.0433 17.3235 9.5625 15.75ZM9.19688 14.25C8.93939 12.7611 8.93939 11.2389 9.19688 9.75H14.8106C15.0681 11.2389 15.0681 12.7611 14.8106 14.25H9.19688ZM3.75 12C3.74935 11.2392 3.85442 10.4819 4.06219 9.75H7.6725C7.4425 11.2412 7.4425 12.7588 7.6725 14.25H4.06219C3.85442 13.5181 3.74935 12.7608 3.75 12ZM14.4375 8.25H9.5625C10.0428 6.67632 10.8765 5.2333 12 4.03125C13.1231 5.23361 13.9567 6.67653 14.4375 8.25ZM19.3434 8.25H16.0041C15.5832 6.70585 14.8738 5.25532 13.9134 3.975C15.0738 4.25375 16.1602 4.7801 17.098 5.51799C18.0359 6.25588 18.8032 7.18784 19.3472 8.25H19.3434ZM10.0866 3.975C9.12619 5.25532 8.41683 6.70585 7.99594 8.25H4.65282C5.19682 7.18784 5.96407 6.25588 6.90196 5.51799C7.83985 4.7801 8.92621 4.25375 10.0866 3.975ZM4.65282 15.75H7.99594C8.41683 17.2941 9.12619 18.7447 10.0866 20.025C8.92621 19.7463 7.83985 19.2199 6.90196 18.482C5.96407 17.7441 5.19682 16.8122 4.65282 15.75ZM13.9134 20.025C14.8738 18.7447 15.5832 17.2941 16.0041 15.75H19.3472C18.8032 16.8122 18.0359 17.7441 17.098 18.482C16.1602 19.2199 15.0738 19.7463 13.9134 20.025Z"
              fill="#707070"
            />
          </svg>

          <Typography className={classes.textRegular14}>
            {selectedLanguage}
          </Typography>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M13.3538 6.35375L8.35375 11.3538C8.30732 11.4002 8.25217 11.4371 8.19147 11.4623C8.13077 11.4874 8.06571 11.5004 8 11.5004C7.9343 11.5004 7.86923 11.4874 7.80853 11.4623C7.74783 11.4371 7.69269 11.4002 7.64625 11.3538L2.64625 6.35375C2.55243 6.25993 2.49973 6.13269 2.49973 6C2.49973 5.86732 2.55243 5.74007 2.64625 5.64625C2.74007 5.55243 2.86732 5.49973 3 5.49973C3.13269 5.49973 3.25993 5.55243 3.35375 5.64625L8 10.2931L12.6463 5.64625C12.6927 5.5998 12.7479 5.56295 12.8086 5.53781C12.8693 5.51267 12.9343 5.49973 13 5.49973C13.0657 5.49973 13.1308 5.51267 13.1915 5.53781C13.2521 5.56295 13.3073 5.5998 13.3538 5.64625C13.4002 5.69271 13.4371 5.74786 13.4622 5.80856C13.4873 5.86925 13.5003 5.93431 13.5003 6C13.5003 6.0657 13.4873 6.13076 13.4622 6.19145C13.4371 6.25215 13.4002 6.3073 13.3538 6.35375Z"
              fill="#707070"
            />
          </svg>
        </Button>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <List className={classes.listItemLanguage}>
            {languages.map((language) => (
              <ListItemButton
                key={language.code}
                onClick={() => handleLanguageChange(language.code)}
                selected={language.code === selectedLanguage}
                className={classes.itemLanguageButton}
              >
                <ListItemText primary={language.name} />
              </ListItemButton>
            ))}
          </List>
        </Popover>
      </div>
    );
  };

  return showLanguageButton();
};

export default LanguageSettings;
