import React from "react";
import classes from "../Styles/general.module.css";
import SearchFilter from "./SearchFilter";
import ExportData from "./ExportData";
import { Button } from "@mui/material";
import FilterDate from "./FilterDate";
import FilterProduct from "./FilterProduct";
import { useTranslation } from "react-i18next";

export const UserAction = (props) => {
  const { translation, action } = props;
  const { t } = useTranslation(translation);
  return (
    <div className={classes.divFilter}>
      {/* left side */}
      <div className={classes.divRowLeft}>
        {action?.search?.isComponent ? (
          <SearchFilter
            t={t}
            reassignStyle={action?.search?.reassignStyle}
            searchValue={action?.search?.value}
            handleSearchValue={action?.search?.handleChangeValue}
          />
        ) : null}

        {action?.date?.isComponent ? (
          <FilterDate
            reassignStyle={action?.date?.reassignStyle}
            start_date={action?.date?.start_date}
            end_date={action?.date?.end_date}
            selected_value={action?.date.selected_value}
            handleChangeRadioButtonDateRange={
              action?.date?.handleChangeRadioButtonDateRange
            }
            handleChangeCustomDateRange={
              action?.date?.handleChangeCustomDateRange
            }
            handleChangeDate={action?.date?.handleChangeDate}
            handleClick={action?.date?.handleClick}
            handleClose={action?.date?.handleClose}
            anchorEl={action?.date?.anchorEl}
            translation={action?.date?.translation}
          />
        ) : null}

        {action?.product?.isComponent ? (
          <FilterProduct
            t={t}
            reassignStyle={action?.product?.reassignStyle}
            data={action?.product?.data}
            value={action?.product?.value}
            handleChangeValue={action?.product?.handleChangeValue}
          />
        ) : null}
      </div>

      {/* right side */}
      <div className={classes.divRowRight}>
        {action?.export?.isComponent ? (
          <ExportData
            openExportData={action?.export?.open}
            handleOpenClose={action?.export?.handleOpenClose}
            handleExportData={action?.export?.handleData}
          />
        ) : null}

        {action?.button?.isComponent ? (
          <Button
            className={classes.containedButtonSmall}
            onClick={action?.button?.handleOpen}
          >
            {action?.button?.icon}
            {t(action?.button?.name)}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
