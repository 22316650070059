import Cookies from "js-cookie";
import removeAllCookies from "./removeAllCookies";
import { redirect } from "react-router-dom";
import encryptedSecretKey from "./encryptedSecretKey";

export const isLogin = () => {
  const accessToken = Cookies.get("access_token");
  if (accessToken) return accessToken;
  return null;
};

export const logOut = () => {
  // Call the function to remove all cookies
  removeAllCookies();
  localStorage.clear();
};

export const isAuthenticated = async () => {
  const accessToken = Cookies.get("access_token");
  if (!accessToken) throw redirect("/");
  return null;
};

export const getAuthenticatedServerRequest = () => {
  return {
    Authorization: Cookies.get("access_token"),
    "X-Req-Inapass": encryptedSecretKey(),
    timestamp: Math.floor(new Date().getTime() / 1000),
  };
};

export const generateId = () => {
  return Math.random().toString(36).substr(2, 8);
};
