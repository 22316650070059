import { useEffect, useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { Typography } from "@mui/material";
import classes from "../Styles/dashboard.module.css";
import { CustomPieTooltip, getColor } from "./TooltipChart";
import SelectOptions from "../../General/Component/SelectOptions";
import strings from "../../General/Constant/Locales/Strings/DashboardStrings";
import { getDataBasedOnCustomPeriod } from "../Helper/getDashboardData";
import { useTranslation } from "react-i18next";

const CardPieChart = () => {
  const { t } = useTranslation("Dashboard");
  const [selectedPeriod, setSelectedPeriod] = useState("Monthly");
  const [dataProduct, setDataProduct] = useState([]);

  useEffect(() => {
    getData();
  }, [selectedPeriod]);

  const getData = () => {
    getDataBasedOnCustomPeriod(selectedPeriod, setDataProduct);
  };

  const handleSelectedPeriod = (e) => {
    setSelectedPeriod(e.target.value);
  };

  const showDetailPieData = () => {
    return dataProduct.map((data, index) => {
      return (
        <div key={index} className={classes.tooltipRowItem}>
          <div
            className={classes.tooltipCircle}
            style={{ backgroundColor: getColor(data.type) }}
          />
          <div className={classes.tooltipRowItem}>
            <div className={classes.textGrayRegular14Color60}>{data.type}</div>
            <div className={classes.textBold14}>{data.count}</div>
          </div>
        </div>
      );
    });
  };

  const showPeriodTranslation = () => {
    if (selectedPeriod === "Daily") {
      return t(strings.textDaily);
    } else if (selectedPeriod === "Weekly") {
      return t(strings.textWeekly);
    } else if (selectedPeriod === "Monthly") {
      return t(strings.textMonthly);
    } else return null;
  };
  return (
    <div className={classes.card} style={{ width: "48%" }}>
      <div className={classes.spaceBetweenRow} style={{ width: "100%" }}>
        <Typography className={classes.textBold16}>
          {t(strings.textActivitySummary)}
        </Typography>
        <div>
          <SelectOptions
            optionList={[
              { id: "Daily", label: t(strings.textDaily) },
              { id: "Weekly", label: t(strings.textWeekly) },
              { id: "Monthly", label: t(strings.textMonthly) },
            ]}
            selectedItem={showPeriodTranslation()}
            handleSelectOption={handleSelectedPeriod}
            placeholder={t(strings.textPlaceholderSe)}
          />
        </div>
      </div>
      <div style={{ width: "100%", height: "250px" }}>
        <ResponsiveContainer className={classes.responsiveChartContainer}>
          <PieChart>
            <Pie
              data={dataProduct}
              cx="50%"
              cy="50%"
              innerRadius={"50%"}
              outerRadius={"70%"}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="count"
              nameKey="type"
            >
              {dataProduct.map((data, index) => (
                <Cell key={`cell-${index}`} fill={getColor(data.type)} />
              ))}
            </Pie>
            <Tooltip content={<CustomPieTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      </div>

      <div className={classes.divDetailPie}> {showDetailPieData()}</div>
      <div>
        <Typography className={classes.textGrayRegular14Color60}>
          *{t(strings.textAllDataDisplayedBy)} {showPeriodTranslation()}
        </Typography>
      </div>
    </div>
  );
};

export default CardPieChart;
