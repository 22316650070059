import React from "react";
import { Tab, Tabs } from "@mui/material";
import classes from "../Styles/general.module.css"; // Import CSS module

const TabPanels = ({ value, tabLabel, listTab, handleChangeTab }) => {
  return (
    <div className={classes.divTabPanelContainer}>
      <Tabs
        value={value}
        onChange={handleChangeTab}
        variant="scrollable"
        scrollButtons={false}
        aria-label={tabLabel}
        TabIndicatorProps={{ style: { display: "none" } }}
        className={classes.tabPanel}
      >
        {listTab?.map((item, index) => (
          <Tab
            key={index}
            label={item.label || item.provider_name || `Tab ${index + 1}`}
            value={index}
            className={
              value === index ? classes.tabItemActive : classes.tabItem
            }
          />
        ))}
      </Tabs>
    </div>
  );
};

export default TabPanels;
