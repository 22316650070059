const ExpandMoreIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
  >
    <path
      d="M15.6898 7.14798L10.0648 12.773C10.0125 12.8253 9.95051 12.8668 9.88222 12.8951C9.81393 12.9234 9.74074 12.938 9.66682 12.938C9.5929 12.938 9.5197 12.9234 9.45141 12.8951C9.38313 12.8668 9.32109 12.8253 9.26885 12.773L3.64385 7.14798C3.5383 7.04243 3.479 6.89927 3.479 6.75001C3.479 6.60074 3.5383 6.45759 3.64385 6.35204C3.7494 6.24649 3.89255 6.18719 4.04182 6.18719C4.19108 6.18719 4.33424 6.24649 4.43979 6.35204L9.66682 11.5798L14.8938 6.35204C14.9461 6.29978 15.0082 6.25832 15.0764 6.23004C15.1447 6.20175 15.2179 6.18719 15.2918 6.18719C15.3657 6.18719 15.4389 6.20175 15.5072 6.23004C15.5755 6.25832 15.6375 6.29978 15.6898 6.35204C15.742 6.4043 15.7835 6.46634 15.8118 6.53463C15.8401 6.60291 15.8546 6.6761 15.8546 6.75001C15.8546 6.82392 15.8401 6.8971 15.8118 6.96539C15.7835 7.03367 15.742 7.09571 15.6898 7.14798Z"
      fill="#707070"
    />
  </svg>
);

export default ExpandMoreIcon;
