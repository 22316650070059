import RequestHttpGatewayInternal from "../../General/Helper/RequestHttpGatewayInternal";
import { getAuthenticatedServerRequest } from "../../General/Helper/auth";

const getDataBasedOnCustomPeriod = async (period, setDataProduct) => {
  const result = await RequestHttpGatewayInternal({
    method: "GET",
    url: "/product-activity/dashboard/type?",
    headers: getAuthenticatedServerRequest(),
    params: {
      interval: period,
    },
  });

  setDataProduct(result.data.data);
  return null;
};

const getDataBasedOnYear = async (setDataYearly) => {
  const currentYear = new Date().getFullYear().toString();

  const result = await RequestHttpGatewayInternal({
    method: "GET",
    url: "/product-activity/dashboard?",
    headers: getAuthenticatedServerRequest(),
    params: {
      year: currentYear,
    },
  });

  setDataYearly(result.data.data);
  return null;
};

const getTotalData = async (setCardData) => {
  const result = await RequestHttpGatewayInternal({
    method: "GET",
    url: "/product-activity/dashboard/summary",
    headers: getAuthenticatedServerRequest(),
  });

  const dataObject = result.data.data;
  const dataArray = Object.entries(dataObject)
    .filter(([key]) => key !== "total") // Exclude "total" if not needed in the array
    .map(([key, value]) => ({
      type: key,
      count: value,
    }));
  setCardData(dataArray);
};

export { getDataBasedOnCustomPeriod, getDataBasedOnYear, getTotalData };
