import * as CryptoJS from "crypto-js";

const encryptedSecretKey = () => {
  const key = process.env.REACT_APP_SECRET_KEY_CRYPTO;
  const dataJSONDecrypt = {
    key: key,
  };
  const key2 = CryptoJS.enc.Utf8.parse(key);

  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(dataJSONDecrypt),
    key2,
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  const encryptedBase64Auth = CryptoJS.enc.Base64.stringify(
    CryptoJS.enc.Utf8.parse(encryptedData.toString())
  );

  return encryptedBase64Auth;
};
export default encryptedSecretKey;
