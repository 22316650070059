import Cookies from "js-cookie";
import RequestHttpGatewayInternal from "../../General/Helper/RequestHttpGatewayInternal";
import exportFromJSON from "export-from-json";

const getAllUserList = (
  page,
  rowsPerPage,
  isExport,
  exportType,
  searchValue,
  selectedStatus,
  startDate,
  endDate,
  setLoading,
  setUserList,
  setTotalPage,
  setOpenExportData,
  setDataNotification
) => {
  let currentDate = new Date();
  const timestamp = Math.floor(currentDate.getTime() / 1000);

  RequestHttpGatewayInternal.get(
    `user-access/list?page=${page}&limit=${rowsPerPage}&isExport=${isExport}&fullname=${searchValue}&status=${selectedStatus}&start_date=${startDate}&end_date=${endDate}`,
    {
      headers: {
        Authorization: Cookies.get("access_token"),
        "X-Req-Inapass": Cookies.get("X-Req-Inapass"),
        timestamp: timestamp,
      },
    }
  )
    .then((res) => {
      if (isExport) {
        const data = res.data.data;
        const fileName = "User List";
        exportFromJSON({ data, fileName, exportType });
      } else {
        const result = res.data.data;
        setUserList(result.items);
        setTotalPage(result.meta.totalPages);
      }
    })
    .catch((err) => {
      setDataNotification({
        open: true,
        status: "error",
        message: err.response.data.response_message,
      });
    })
    .finally(() => {
      setLoading(false);
      setOpenExportData(false);
    });
};

export default getAllUserList;
