import RequestHttpGatewayInternal from "../../General/Helper/RequestHttpGatewayInternal";
import strings from "../../General/Constant/Locales/Strings/MerchantManageStrings";
import { getAuthenticatedServerRequest } from "../../General/Helper/auth";

const addEditMerchant = async (
  t,
  setLoadingSubmit,
  setDataNotification,
  merchantData,
  getMerchant,
  handleCloseDialog,
  setUploadedData,
  openMerchantDialog
) => {
  setLoadingSubmit(true);
  const requestBodySubmit = {
    merchant_id: merchantData.merchantId, // mandatory
    email: merchantData.companyEmail, // mandatory
    company_name: merchantData.companyName, // mandatory
    pic_name: merchantData.picName, // optional
    address: merchantData.address, // optional
    logo_company: merchantData.imageURL,
    phone: merchantData.phoneNumber, // optional,
    flagSentEmail: true,
  };

  if (openMerchantDialog?.edit) {
    requestBodySubmit["merchant_id_new"] = merchantData.merchantIdNew;
    requestBodySubmit["email_new"] = merchantData.companyEmailNew;
  }
  try {
    const result = await RequestHttpGatewayInternal({
      method: openMerchantDialog?.edit ? "PUT" : "POST",
      url: openMerchantDialog?.edit ? `/merchant/edit` : `/merchant/register`,
      headers: getAuthenticatedServerRequest(),
      data: requestBodySubmit,
    });
    if (result.data.data) {
      setDataNotification({
        open: true,
        status: "success",
        message: t(strings.textSuccessRegistMerchant),
      });
      handleCloseDialog();
      setUploadedData({
        file: [],
        fileName: "",
      });
      getMerchant();
    }
  } catch (error) {
    setDataNotification({
      open: true,
      status: "error",
      message:
        error.response.data.error.response_message || "Service is Unavailable",
    });
  } finally {
    setLoadingSubmit(false);
  }
};

export default addEditMerchant;
