import { IconButton } from "@mui/material";
import classes from "../Styles/general.module.css";
import numberWithThousand from "../Helper/numberWithThousand";

const IncreaseDecreaseNumber = ({ value, fieldName, onChangeValue }) => {
  let stringValue = value?.toString();
  let numberValue = Number(stringValue?.replace(/[^,\d]/g, ""));

  const increaseNumber = () => {
    let fixedValue = (numberValue + 1).toString();

    onChangeValue(fieldName, numberWithThousand(fixedValue));
  };

  const decreaseNumber = () => {
    let fixedValue = (numberValue - 1).toString();

    onChangeValue(fieldName, numberWithThousand(fixedValue));
  };

  return (
    <div className={classes.divColumnNumber}>
      <IconButton sx={{ padding: 0 }} onClick={increaseNumber}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
        >
          <path
            d="M11.6848 9.05939C11.6441 9.10006 11.5959 9.13233 11.5428 9.15435C11.4897 9.17637 11.4327 9.1877 11.3752 9.1877C11.3177 9.1877 11.2608 9.17637 11.2077 9.15435C11.1546 9.13233 11.1063 9.10006 11.0657 9.05939L7.00024 4.99337L2.93477 9.05939C2.85268 9.14148 2.74134 9.1876 2.62524 9.1876C2.50915 9.1876 2.3978 9.14148 2.31571 9.05939C2.23362 8.97729 2.1875 8.86595 2.1875 8.74986C2.1875 8.63376 2.23362 8.52242 2.31571 8.44032L6.69071 4.06532C6.73134 4.02465 6.7796 3.99238 6.83271 3.97036C6.88582 3.94834 6.94275 3.93701 7.00024 3.93701C7.05774 3.93701 7.11467 3.94834 7.16778 3.97036C7.22089 3.99238 7.26914 4.02465 7.30977 4.06532L11.6848 8.44032C11.7255 8.48096 11.7577 8.52921 11.7797 8.58232C11.8018 8.63543 11.8131 8.69236 11.8131 8.74986C11.8131 8.80735 11.8018 8.86428 11.7797 8.91739C11.7577 8.9705 11.7255 9.01875 11.6848 9.05939Z"
            fill="#3C3C3C"
          />
        </svg>
      </IconButton>

      <IconButton sx={{ padding: 0 }} onClick={decreaseNumber}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
        >
          <path
            d="M11.6848 5.55977L7.30977 9.93477C7.26914 9.97545 7.22089 10.0077 7.16778 10.0297C7.11467 10.0518 7.05774 10.0631 7.00024 10.0631C6.94275 10.0631 6.88582 10.0518 6.83271 10.0297C6.7796 10.0077 6.73134 9.97545 6.69071 9.93477L2.31571 5.55977C2.23362 5.47768 2.1875 5.36634 2.1875 5.25024C2.1875 5.13415 2.23362 5.0228 2.31571 4.94071C2.3978 4.85862 2.50915 4.8125 2.62524 4.8125C2.74134 4.8125 2.85268 4.85862 2.93477 4.94071L7.00024 9.00673L11.0657 4.94071C11.1064 4.90006 11.1546 4.86782 11.2077 4.84582C11.2608 4.82382 11.3178 4.8125 11.3752 4.8125C11.4327 4.8125 11.4897 4.82382 11.5428 4.84582C11.5959 4.86782 11.6441 4.90006 11.6848 4.94071C11.7254 4.98136 11.7577 5.02962 11.7797 5.08273C11.8017 5.13584 11.813 5.19276 11.813 5.25024C11.813 5.30773 11.8017 5.36465 11.7797 5.41776C11.7577 5.47087 11.7254 5.51913 11.6848 5.55977Z"
            fill="#3C3C3C"
          />
        </svg>
      </IconButton>
    </div>
  );
};

export default IncreaseDecreaseNumber;
