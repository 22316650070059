export const createIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M16.475 4.16799L9.6 0.406271C9.41617 0.305195 9.20978 0.252197 9 0.252197C8.79022 0.252197 8.58383 0.305195 8.4 0.406271L1.525 4.17033C1.32866 4.27776 1.16477 4.43593 1.05043 4.62832C0.936094 4.82072 0.875508 5.04028 0.875 5.26408V12.736C0.875508 12.9598 0.936094 13.1793 1.05043 13.3717C1.16477 13.5641 1.32866 13.7223 1.525 13.8297L8.4 17.593C8.58369 17.6945 8.79013 17.7477 9 17.7477C9.20987 17.7477 9.41631 17.6945 9.6 17.593L16.475 13.8297C16.6713 13.7223 16.8352 13.5641 16.9496 13.3717C17.0639 13.1793 17.1245 12.9598 17.125 12.736V5.26408C17.1249 5.03988 17.0645 4.81983 16.9502 4.62699C16.8358 4.43414 16.6717 4.27561 16.475 4.16799ZM12.125 10.875V14.7883L9.625 16.1571V9.37033L15.875 5.94846V8.44846L12.45 10.3235C12.3511 10.3776 12.2687 10.4574 12.2115 10.5545C12.1543 10.6516 12.1244 10.7623 12.125 10.875ZM5.55 10.3281L2.125 8.45315V5.95315L8.375 9.37502V16.1617L5.875 14.7883V10.875C5.87475 10.7631 5.84445 10.6533 5.78728 10.5571C5.73012 10.4609 5.64817 10.3819 5.55 10.3281ZM5.49531 3.42111L8.69844 5.17502C8.79042 5.22535 8.89359 5.25173 8.99844 5.25173C9.10329 5.25173 9.20646 5.22535 9.29844 5.17502L12.5016 3.42111L15.1953 4.89533L9 8.28752L2.80156 4.89377L5.49531 3.42111ZM9 1.50002L11.2031 2.70627L9 3.9133L6.79688 2.70705L9 1.50002ZM2.125 9.87658L4.625 11.2453V14.1039L2.125 12.736V9.87658ZM13.375 14.1039V11.2453L15.875 9.87658V12.736L13.375 14.1039Z"
        fill="white"
      />
    </svg>
  );
};
