import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import AppID from "./General/Constant/Locales/ID/App.json";
import AppEN from "./General/Constant/Locales/EN/App.json";
import KycId from "./General/Constant/Locales/ID/Kyc.json";
import KycEn from "./General/Constant/Locales/EN/Kyc.json";

import TransactionID from "./General/Constant/Locales/ID/Transaction.json";
import TransactionEN from "./General/Constant/Locales/EN/Transaction.json";

import UserMagamentID from "./General/Constant/Locales/ID/User.json";
import UserManagementEN from "./General/Constant/Locales/EN/User.json";

import MerchantID from "./General/Constant/Locales/ID/Merchant.json";
import MerchantEN from "./General/Constant/Locales/EN/Merchant.json";

import ProductID from "./General/Constant/Locales/ID/Product.json";
import ProductEN from "./General/Constant/Locales/EN/Product.json";

import DashboardID from "./General/Constant/Locales/ID/Dashboard.json";
import DashboardEN from "./General/Constant/Locales/EN/Dashboard.json";

const resources = {
  id: {
    App: AppID,
    Transaction: TransactionID,
    Merchant: MerchantID,
    User: UserMagamentID,
    Product: ProductID,
    Kyc: KycId,
    Dashboard: DashboardID,
  },
  en: {
    App: AppEN,
    Transaction: TransactionEN,
    Merchant: MerchantEN,
    User: UserManagementEN,
    Product: ProductEN,
    Kyc: KycEn,
    Dashboard: DashboardEN,
  },
};

const localLang = localStorage.getItem("lang");

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localLang ? localLang : "id", // by default id

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
