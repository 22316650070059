import {
  Button,
  CircularProgress,
  Drawer,
  FormControl,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import strings from "../../General/Constant/Locales/Strings/UserManageStrings";
import classes from "../Styles/usermanage.module.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import numericValidation from "../../General/Helper/numericValidation";
import emailValidation from "../../General/Helper/emailValidation";
import userStatus from "../../General/Constant/List/userStatus";
import CloseIcon from "../../General/Component/CloseIcon";
import IndonesianFlagIcon from "../../General/Icon/IndonesianFlagIcon";

const AddEditUserDataDrawer = ({
  t,
  isEdit,
  loadingSubmit,
  openDialog,
  handleCloseDialog,
  userData,
  handleChangeUser,
  handleSubmitUserData,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [typePassword, setTypePassword] = useState("password");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    if (typePassword === "password") {
      setTypePassword("text");
    } else if (typePassword === "text") {
      setTypePassword("password");
    }
  };

  const handleDisabledClasses = () => {
    if (
      !userData.email ||
      !emailValidation(userData.email) ||
      (!isEdit && !userData.role) ||
      (!isEdit && userData.role === "Custom" && !userData.rolesName) ||
      (userData.phone && !numericValidation(userData.phone)) ||
      (userData.phone && userData.phone.length > 13) ||
      loadingSubmit
    ) {
      return `${classes.disabledContainedButton}`;
    } else {
      return `${classes.containedButton}`;
    }
  };

  const handleDisabledButton = () => {
    if (
      !userData.email ||
      !emailValidation(userData.email) ||
      (!isEdit && !userData.role) ||
      (!isEdit && userData.role === "Custom" && !userData.rolesName) ||
      (userData.phone && !numericValidation(userData.phone)) ||
      (userData.phone && userData.phone.length > 13) ||
      loadingSubmit
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validatePhoneNumber = (phoneNumber) => {
    if (phoneNumber) {
      if (!numericValidation(phoneNumber)) {
        return (
          <Typography className={classes.textAlert12}>
            {t(strings.textInvalidPhoneNumber)}
          </Typography>
        );
      } else if (phoneNumber.length > 13) {
        return (
          <Typography className={classes.textAlert12}>
            {t(strings.textInvalidPhoneNumberLength)}
          </Typography>
        );
      } else {
        return null;
      }
    }
  };

  const showFormField = () => {
    return (
      <div className={classes.divForm}>
        <div className={classes.divFormField}>
          <Typography className={classes.textBold14}>
            {t(strings.textFullName)}
          </Typography>
          <TextField
            fullWidth
            className={classes.field}
            variant="standard"
            type="text"
            name="fullName"
            value={userData.fullName || ""}
            placeholder={t(strings.textPlaceholderFullName)}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.textSemiBold14 },
            }}
            onChange={handleChangeUser}
          />
        </div>
        <div className={classes.divFormField}>
          <Typography className={classes.textBold14}>
            {t(strings.textEmail)}
          </Typography>
          <TextField
            fullWidth
            className={classes.field}
            placeholder={t(strings.textPlaceholderEmail)}
            variant="standard"
            type="email"
            name="email"
            value={userData.email || ""}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.textSemiBold14 },
            }}
            onChange={handleChangeUser}
          />
          {userData.email && !emailValidation(userData.email) && (
            <Typography className={classes.textAlert12}>
              {t(strings.textInvalidEmail)}
            </Typography>
          )}
          {!userData.email && (
            <Typography className={classes.textAlert12}>
              {t(strings.textEmailIsRequired)}
            </Typography>
          )}
        </div>
        <div className={classes.divFormField}>
          <Typography className={classes.textBold14}>
            {t(strings.textPassword)}
          </Typography>
          <TextField
            fullWidth
            className={classes.field}
            placeholder={t(strings.textPlaceholderPassword)}
            variant="standard"
            type={typePassword}
            name="password"
            value={userData.password || ""}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.textSemiBold14 },
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={
                      typePassword === "password"
                        ? t(strings.textViewPassword)
                        : t(strings.textHidePassword)
                    }
                    componentsProps={{
                      tooltip: { className: classes.tooltipStyle },
                    }}
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? (
                        <Visibility className={classes.visibilityIcon} />
                      ) : (
                        <VisibilityOff className={classes.visibilityIcon} />
                      )}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            onChange={handleChangeUser}
          />
        </div>
        <div className={classes.divFormField}>
          <Typography className={classes.textBold14}>
            {t(strings.textPhoneNumber)}
          </Typography>
          <TextField
            fullWidth
            className={classes.field}
            placeholder={t(strings.textPlaceholderPhone)}
            variant="standard"
            type="text"
            name="phone"
            value={userData.phone || ""}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.textSemiBold14 },
              startAdornment: (
                <div className={classes.startAdornmentNumber}>
                  <IndonesianFlagIcon />
                  <Typography className={classes.textBold14}>+62</Typography>
                </div>
              ),
            }}
            onChange={handleChangeUser}
          />
          {validatePhoneNumber(userData.phone)}
        </div>
        <div className={classes.divFormField}>
          <Typography className={classes.textBold14}>
            {t(strings.textAddress)}
          </Typography>
          <TextField
            fullWidth
            className={classes.addressField}
            placeholder={t(strings.textPlaceholderAddress)}
            variant="standard"
            type="text"
            name="address"
            multiline
            maxRows={4}
            value={userData.address || ""}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.textSemiBold14 },
            }}
            onChange={handleChangeUser}
          />
        </div>

        {isEdit && (
          <div className={classes.divFormField}>
            <Typography className={classes.textBold14}>
              {t(strings.textStatus)}
            </Typography>
            <FormControl fullWidth>
              <Select
                value={userData.status || ""}
                onChange={handleChangeUser}
                name="status"
              >
                {userStatus().map((data) => {
                  return (
                    <MenuItem value={data.id} key={data.id}>
                      <ListItemText className={classes.textSemiBold14}>
                        {data.label}
                      </ListItemText>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        )}

        {!isEdit && (
          <>
            <div className={classes.divFormField}>
              <Typography className={classes.textBold14}>
                {t(strings.textRole)}
              </Typography>

              <FormControl fullWidth>
                <Select
                  fullWidth
                  value={userData.role || ""}
                  onChange={handleChangeUser}
                  name="role"
                  input={
                    <OutlinedInput
                      classes={{ input: classes.textSemiBold14 }}
                    />
                  }
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <Typography className={classes.placeholder}>
                          {t(strings.textPlaceholderSelectRole) ||
                            "Select Item..."}
                        </Typography>
                      );
                    }
                    return userData.role;
                  }}
                >
                  <MenuItem value={"Super Administrator"}>
                    <ListItemText className={classes.textSemiBold14}>
                      {t(strings.textSuperAdmin)}
                    </ListItemText>
                  </MenuItem>
                  <MenuItem value={"Custom"}>
                    <ListItemText className={classes.textSemiBold14}>
                      {t(strings.textCustom)}
                    </ListItemText>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            {userData.role === "Custom" && (
              <>
                <div className={classes.divFormField}>
                  <Typography className={classes.textBold14}>
                    {t(strings.textRoleName)}
                  </Typography>
                  <TextField
                    fullWidth
                    className={classes.field}
                    placeholder={t(strings.textPlaceholderRoleName)}
                    variant="standard"
                    type="text"
                    name="rolesName"
                    multiline
                    maxRows={4}
                    value={userData.rolesName || ""}
                    InputProps={{
                      disableUnderline: true,
                      classes: { input: classes.textSemiBold14 },
                    }}
                    onChange={handleChangeUser}
                  />
                </div>
              </>
            )}
          </>
        )}

        <Button
          fullWidth
          className={handleDisabledClasses()}
          onClick={() => handleSubmitUserData()}
          disabled={handleDisabledButton()}
        >
          {loadingSubmit ? <CircularProgress size={"1em"} /> : null}

          {isEdit ? t(strings.textEditData) : t(strings.textRegisterAccount)}
        </Button>
      </div>
    );
  };

  return (
    <Drawer
      anchor="right"
      open={openDialog}
      onClose={handleCloseDialog}
      variant="persistent"
    >
      <div className={classes.divBoxDrawer}>
        <div className={classes.divDialogEditTitle}>
          <div className={classes.divCloseEnd}>
            <CloseIcon handleCloseDialog={handleCloseDialog} />
          </div>
          <Typography className={classes.textBold16}>
            {isEdit
              ? t(strings.textEditUserData)
              : t(strings.textUserRegistrationAccount)}
            {}
          </Typography>
          <Typography className={classes.textGray16Color60}>
            {t(strings.textAddUserSubtitle)}
          </Typography>
        </div>
        <div className={classes.divDialogEdit}>
          <div>{showFormField()}</div>
        </div>
      </div>
    </Drawer>
  );
};

export default AddEditUserDataDrawer;
