import classes from "./Styles/merchantmanage.module.css";
import strings from "../General/Constant/Locales/Strings/MerchantManageStrings";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { withTranslation } from "react-i18next";
import getAllMerchantList from "./Helper/getAllMerchantList";
import { useEffect, useState } from "react";
import merchantManageHeaders from "./Constant/merchantManageHeaders";
import TablePagination from "../General/Component/TablePagination";
import LoadingState from "../General/Component/LoadingState";
import handleCloseNotif from "../General/Helper/handleCloseNotif";
import SnackbarNotification from "../General/Component/SnackbarNotification";
import AddEditMerchantDrawer from "./Component/AddEditMerchantDrawer";
import ThreeeDotsButton from "./Component/ThreeeDotsButton";
import uploadImage from "./Helper/uploadImage";
import { UserAction } from "../General/Component/UserAction";
import addEditProvider from "./Helper/addEditProvider";
import ViewEditMerchantProvider from "./Component/ViewEditMerchantProvider";
import addEditMerchant from "./Helper/addEditMerchant";
import AddPersonIcon from "../General/Icon/AddPersonIcon";
import DeletePopUp from "../General/Component/DeletePopUp";
import deleteProvider from "./Helper/deleteProvider";
import {
  validateInputProvider,
  validateInputThresholdMerchant,
} from "../E-KYC/Helper/validation";
import ViewEditMerchantThreshold from "./Component/ViewEditMerchantThreshold";
import addEditThreshold from "./Helper/addEditThreshold";

const MerchantManagement = (props) => {
  const { t } = props;
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [openMerchantDialog, setOpenMerchantDialog] = useState({
    edit: false,
    create: false,
  });
  const [valueTab, setValueTab] = useState(0);
  const [openProviderDialog, setOpenProviderDialog] = useState({
    view: false,
    edit: false,
    create: false,
    delete: false,
  });
  const [openThresholdDialog, setOpenThresholdDialog] = useState({
    view: false,
    edit: false,
    create: false,
  });
  const [openExportData, setOpenExportData] = useState(false);
  const [merchantList, setMerchantList] = useState([]);
  const [dataNotification, setDataNotification] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [uploadedData, setUploadedData] = useState({
    file: [],
    fileName: "",
  });
  const [merchantData, setMerchantData] = useState({
    merchantId: "",
    merchantIdNew: "",
    companyName: "",
    companyEmail: "",
    companyEmailNew: "",
    picName: "",
    phoneNumber: "",
    address: "",
    base64Image: "",
    imageURL: "",
  });

  let isExport = false;

  const [providerActionData, setProviderActionData] = useState({
    item: [
      {
        id: null,
        provider_name: "",
        secret_key: "",
        hash_key: "",
        system_id: "",
        apikey: "",
        token: "",
        password: "",
        channel_id: "",
        basic_price: "0",
        vat_merchant: "0",
        status: "false",
        merchantThreshold: null,
      },
    ],
    loading: false,
  });

  const [thresholdActionData, setThresholdActionData] = useState({
    item: [
      {
        providerId: "",
        thresholdValue: "",
        status: "false",
        description: "",
        provider_name: "",
        merchant_id: "",
      },
    ],
    loading: false,
  });

  const [action, setAction] = useState({
    search: {
      isComponent: true,
      reassignStyle: {
        placeholder: `Search by Merchant ID`,
      },
      value: "",
      handleChangeValue: ({ target }) => {
        setAction((data) => {
          if (target.value.length === 0 || target.value.length > 4) {
            getAllMerchantList(
              page,
              rowsPerPage,
              isExport,
              null,
              target.value,
              setLoading,
              setMerchantList,
              setTotalPage,
              setOpenExportData,
              setDataNotification
            );
          }
          return { ...data, search: { ...data.search, value: target.value } };
        });
      },
    },
    export: {
      isComponent: true,
      open: false,
      handleData: (exportType) => {
        isExport = true;
        setAction((data) => {
          getAllMerchantList(
            page,
            rowsPerPage,
            isExport,
            exportType,
            data.search.value,
            setLoading,
            setMerchantList,
            setTotalPage,
            setOpenExportData,
            setDataNotification
          );
          return { ...data, export: { ...data.export, open: false } };
        });
      },
      handleOpenClose: (value) =>
        setAction((data) => {
          return { ...data, export: { ...data.export, open: value } };
        }),
    },
    button: {
      isComponent: true,
      icon: <AddPersonIcon />,
      open: false,
      name: t(strings.textAddNewMerchant),
      handleOpen: () => {
        handleOpenMerchantDrawer("create", null);
        setAction((data) => {
          return { ...data, createData: { ...data.createData, open: true } };
        });
      },
      handleClose: () =>
        setAction((data) => {
          return { ...data, createData: { ...data.createData, open: false } };
        }),
    },
  });

  useEffect(() => {
    getMerchant();
    setUploadedData({
      file: [],
      fileName: "",
    });
  }, []);

  useEffect(() => {
    if (uploadedData.file) {
      handleImageLogo();
    }
  }, [uploadedData]);

  const getMerchant = async () => {
    await getAllMerchantList(
      page,
      rowsPerPage,
      isExport,
      null,
      action.search.value,
      setLoading,
      setMerchantList,
      setTotalPage,
      setOpenExportData,
      setDataNotification
    );
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    getAllMerchantList(
      newPage,
      rowsPerPage,
      isExport,
      null,
      searchValue,
      setLoading,
      setMerchantList,
      setTotalPage,
      setOpenExportData,
      setDataNotification
    );
  };

  const handleRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    getAllMerchantList(
      page,
      event.target.value,
      isExport,
      null,
      searchValue,
      setLoading,
      setMerchantList,
      setTotalPage,
      setOpenExportData,
      setDataNotification
    );
  };

  const showMerchantTable = () => {
    return (
      <TableContainer
        component={Paper}
        style={{
          width:
            openMerchantDialog.create ||
            openMerchantDialog.edit ||
            openMerchantDialog.view ||
            openProviderDialog.create ||
            openProviderDialog.edit ||
            openProviderDialog.view
              ? "60%"
              : "100%",
          transition: "width 0.5s ease",
          overflow: "hidden",
          overflowX: "auto",
          boxShadow: "none",
        }}
      >
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {merchantManageHeaders(props).map((data, index) => {
                return (
                  <TableCell
                    key={index}
                    className={
                      data.id > 1 &&
                      data.id < merchantManageHeaders(props).length
                        ? classes.tableCellFullBorder
                        : classes.tableRow
                    }
                  >
                    <Typography className={classes.textBold14}>
                      {data.label}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>{printBodyData()}</TableBody>
        </Table>
        <TablePagination
          t={t}
          rowsPerPage={rowsPerPage}
          currentPage={page}
          totalPages={totalPage}
          handlePageChange={handlePageChange}
          handleRowsPerPage={handleRowsPerPage}
        />
      </TableContainer>
    );
  };

  const printBodyData = () => {
    if (loading) {
      return <LoadingState />;
    } else {
      if (merchantList?.length > 0) {
        return merchantList?.map((data, index) => {
          return (
            <TableRow key={index}>
              <TableCell className={classes.tableRow}>
                <div className={classes.divLogoCompany}>
                  {data.logo_company ? (
                    <img
                      src={data.logo_company}
                      className={classes.logoCompany}
                      alt="company_logo"
                    />
                  ) : (
                    "-"
                  )}
                </div>
              </TableCell>
              <TableCell className={classes.tableCellFullBorder}>
                {data.merchant_id || "-"}
              </TableCell>
              <TableCell className={classes.tableCellFullBorder}>
                {data.company_name || "-"}
              </TableCell>
              <TableCell className={classes.tableCellFullBorder}>
                {data.email || "-"}
              </TableCell>
              <TableCell className={classes.tableCellFullBorder}>
                {data.address || "-"}
              </TableCell>
              <TableCell className={classes.tableCellFullBorder}>
                {data.merchant_provider?.length > 0
                  ? data.merchant_provider.map((data, index) => {
                      return <li key={index}>{data.provider_name}</li>;
                    })
                  : "-"}
              </TableCell>

              <TableCell className={classes.tableRow}>
                <ThreeeDotsButton
                  t={t}
                  handleOpenProviderDrawer={(user_action) =>
                    handleOpenProviderDrawerAction(user_action, {
                      merchant_id: data.merchant_id,
                      merchant_provider: data.merchant_provider,
                    })
                  }
                  handleOpenThresholdDrawer={(user_action) =>
                    handleOpenThresholdDrawerAction(user_action, {
                      ...data,
                    })
                  }
                  handleOpenMerchantDrawer={(user_action) =>
                    handleOpenMerchantDrawer(user_action, data)
                  }
                  optionData={data.merchant_provider}
                />
              </TableCell>
            </TableRow>
          );
        });
      } else {
        return (
          <TableRow>
            <TableCell colSpan={7} align="center" className={classes.tableRow}>
              {t(strings.textRecordsNotFound)}
            </TableCell>
          </TableRow>
        );
      }
    }
  };

  const handleChangeTab = (e, newValue) => {
    setValueTab(newValue);
  };

  const handleOpenMerchantDrawer = (action, selectedItem) => {
    if (action === "edit") {
      setOpenMerchantDialog({
        create: false,
        edit: true,
      });
      setMerchantData({
        merchantId: selectedItem.merchant_id || "",
        companyName: selectedItem.company_name || "",
        companyEmail: selectedItem.email || "",
        picName: selectedItem.pic_name || "",
        phoneNumber: selectedItem.phone || "",
        address: selectedItem.address || "",
        imageURL: selectedItem.logo_company || "",
      });
    } else if (action === "create") {
      setOpenMerchantDialog({
        create: true,
        edit: false,
      });
    }
  };

  const handleChangeMerchant = (e, newValue) => {
    if (e.target.name) {
      if (openMerchantDialog.edit) {
        if (e.target.name === "merchantId") {
          setMerchantData({
            ...merchantData,
            merchantIdNew: e.target.value,
          });
        } else if (e.target.name === "companyEmail") {
          setMerchantData({
            ...merchantData,
            companyEmailNew: e.target.value,
          });
        } else {
          setMerchantData({ ...merchantData, [e.target.name]: e.target.value });
        }
      } else {
        setMerchantData({ ...merchantData, [e.target.name]: e.target.value });
      }
    }
  };
  const handleImageLogo = () => {
    const acceptedFiles = [uploadedData.file];

    if (acceptedFiles[0].type?.includes("image")) {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          const binaryStr = reader.result;
          setMerchantData({
            ...merchantData,
            base64Image: binaryStr.split(",")[1],
          });
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const handleCloseDialog = () => {
    setOpenMerchantDialog({
      create: false,
      edit: false,
    });
    setMerchantData({
      merchantId: "",
      companyName: "",
      companyEmail: "",
      picName: "",
      phoneNumber: "",
      address: "",
      base64Image: "",
      imageURL: "",
    });
    setUploadedData({ file: [], fileName: "" });
  };

  const handleCloseViewProviderDialog = () => {
    setOpenProviderDialog({
      create: false,
      view: false,
      edit: false,
      delete: false,
    });
    setProviderActionData();
  };

  const handleSubmitUserData = () => {
    if (merchantData.base64Image) {
      uploadImage(
        t,
        merchantData,
        setMerchantData,
        setLoadingSubmit,
        setDataNotification,
        handleCloseDialog,
        getMerchant,
        setUploadedData,
        openMerchantDialog
      );
    } else {
      addEditMerchant(
        t,
        setLoadingSubmit,
        setDataNotification,
        merchantData,
        getMerchant,
        handleCloseDialog,
        setUploadedData,
        openMerchantDialog
      );
    }
  };

  const handleSubmitProviderData = async (row_index) => {
    const request = providerActionData.item[row_index];
    const isInvalid = validateInputProvider(request);
    let submitResult = null;
    if (isInvalid.length) {
      setDataNotification({
        open: true,
        status: "error",
        message: t(isInvalid[0]),
      });
    } else {
      setProviderActionData((pad) => ({ ...pad, loading: true }));
      const addResult = await addEditProvider(
        t,
        {
          merchant_id: request.merchant_id,
          merchant_provider: request,
        },
        setDataNotification
      );
      submitResult = addResult.getUpdatedMerchant.merchant_provider;

      if (addResult) {
        getMerchant();
        setProviderActionData(() => ({
          item: submitResult,
          loading: false,
        }));
        if (openProviderDialog.create) {
          handleOpenThresholdDrawerAction("create", request);
        }
      } else {
        setProviderActionData((data) => ({ ...data, loading: false }));
      }

      handleCloseProviderDrawerAction(
        submitResult,
        request.provider_name,
        row_index
      );
    }
  };

  const handleDeleteProvider = () => {
    const request = providerActionData?.item?.find((el) => el?.["is_delete"]);
    deleteProvider(
      t,
      request,
      setDataNotification,
      getMerchant,
      handleCloseViewProviderDialog
    );
  };

  const handleCloseNotification = () => {
    handleCloseNotif(setDataNotification);
  };

  const handleCloseDeleteDialog = () => {
    setOpenProviderDialog((data) => ({ ...data, delete: false }));
  };

  const handleOpenProviderDrawerAction = (action, selectedItem) => {
    if (action === "edit") {
      setOpenProviderDialog((od) => ({ ...od, edit: true }));
    } else if (action === "delete") {
      setProviderActionData((pad) => ({
        ...pad,
        item: pad.item.map((el) => {
          const result = el;
          if (el.id === selectedItem.id) {
            result["is_delete"] = true;
          }
          return result;
        }),
      }));

      setOpenProviderDialog((od) => ({ ...od, delete: true }));
    } else if (action === "create") {
      setProviderActionData({
        item: [
          {
            merchant_id: selectedItem.merchant_id,
            provider_name: "",
            secret_key: "",
            hash_key: "",
            system_id: "",
            apikey: "",
            token: "",
            password: "",
            channel_id: "",
            basic_price: "0",
            vat_merchant: "0",
            status: "false",
          },
        ],
        loading: false,
      });
      setOpenProviderDialog({
        create: true,
        view: false,
        edit: false,
        delete: false,
      });
      // setTableOptions((t) => ({ ...t, openMerchantDialog: true }));
    } else if (action === "view") {
      const viewResult = selectedItem?.merchant_provider?.map((item) => {
        const {
          id,
          provider_name,
          secret_key,
          hash_key,
          system_id,
          apikey,
          token,
          password,
          channel_id,
          basic_price,
          status,
          merchantThreshold,
        } = item;
        return {
          merchant_id: selectedItem.merchant_id,
          id,
          provider_name: provider_name || "",
          secret_key: secret_key || "",
          hash_key: hash_key || "",
          system_id: system_id || "",
          apikey: apikey || "",
          token: token || "",
          password: password || "",
          channel_id: channel_id || "",
          basic_price: `${basic_price}`,
          vat_merchant: `${item.vat_merchant * 100}`,
          status: `${status}` || "false",
          merchantThreshold: merchantThreshold || null,
        };
      });
      setProviderActionData((pad) => ({ ...pad, item: viewResult }));
      setOpenProviderDialog({
        view: true,
        edit: false,
        create: false,
        delete: false,
      });
    }
  };

  const handleCloseProviderDrawerAction = (
    newProvider,
    providerName,
    row_index
  ) => {
    if (!providerActionData.loading && openProviderDialog.edit === true) {
      const merchant_id = providerActionData.item[0]?.merchant_id;
      const viewResult = newProvider?.map((item, index) => {
        const {
          id,
          provider_name,
          secret_key,
          hash_key,
          system_id,
          apikey,
          token,
          password,
          channel_id,
          basic_price,
          status,
          merchantThreshold,
        } = item;

        if (provider_name === providerName) {
          setValueTab(index);
        }
        return {
          merchant_id: merchant_id,
          id,
          provider_name: provider_name || "",
          secret_key: secret_key || "",
          hash_key: hash_key || "",
          system_id: system_id || "",
          apikey: apikey || "",
          token: token || "",
          password: password || "",
          channel_id: channel_id || "",
          basic_price: `${basic_price}`,
          vat_merchant: `${item.vat_merchant * 100}`,
          status: `${status}` || "false",
          merchantThreshold: merchantThreshold || null,
        };
      });
      setProviderActionData((pad) => ({ ...pad, item: viewResult }));
      setOpenProviderDialog((od) => ({ ...od, edit: false }));
    } else {
      setOpenProviderDialog({
        create: false,
        view: false,
        edit: false,
        delete: false,
      });
    }
  };

  const handleChangeProviderValue = (fieldName, value, row) => {
    const result = providerActionData.item.map((data, index) => {
      if (row === index) {
        data[fieldName] = value;
      }
      return data;
    });

    setProviderActionData((pad) => ({
      ...pad,
      item: result,
    }));
  };

  const handleOpenThresholdDrawerAction = async (action, selectedItem) => {
    if (action === "edit") {
      setOpenThresholdDialog((od) => ({ ...od, edit: true }));
    } else if (action === "create") {
      setThresholdActionData({
        item: [
          {
            provider_name: selectedItem.provider_name,
            providerId: "",
            thresholdValue: "",
            status: "false",
            description: "",
            merchant_id: selectedItem.merchant_id,
          },
        ],
        loading: false,
      });
      setOpenThresholdDialog({
        create: true,
        view: false,
        edit: false,
      });
    } else if (action === "view") {
      const providerWithThreshold = selectedItem.merchant_provider.filter(
        (data) => data.merchantThreshold
      );
      setProviderActionData(() => ({
        item: providerWithThreshold,
      }));

      const merchantProvider = providerWithThreshold.map((data) => {
        return {
          ...data.merchantThreshold,
          provider_name: data.provider_name,
          providerId: data.id,
        };
      });

      setThresholdActionData(() => ({
        item: merchantProvider,
        loading: false,
      }));
      setOpenThresholdDialog({
        view: true,
        edit: false,
        create: false,
      });
    }
  };

  const handleCloseThresholdDrawerAction = () => {
    if (!thresholdActionData.loading) {
      if (openThresholdDialog.edit === true) {
        setOpenThresholdDialog((od) => ({ ...od, edit: false }));
      } else {
        setOpenThresholdDialog({
          create: false,
          view: false,
          edit: false,
        });
      }
    }
  };

  const handleChangeThresholdValue = (fieldName, value, row) => {
    const result = thresholdActionData.item.map((data, index) => {
      if (row === index) {
        data[fieldName] = value;
      }
      return data;
    });

    setThresholdActionData((pad) => ({
      ...pad,
      item: result,
    }));
  };

  const handleSubmitThresholdData = async (row_index) => {
    const request = thresholdActionData.item[row_index];

    const isInvalid = validateInputThresholdMerchant({
      ...request,
      providerId: "default",
      provider_name: "default",
    });
    if (isInvalid.length) {
      setDataNotification({
        open: true,
        status: "error",
        message: t(isInvalid[0]),
      });
    } else {
      //ADD THRESHOLD
      setThresholdActionData((pad) => ({ ...pad, loading: true }));

      let getProviderData = null;
      if (!request.id) {
        const getMerchantData = merchantList.find(
          (el) => el.merchant_id === request.merchant_id
        ).merchant_provider;

        getProviderData = getMerchantData.find(
          (el) => el.provider_name === request.provider_name
        );
      }

      await addEditThreshold(
        t,
        {
          request,
          providerId: request.providerId
            ? request.providerId
            : getProviderData.id,
          isEdit: request.id ? true : false,
        },
        setDataNotification
      );

      setThresholdActionData((pad) => ({ ...pad, loading: false }));

      await getMerchant();
      handleCloseThresholdDrawerAction();
    }
  };

  return (
    <div className={classes.divContainer}>
      <SnackbarNotification
        open={dataNotification?.open}
        handleClose={handleCloseNotification}
        severity={dataNotification?.status}
        message={dataNotification?.message}
      />
      <div>
        <Typography className={classes.textBold24}>
          {t(strings.textMerchantManage)}
        </Typography>
        <Typography className={classes.textGray16Color60}>
          {t(strings.textMerchantManageSubtitle)}
        </Typography>
      </div>

      <div className={classes.divTableData}>
        <UserAction action={action} />
        {showMerchantTable()}
        <AddEditMerchantDrawer
          t={t}
          merchantData={merchantData}
          loadingSubmit={loadingSubmit}
          openDialog={openMerchantDialog}
          handleCloseDialog={handleCloseDialog}
          handleChangeMerchant={handleChangeMerchant}
          handleSubmitUserData={handleSubmitUserData}
          setUploadedData={setUploadedData}
          uploadedData={uploadedData}
          setDataNotification={setDataNotification}
        />

        <ViewEditMerchantProvider
          t={t}
          openDialog={openProviderDialog}
          providerData={providerActionData}
          handleOpenDrawerAction={handleOpenProviderDrawerAction}
          handleCloseDrawerAction={handleCloseProviderDrawerAction}
          handleChangeValueData={handleChangeProviderValue}
          handleSubmitData={(row_index) => handleSubmitProviderData(row_index)}
          handleOpenThresholdDrawerAction={handleOpenThresholdDrawerAction}
          handleChangeTab={handleChangeTab}
          valueTab={valueTab}
        />

        <ViewEditMerchantThreshold
          t={t}
          openThresholdDialog={openThresholdDialog}
          thresholdActionData={thresholdActionData}
          providerActionData={providerActionData}
          handleOpenThresholdDrawerAction={handleOpenThresholdDrawerAction}
          handleCloseDrawerAction={handleCloseThresholdDrawerAction}
          handleChangeValueData={handleChangeThresholdValue}
          handleSubmitData={(row_index) => handleSubmitThresholdData(row_index)}
        />

        <DeletePopUp
          t={t}
          openDialog={openProviderDialog.delete}
          deletedObject={openProviderDialog.delete ? "Merchant Provider" : null}
          handleCancelAction={handleCloseDeleteDialog}
          handleConfirmAction={handleDeleteProvider}
        />
      </div>
    </div>
  );
};

export default withTranslation("Merchant")(MerchantManagement);
