import { z } from "zod";
import {
  isExcludeNumericString,
  isExcludeSpacesString,
} from "../../General/Component/regExp";
import { kycStrings } from "../../General/Constant/Locales/Strings/EkycString";

const isNumericString = (str) => /^[+-]?(\d*\.)?\d+$/.test(str);

const isNumericStringInRange = (min, max) => (number) => {
  const num = Number(`${number}`);
  return isNumericString(`${number}`) && num >= min && num <= max;
};

const isInvalidInputThresholdSchema = z.object({
  name: z
    .string()
    .nonempty(kycStrings.textInvalidName)
    .refine(
      (name) => isExcludeSpacesString(name) && isExcludeNumericString(name),
      {
        message: kycStrings.textInvalidNameFormat,
      }
    ),
  threshold_value: z.string().refine(isNumericStringInRange(0, 100), {
    message: kycStrings.textInvalidThresholdValue,
  }),
  description: z.string().nonempty(kycStrings.textInvalidDescription),
});

const validateInputThreshold = (data) => {
  const result = isInvalidInputThresholdSchema.safeParse(data);
  if (!result.success) {
    return result.error.errors.map((err) => err.message);
  }
  return [];
};

const isInvalidInputProviderSchema = z.object({
  provider_name: z.string().nonempty(kycStrings.textInvalidProviderName),
  secret_key: z.string().nonempty(kycStrings.textInvalidSecretKey),
  hash_key: z.string().nonempty(kycStrings.textInvalidHashKey),
  system_id: z.string().nonempty(kycStrings.textInvalidSystemId),
  apikey: z.string().nonempty(kycStrings.textInvalidAPIKey),
  token: z.string().nonempty(kycStrings.textInvalidToken),
  password: z.string().nonempty(kycStrings.textInvalidPassword),
  channel_id: z.string().nonempty(kycStrings.textInvalidChannelId),
  basic_price: z
    .string()
    .nonempty(kycStrings.textInvalidBasicPrice1)
    .refine(isNumericStringInRange(0, Infinity), {
      message: kycStrings.textInvalidBasicPrice2,
    }),
  vat_merchant: z
    .string()
    .nonempty(kycStrings.textInvalidVATMerchant1)
    .refine(isNumericStringInRange(0, Infinity), {
      message: kycStrings.textInvalidVATMerchant2,
    }),
});

const validateInputProvider = (data) => {
  const result = isInvalidInputProviderSchema.safeParse(data);
  if (!result.success) {
    return result.error.errors.map((err) => err.message);
  }
  return [];
};

//threshold merchant
const isInvalidInputThresholdMerchantSchema = z.object({
  provider_name: z
    .string()
    .nonempty(kycStrings.textInvalidName)
    .refine(
      (name) => isExcludeSpacesString(name) && isExcludeNumericString(name),
      {
        message: kycStrings.textInvalidNameFormat,
      }
    ),
  thresholdValue: z.string().refine(isNumericStringInRange(0, 100), {
    message: kycStrings.textInvalidThresholdValue,
  }),
  description: z.string().nonempty(kycStrings.textInvalidDescription),
});

const validateInputThresholdMerchant = (data) => {
  const result = isInvalidInputThresholdMerchantSchema.safeParse(data);
  if (!result.success) {
    return result.error.errors.map((err) => err.message);
  }
  return [];
};

export {
  validateInputThreshold,
  validateInputProvider,
  validateInputThresholdMerchant,
};
