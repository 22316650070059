import { Alert, Snackbar } from "@mui/material";

const SnackbarNotification = ({ open, handleClose, severity, message }) => {
  //kinds of severity:
  //success, info, warning, error

  return (
    <Snackbar
      open={open || false}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert severity={severity || ""} variant="filled" sx={{ width: "100%" }}>
        {message || ""}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarNotification;
