const ExpandLessIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
  >
    <path
      d="M15.3563 11.648C15.304 11.7003 15.242 11.7418 15.1737 11.7701C15.1054 11.7984 15.0322 11.813 14.9583 11.813C14.8844 11.813 14.8112 11.7984 14.7429 11.7701C14.6746 11.7418 14.6126 11.7003 14.5604 11.648L9.33332 6.42025L4.10629 11.648C4.00074 11.7535 3.85759 11.8128 3.70832 11.8128C3.55905 11.8128 3.4159 11.7535 3.31035 11.648C3.2048 11.5424 3.14551 11.3993 3.14551 11.25C3.14551 11.1007 3.2048 10.9576 3.31035 10.852L8.93535 5.22705C8.98759 5.17475 9.04963 5.13326 9.11792 5.10495C9.1862 5.07664 9.2594 5.06207 9.33332 5.06207C9.40724 5.06207 9.48044 5.07664 9.54872 5.10495C9.61701 5.13326 9.67905 5.17475 9.73129 5.22705L15.3563 10.852C15.4086 10.9043 15.4501 10.9663 15.4784 11.0346C15.5067 11.1029 15.5213 11.1761 15.5213 11.25C15.5213 11.3239 15.5067 11.3971 15.4784 11.4654C15.4501 11.5337 15.4086 11.5957 15.3563 11.648Z"
      fill="#707070"
    />
  </svg>
);

export default ExpandLessIcon;
