import RequestHttpGatewayInternal from "../../General/Helper/RequestHttpGatewayInternal";
import { getAuthenticatedServerRequest } from "../../General/Helper/auth";

export const fetchProductActivity = async (request, setNotificationData) => {
  try {
    const params = {
      page: request.page,
      limit: request.limit,
      startDate: request.startDate,
      endDate: request.endDate,
      productActivityType: request.productActivityType,
      search: request.search,
      export: request.export,
    };
    const result = await RequestHttpGatewayInternal({
      method: "GET",
      url: "/product-activity",
      headers: getAuthenticatedServerRequest(),
      params: params,
    });
    return result.data?.data;
  } catch (error) {
    setNotificationData({
      open: true,
      status: "error",
      message: error.response.data.response_message,
    });
    return {
      items: [],
      meta: {
        totalItems: "0",
        currentPage: "1",
        itemsPerPage: "5",
        totalPages: 0,
      },
    };
  }
};

export const fetchDataProductActivityType = async (setNotificationData) => {
  try {
    const result = await RequestHttpGatewayInternal({
      method: "GET",
      url: "/product-activity/product-activity-type",
      headers: getAuthenticatedServerRequest(),
    });
    return result.data?.data;
  } catch (error) {
    setNotificationData({
      open: true,
      status: "error",
      message: error.response.data.response_message,
    });
    return [];
  }
};
