import RequestHttpGatewayInternal from "../../General/Helper/RequestHttpGatewayInternal";
import { getAuthenticatedServerRequest } from "../../General/Helper/auth";

export const fetchTransactionData = async (
  request,
  body,
  setNotificationData
) => {
  const data = {
    search: body.search,
    startDate: body.startDate,
    endDate: body.endDate,
    isExport: body.isExport,
  };
  const params = {
    page: request.page,
    limit: request.limit,
  };
  try {
    const result = await RequestHttpGatewayInternal({
      method: "POST",
      url: "/transaction/list",
      headers: getAuthenticatedServerRequest(),
      params: params,
      data: data,
    });
    return result.data?.data;
  } catch (error) {
    setNotificationData({
      open: true,
      status: "error",
      message: error.response.data.response_message,
    });
    return {
      items: [],
      meta: {
        totalItems: "0",
        currentPage: "1",
        itemsPerPage: "5",
        totalPages: 0,
      },
    };
  }
};

export const fetchDetailTransaction = async (
  transaction_id,
  setDetailTrx,
  strings,
  t,
  setNotificationData
) => {
  try {
    const result = await RequestHttpGatewayInternal({
      method: "GET",
      url: `/transaction/detail/${transaction_id}`,
      headers: getAuthenticatedServerRequest(),
    });
    const data = result.data.data;
    if (data.productType?.name === "SSO") {
      setDetailTrx([
        {
          title: t(strings.textTrxId),
          value: data.transaction_id,
          desc: "trx",
        },
        {
          title: t(strings.textOrderId),
          value: data.order_id ? data.order_id : "-",
          desc: "orderId",
        },
        {
          title: t(strings.textProduct),
          value: data.productType.name ? data.productType.name : "-",
          desc: "product",
        },
        {
          title: t(strings.textFullName),
          value: data.fullname ? data.fullname : "-",
          desc: "fullname",
        },
        {
          title: t(strings.textNik),
          value: data.nik ? data.nik : "-",
          desc: "nik",
        },
        {
          title: t(strings.textEmail),
          value: data.email ? data.email : "-",
          desc: "email",
        },
        {
          title: t(strings.textPhoneNumber),
          value: data.phone ? data.phone : "-",
          desc: "phone",
        },
        {
          title: t(strings.textMerchant),
          value: null,
          logo: data.merchantLogo ? data.merchantLogo : "-",
          name: data.merchantName ? data.merchantName : "-",
          desc: "merchant",
        },
        {
          title: t(strings.textAddress),
          value: data.address ? data.address : "-",
          desc: "address",
        },
        {
          title: t(strings.textTrxDate),
          value: data.createdAt ? data.createdAt : "-",
          desc: "createdAt",
        },
        {
          title: t(strings.textScanTime),
          value: data.scanTime ? data.scanTime : "-",
          desc: "scantime",
        },
        {
          title: t(strings.textLocation),
          address: data.address,
          value:
            data?.lat !== null && data?.long !== null
              ? [data?.lat, data?.long]
              : null,
          desc: "loc",
        },
      ]);
    } else if (data.productType?.name === "E-KYC") {
      setDetailTrx([
        {
          title: t(strings.textTrxId),
          value: data.transaction_id,
          desc: "trx",
        },
        {
          title: t(strings.textProduct),
          value: data.productType.name ? data.productType.name : "-",
          desc: "product",
        },
        {
          title: t(strings.textFullName),
          value: data.fullname ? data.fullname : "-",
          desc: "fullname",
        },
        {
          title: t(strings.textNik),
          value: data.nik ? data.nik : "-",
          desc: "nik",
        },
        {
          title: t(strings.textDob),
          value: data.dateOfBirth ? data.dateOfBirth : "-",
          desc: "dob",
        },
        {
          title: t(strings.textThreshold),
          value: data.threshold ? data.threshold : "-",
          desc: "threshold",
        },
        {
          title: t(strings.textFinalScore),
          value: data.ekycFinalScore ? data.ekycFinalScore : "-",
          desc: "finalscore",
        },
        {
          title: t(strings.textPackage),
          value: data.requestPackage ? data.requestPackage : "-",
          desc: "package",
        },
        {
          title: t(strings.textProvider),
          value: data.provider ? data.provider : "-",
          desc: "provider",
        },
        {
          title: t(strings.textTrxDate),
          value: data.createdAt ? data.createdAt : "-",
          desc: "createdAt",
        },
      ]);
    }
  } catch (error) {
    setNotificationData({
      open: true,
      status: "error",
      message: error.response.data.response_message,
    });
    return {};
  }
};
