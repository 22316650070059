import {
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import classes from "../Styles/general.module.css";
import { useState } from "react";

const SvgIconStatus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M16.6925 7.94205L10.4425 14.192C10.3845 14.2502 10.3156 14.2963 10.2397 14.3277C10.1638 14.3592 10.0825 14.3754 10.0003 14.3754C9.91821 14.3754 9.83688 14.3592 9.76101 14.3277C9.68514 14.2963 9.61621 14.2502 9.55816 14.192L3.30816 7.94205C3.19088 7.82477 3.125 7.66571 3.125 7.49986C3.125 7.33401 3.19088 7.17495 3.30816 7.05767C3.42544 6.9404 3.5845 6.87451 3.75035 6.87451C3.9162 6.87451 4.07526 6.9404 4.19253 7.05767L10.0003 12.8663L15.8082 7.05767C15.8662 6.9996 15.9352 6.95354 16.011 6.92211C16.0869 6.89069 16.1682 6.87451 16.2503 6.87451C16.3325 6.87451 16.4138 6.89069 16.4897 6.92211C16.5655 6.95354 16.6345 6.9996 16.6925 7.05767C16.7506 7.11574 16.7967 7.18468 16.8281 7.26055C16.8595 7.33642 16.8757 7.41774 16.8757 7.49986C16.8757 7.58198 16.8595 7.6633 16.8281 7.73917C16.7967 7.81504 16.7506 7.88398 16.6925 7.94205Z"
      fill="#707070"
    />
  </svg>
);

const SelectOptions = ({
  optionList,
  selectedItem,
  handleSelectOption,
  placeholder,
}) => {
  const [open, setOpen] = useState(false);

  const handleIconClick = () => {
    setOpen(!open);
  };

  return (
    <Select
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      value={selectedItem || ""}
      onChange={handleSelectOption}
      displayEmpty
      renderValue={(selected) => {
        if (!selected) {
          return (
            <Typography className={classes.placeholder}>
              {placeholder || "Select Item..."}
            </Typography>
          );
        }
        return selectedItem;
      }}
      name="status"
      sx={{
        ".MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
        ".MuiSelect-select": {
          padding: 0, // Remove padding here
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        },
        ".MuiSelect-input": {
          paddingRight: "32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: "12px",
        },
      }}
      input={<OutlinedInput classes={{ input: classes.selectedInput }} />}
      IconComponent={() => (
        <IconButton onClick={handleIconClick} sx={{ padding: 0 }}>
          <SvgIconStatus />
        </IconButton>
      )}
      // IconComponent={null}
      className={classes.selectStatus}
    >
      {optionList.map((data) => {
        return (
          <MenuItem
            value={data.id}
            key={data.id}
            className={classes.statusMenuItem}
          >
            <Typography className={classes.textSemiBold14}>
              {data.label}
            </Typography>
            <>{data.icon || null}</>
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default SelectOptions;
