import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import classes from "../Styles/general.module.css";

// create custom icon
const customIcon = new Icon({
  // iconUrl: "https://cdn-icons-png.flaticon.com/512/447/447031.png",
  iconUrl:
    "https://res.cloudinary.com/pastisah-id/image/upload/v1720155014/Default_marker_component_jph3mg.png",
  iconSize: [25, 38], // size of the icon
});

const LocationOnMaps = (props) => {
  const { location } = props;

  return (
    <>
      <div className={classes.leaflet_container}>
        <MapContainer
          className={classes.leaflet_container}
          // center={[-6.270264265331952, 106.81546532451966]}
          center={location}
          zoom={13}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker
            // position={[-6.270264265331952, 106.81546532451966]}
            position={location}
            icon={customIcon}
          >
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    </>
  );
};

export default LocationOnMaps;
