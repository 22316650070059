import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import classesOfUser from "../../UserManagement/Styles/usermanage.module.css";
import classesOfKyc from "../Styles/kyc.module.css";
import SnackbarNotification from "../../General/Component/SnackbarNotification";
import { UserAction } from "../../General/Component/UserAction";
import { createIcon } from "../Icon/create";
import { TableView } from "../../General/Component/table";
import {
  isExcludeNumericString,
  isExcludeSpacesString,
} from "../../General/Component/regExp";
import { kycStrings } from "../../General/Constant/Locales/Strings/EkycString";

export const EkycContent = (props) => {
  const {
    t,
    text,
    notificationData,
    tableOptions,
    fetchDataRequest,
    handleOpenDialogCreate,
  } = props;

  const [action, setAction] = useState({
    search: {
      isComponent: true,
      reassignStyle: {
        placeholder: `Search by E-KYC ${text.title} name`,
        width: classesOfKyc.searchWidth,
      },
      value: "",
      handleChangeValue: async ({ target }) => {
        let isValidInput = false;
        if (
          ((isExcludeSpacesString(target.value) &&
            isExcludeNumericString(target.value)) ||
            target.value === "") &&
          text.title === kycStrings.textThresholdTitle
        ) {
          isValidInput = true;
        } else if (text.title === kycStrings.textProviderTitle) {
          isValidInput = true;
        }

        if (isValidInput) {
          setAction((a) => {
            fetchDataRequest({
              page: 1,
              limit: 5,
              name: target.value,
            });
            return {
              ...a,
              search: { ...a.search, value: target.value },
            };
          });
        }
      },
    },
    export: {
      isComponent: true,
      open: false,
      handleData: (exportType) => {
        setAction((a) => {
          fetchDataRequest({
            name: a.search.value,
            isExport: true,
            exportType,
          });
          return {
            ...a,
            export: { ...a.export, open: false },
          };
        });
      },
      handleOpenClose: (value) => {
        setAction((a) => ({
          ...a,
          export: { ...a.export, open: value },
        }));
      },
    },
    button: {
      isComponent: true,
      icon: createIcon(),
      open: false,
      name: `Add E-KYC ${text.title}`,
      handleOpen: () => {
        handleOpenDialogCreate("create");
        setAction((a) => {
          return {
            ...a,
            button: { ...a.button, open: true },
          };
        });
      },
      handleClose: () =>
        setAction((a) => ({
          ...a,
          button: { ...a.button, open: false },
        })),
    },
  });

  const [notificationAction, setNotificationAction] = useState({
    open: false,
    handleClose: () =>
      setNotificationAction({ ...notificationAction, open: false }),
  });

  useEffect(() => {
    if (notificationData.status && notificationData.message) {
      setNotificationAction((na) => ({ ...na, open: true }));
    }
  }, [notificationData.id]);

  return (
    <div className={classesOfUser.divContainer}>
      <SnackbarNotification
        open={notificationAction.open}
        handleClose={notificationAction.handleClose}
        severity={notificationData.status}
        message={notificationData.message}
      />

      <div>
        <Typography className={classesOfUser.textBold24}>
          {text.title}
        </Typography>
        <Typography className={classesOfUser.textGray16Color60}>
          {text.description}
        </Typography>
      </div>
      <div className={classesOfUser.divTableData}>
        <UserAction translation={"Kyc"} action={action} />
        <TableView t={t} tableOptions={tableOptions} />
      </div>
    </div>
  );
};
