const strings = {
  textProductActivity: "Product Activities",
  textMonitorProductActivity: "Monitor your product activities.",
  textSearch: "Search",
  textSelectActivityDates: "Select Activity Dates",
  textAllProduct: "All Product",
  textActivityID: "Activity ID",
  textProductTypeActivity: "Product Activity Type",
  textName: "Name",
  textNik: "ID Card",
  textTransDate: "Transaction Date",
  textAction: "Action",
  textViewDetail: "View Detail",
  textOrderId: "Order ID",
  textProductType: "Product Type",
  textGeneratedBy: "Generate by",
  textScanBy: "Scan by",
  textScanTime: "Scan Time",
  textLocation: "Location",
  textFullName: "Full Name",
  textDob: "Date of Birth",
  textThreshold: "Threshold",
  textFinalScore: "Final Score",
  textPackage: "Package",
  textProvider: "Provider",
  textActivityDate: "Activity Date",
  textMerchant: "Merchant",
  textActivityDate: "Activity Dates",
  textLast7Day: "Last 7 days",
  textLast30Day: "Last 30 days",
  textCustomDate: "Custom Date",
  textDescCustomDate:
    "Custom period for transaction dates can be up to one month earlier or later than the selected date.",
  textStartDate: "Start date",
  textEndDate: "End date",
  textApplyFilter: "Apply Filter",
  textInvalidLoc: "Invalid Location",
};

export default strings;
