import { withTranslation } from "react-i18next";
import strings from "../../General/Constant/Locales/Strings/MerchantManageStrings";

const merchantManageHeaders = (props) => {
  const { t } = props;
  return [
    {
      id: 1,
      label: t(strings.textMerchantLogo),
    },
    {
      id: 2,
      label: t(strings.textMerchantID),
    },
    {
      id: 3,
      label: t(strings.textCompanyName),
    },
    {
      id: 4,
      label: t(strings.textEmail),
    },
    {
      id: 5,
      label: t(strings.textAddress),
    },
    {
      id: 6,
      label: t(strings.textMerchantProvider),
    },
    {
      id: 7,
      label: t(strings.textAction),
    },
  ];
};

export default merchantManageHeaders;
