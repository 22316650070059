import DashboardSettings from "./Component/DashboardSettings";
import { withTranslation } from "react-i18next";

const Dashboard = (props) => {
  const { handleDrawerOpen, setDrawerOpen, drawerOpen, listRoute } = props;
  return (
    <div>
      <DashboardSettings
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        handleDrawerOpen={handleDrawerOpen}
        listRoute={listRoute}
      />
    </div>
  );
};

export default withTranslation("App")(Dashboard);
