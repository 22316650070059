import { Button, Typography } from "@mui/material";
import DeleteIcon from "../Icon/DeleteIcon";
import classes from "../Styles/general.module.css";
import ModalPopUp from "./ModalPopUp";

const DeletePopUp = ({
  t,
  openDialog,
  deletedObject,
  handleCancelAction,
  handleConfirmAction,
}) => {
  return (
    <ModalPopUp
      content={
        <div className={classes.containerModal}>
          <div>
            <div className={classes.redCircle}>
              <DeleteIcon />
            </div>
          </div>
          <div className={classes.divContentModal}>
            <div>
              <Typography className={classes.textBold18}>
                {t(`Delete ${deletedObject}`) + "?"}
              </Typography>
              <Typography className={classes.textGray14Color60}>
                {t(
                  `Deleting the ${deletedObject} will permanently remove it from the ${deletedObject} list.`
                )}
              </Typography>
            </div>

            <div className={classes.divGroupButton}>
              <Button
                onClick={handleCancelAction}
                className={classes.outlinedButtonSmall}
              >
                {t(`No, Keep ${deletedObject}`)}
              </Button>
              <Button
                onClick={handleConfirmAction}
                className={classes.containedButtonSmall}
              >
                {t(`Yes, Delete ${deletedObject}`)}
              </Button>
            </div>
          </div>
        </div>
      }
      closeDialog={handleCancelAction}
      openDialog={openDialog}
    />
  );
};

export default DeletePopUp;
