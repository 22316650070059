import strings from "../../General/Constant/Locales/Strings/UserManageStrings";

const userRoleHeaders = (props) => {
  const { t } = props;
  return [
    {
      id: 1,
      label: t(strings.textRoleName),
    },
    {
      id: 2,
      label: t(strings.textUserAccessPermission),
    },
    {
      id: 3,
      label: t(strings.textAction),
    },
  ];
};

export default userRoleHeaders;
