import { Button, Typography } from "@mui/material";
import classes from "../Styles/merchantmanage.module.css";
import strings from "../../General/Constant/Locales/Strings/MerchantManageStrings";
import TabPanels from "../../General/Component/TabPanels";
import { ProviderSetupData } from "../../E-KYC/Component/action";
import DrawerAction from "../../General/Component/DrawerAction";
import InfoIcon from "../../General/Icon/InfoIcon";
import AddWhiteIcon from "../../General/Icon/AddWhiteIcon";

const ViewEditMerchantProvider = ({
  t,
  openDialog,
  providerData,
  handleChangeValueData,
  handleOpenDrawerAction,
  handleCloseDrawerAction,
  handleSubmitData,
  handleOpenThresholdDrawerAction,
  handleChangeTab,
  valueTab,
}) => {
  const showTabContainer = () => {
    return providerData?.item?.map((item, index) => {
      if (index === valueTab) {
        return (
          <div style={{ width: "100%" }} key={index}>
            {openDialog.view && !item.merchantThreshold && (
              <div className={classes.divInfo}>
                <div className={classes.infoIcon}>
                  <InfoIcon />
                </div>
                <div className={classes.divColumnFlexEnd}>
                  <Typography>
                    {t(strings.textProviderWithoutThreshold)}
                  </Typography>
                  <div>
                    <Button
                      onClick={() =>
                        handleOpenThresholdDrawerAction("create", item)
                      }
                      className={classes.containedButtonSmall}
                    >
                      <AddWhiteIcon />
                      {t(strings.textAddThreshold)}
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {(openDialog.edit || openDialog.view) && (
              <div
                className={`${classes.divViewField} ${classes.divDialogEdit}`}
              >
                <Typography className={classes.textGray14Color60}>
                  {t(strings.textMerchantID)}
                </Typography>
                <Typography className={classes.textBold16}>
                  {item.merchant_id}
                </Typography>
              </div>
            )}

            <div style={{ margin: "-24px" }}>
              <ProviderSetupData
                actionData={{
                  item: {
                    ...item,
                    status:
                      openDialog.edit || openDialog.create
                        ? item.status
                        : item.status
                        ? t(strings.textActive)
                        : t(strings.textInactive),
                  },
                  loading: providerData.loading,
                }}
                onChangeValue={(fieldName, value) =>
                  handleChangeValueData(fieldName, value, index)
                }
                onSubmit={() => handleSubmitData(index)}
                labelOptions={{
                  textCreate: "Create Provider",
                  textEdit: "Edit Provider",
                  textDelete: "Delete Provider",
                }}
                dialogOptions={{
                  openEdit: openDialog.edit,
                  handleOpenDrawerAction: (action) =>
                    handleOpenDrawerAction(action, {
                      ...item,
                      merchant_id: item.merchant_id,
                    }),
                }}
              />
            </div>
          </div>
        );
      }
    });
  };

  return (
    <div className={classes.divDialogEditTitle}>
      <DrawerAction
        openDialog={openDialog.view || openDialog.create}
        handleCloseDrawer={handleCloseDrawerAction}
        text={{
          title: openDialog.edit
            ? "Edit Merchant Provider"
            : openDialog.create
            ? "Create Merchant Provider"
            : t(strings.textDetailMerchantProvider),
        }}
        content={
          <div className={classes.divListProvider}>
            {(openDialog.edit || openDialog.view) && (
              <div>
                <Typography className={classes.textBold14}>
                  {t(strings.textListProvider)}
                </Typography>
                <Typography className={classes.textGray14Color60}>
                  {t(strings.textDetailProviderSubtitle)}
                </Typography>
                <div className={classes.divItemProvider}>
                  <TabPanels
                    value={valueTab}
                    tabLabel={"Tab Provider"}
                    listTab={providerData.item || []}
                    handleChangeTab={handleChangeTab}
                  />
                </div>
              </div>
            )}

            <div className={classes.grayLine} />
            {showTabContainer()}
          </div>
        }
      />
    </div>
  );
};

export default ViewEditMerchantProvider;
