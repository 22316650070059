import React from "react";
import { CssBaseline, Box } from "@mui/material";
import AppBarDashboard from "./AppBar";
import DrawerDashboard from "./Drawer";
import { withTranslation } from "react-i18next";

const DashboardSettings = (props) => {
  const { t, drawerOpen, handleDrawerOpen, listRoute } = props;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBarDashboard
        t={t}
        open={drawerOpen}
        handleDrawerOpen={handleDrawerOpen}
      />
      <DrawerDashboard
        t={t}
        drawerOpen={drawerOpen}
        handleDrawerOpen={handleDrawerOpen}
        listMenu={listRoute}
      />
    </Box>
  );
};

export default withTranslation("App")(DashboardSettings);
