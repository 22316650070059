import { useEffect, useState } from "react";
import SnackbarNotification from "../../General/Component/SnackbarNotification";
import handleCloseNotif from "../../General/Helper/handleCloseNotif";
import { Typography } from "@mui/material";
import strings from "../../General/Constant/Locales/Strings/DashboardStrings";
import classes from "../Styles/dashboard.module.css";
import getDateNow from "../../General/Helper/getDateNow";
import CardInfo from "../../General/Component/CardInfo";
import CardAreaChart from "./CardAreaChart";
import CardPieChart from "./CardPieChart";
import { getTotalData } from "../Helper/getDashboardData";
import { useTranslation } from "react-i18next";

const DashboardPage = (props) => {
  const { t } = useTranslation("Dashboard");
  const [dataNotification, setDataNotification] = useState(null);
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    getTotalData(setCardData);
  }, []);

  const handleCloseNotification = () => {
    handleCloseNotif(setDataNotification);
  };

  return (
    <div className={classes.divContainer}>
      <SnackbarNotification
        open={dataNotification?.open}
        handleClose={handleCloseNotification}
        severity={dataNotification?.status}
        message={dataNotification?.message}
      />
      <div>
        <Typography className={classes.textBold24}>
          {t(strings.textDashboard)}
        </Typography>
        <Typography className={classes.textGray16Color60}>
          {getDateNow()}
        </Typography>
      </div>

      <CardInfo cardData={cardData} />

      <div className={classes.divCard}>
        <CardAreaChart />
        <CardPieChart t={t} />
      </div>
    </div>
  );
};

export default DashboardPage;
