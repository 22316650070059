const strings = {
  textWelcome: "Welcome to INAPass Dashboard",
  textSubWelcome: "To get started, please login to your account",
  textEmailAddress: "E-mail Address",
  textPassword: "Password",
  textViewPassword: "View Password",
  textHidePassword: "Hide Password",
  textPlaceholderEmail: "Type your e-mail here...",
  textPlaceholderPassword: "Type your password here...",
  textForgotPassword: "Forgot Password?",
  textLogin: "Login",
  textFooter1: "By Logging into your account, you agree to our",
  textFooter2: "Terms of",
  textFooter3: "Service",
  textFooter4: "and",
  textFooter5: "Privacy Policy",
  textCopyright: "©2024 - INAPass. All Rights Reserved.",
  textDashboard: "Dashboard",
  textUserManage: "User Management",
  textUserList: "User List",
  textUserRole: "User Role",
  textEkyc: "E-KYC",
  textProvider: "Provider",
  textThreshold: "Threshold",
  textPrevious: "Previous",
  textNext: "Next",
  textSettings: "Settings",
  textHelps: "Helps",
  textLogout: "Log Out",
  textUserProfileSetting: "User Profile Setting",
  textAllStatus: "All Status",
  textSuccessActivation: "Account Activation Successful!",
  textSubtitleActivation:
    "Now, you can continue your activities by logging into the INAPass Backoffice using your account.",
  textGoToLogin: "Go to the Login Page",
  textMerchantManagement: "Merchant Management",
  textMerchant: "Merchant",
  textFailedActivation: "Account Activation Failed!",
  textSubtitleFailedActivation:
    "We're unable to complete the account verification at this time. Please try the verification process again. If the issue persists, reach out to the INAPass support team for assistance.",
  textTransactionActivity: "Transaction",
  textProductActivity: "Product Activities",
  //invalid text
  textInvalidEmail: "Invalid e-mail format",
  textAllProducts: "All Products",

  //days
  textSunday: "Sunday",
  textMonday: "Monday",
  textTuesday: "Tuesday",
  textWednesday: "Wednesday",
  textThursday: "Thursday",
  textFriday: "Friday",
  textSaturday: "Saturday",

  //month
  textJanuary: "January",
  textFebruary: "February",
  textMarch: "March",
  textApril: "April",
  textMay: "May",
  textJune: "June",
  textJuly: "July",
  textAugust: "August",
  textSeptember: "September",
  textOctober: "October",
  textNovember: "November",
  textDecember: "December",
};

export default strings;
