import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import classes from "../Styles/dashboard.module.css";
import { useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const DrawerItem = ({ item, handleDrawerOpen, drawerOpen }) => {
  const location = useLocation();
  const activePath = location.pathname;

  const [isDrawerSubmenu, setIsDrawerSubmenu] = useState(false);

  const handleClick = () => {
    if (item.submenu) {
      setIsDrawerSubmenu(drawerOpen ? !isDrawerSubmenu : true);
      if (!drawerOpen) {
        handleDrawerOpen();
      }
    } else {
      handleDrawerOpen();
    }
  };

  const checkClassNameMenu = () => {
    if (activePath === item.path) {
      if (drawerOpen) {
        return classes.activeMenu;
      } else {
        return classes.activeCloseMenu;
      }
    } else {
      return classes.defaultMenu;
    }
  };
  return (
    <div>
      {!item.is_sub_menu ? (
        <ListItemButton
          className={checkClassNameMenu()}
          component={!item.submenu ? Link : null}
          onClick={item.submenu ? handleClick : null}
          to={!item.submenu ? item.path : undefined}
          key={item.id}
        >
          <ListItemIcon
            className={`${
              activePath === item.path
                ? classes.activeIcon
                : classes.defaultMenuIcon
            }`}
          >
            {item.icon}
          </ListItemIcon>

          <ListItemText className={classes.textRegularGray14}>
            {item.label}
          </ListItemText>
          <ListItemIcon>
            {item.submenu ? (
              <>
                {isDrawerSubmenu ? (
                  <ExpandMore color="#707070" />
                ) : (
                  <ExpandLess color="#707070" />
                )}
              </>
            ) : null}
          </ListItemIcon>
        </ListItemButton>
      ) : null}

      {item.submenu ? (
        <Collapse
          in={isDrawerSubmenu && drawerOpen}
          timeout="auto"
          unmountOnExit
        >
          <List>
            {item.submenu.map((menu, index) => {
              return (
                <ListItemButton component={Link} to={menu.path} key={menu.id}>
                  <ListItemIcon />

                  <ListItemText className={classes.textRegularGray14}>
                    {menu.label}
                  </ListItemText>
                </ListItemButton>
              );
            })}
          </List>
        </Collapse>
      ) : null}
    </div>
  );
};

export default DrawerItem;
