import { Typography } from "@mui/material";
import classes from "../Styles/merchantmanage.module.css";
import strings from "../../General/Constant/Locales/Strings/MerchantManageStrings";
import TabPanels from "../../General/Component/TabPanels";
import { useState } from "react";
import { MerchantThresholdSetupData } from "../../E-KYC/Component/action";
import DrawerAction from "../../General/Component/DrawerAction";

const ViewEditMerchantThreshold = ({
  t,
  openThresholdDialog,
  providerActionData,
  thresholdActionData,
  handleChangeValueData,
  handleCloseDrawerAction,
  handleOpenThresholdDrawerAction,
  handleSubmitData,
}) => {
  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (e, newValue) => {
    setValueTab(newValue);
  };

  const showTabContainer = () => {
    return thresholdActionData?.item?.map((item, index) => {
      if (index === valueTab) {
        return (
          <div style={{ width: "100%" }} key={index}>
            <div style={{ margin: "-24px" }}>
              <MerchantThresholdSetupData
                actionData={{
                  item: {
                    ...item,
                    status:
                      openThresholdDialog.edit || openThresholdDialog.create
                        ? item.status
                        : item.status
                        ? t(strings.textActive)
                        : t(strings.textInactive),
                  },
                  loading: thresholdActionData.loading,
                }}
                onChangeValue={(fieldName, value) =>
                  handleChangeValueData(fieldName, value, index)
                }
                onSubmit={() => handleSubmitData(index)}
                labelOptions={{
                  textCreate: "Create Threshold",
                  textEdit: "Edit Threshold",
                }}
                dialogOptions={{
                  openEdit: openThresholdDialog.edit,
                  handleOpenDrawerAction: (action) =>
                    handleOpenThresholdDrawerAction(action, {
                      ...item,
                      merchant_id: item.merchant_id,
                    }),
                }}
              />
            </div>
          </div>
        );
      }
    });
  };

  return (
    <div className={classes.divDialogEditTitle}>
      <DrawerAction
        openDialog={openThresholdDialog.view || openThresholdDialog.create}
        handleCloseDrawer={handleCloseDrawerAction}
        text={{
          title: openThresholdDialog.edit
            ? "Edit Merchant Threshold"
            : openThresholdDialog.create
            ? "Create Merchant Threshold"
            : t(strings.textViewThresholdDetail),
        }}
        content={
          <div className={classes.divListProvider}>
            {(openThresholdDialog.edit || openThresholdDialog.view) && (
              <div>
                <Typography className={classes.textBold14}>
                  {t(strings.textListProvider)}
                </Typography>
                <Typography className={classes.textGray14Color60}>
                  {t(strings.textDetailProviderSubtitle)}
                </Typography>
                <div className={classes.divItemProvider}>
                  <TabPanels
                    value={valueTab}
                    tabLabel={"Tab Provider"}
                    listTab={providerActionData.item || []}
                    handleChangeTab={handleChangeTab}
                  />
                </div>
              </div>
            )}

            <div className={classes.grayLine} />
            {showTabContainer()}
          </div>
        }
      />
    </div>
  );
};

export default ViewEditMerchantThreshold;
