import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import classes from "../Styles/usermanage.module.css";
import strings from "../../General/Constant/Locales/Strings/UserManageStrings";
import getAllUserList from "../Helper/getAllUserList";
import { useEffect, useState } from "react";
import userListHeaders from "../Constant/userListHeaders";
import LoadingState from "../../General/Component/LoadingState";
import AddEditUserDataDrawer from "../Component/AddEditUserDataDrawer";
import SnackbarNotification from "../../General/Component/SnackbarNotification";
import handleCloseNotif from "../../General/Helper/handleCloseNotif";
import submitUserData from "../Helper/submitUserData";
import { withTranslation } from "react-i18next";
import TablePagination from "../../General/Component/TablePagination";
import AddPersonIcon from "../../General/Icon/AddPersonIcon";
import { UserAction } from "../../General/Component/UserAction";

const ListUser = (props) => {
  const { t } = props;
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalPage, setTotalPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [dataNotification, setDataNotification] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("All Status");
  const [searchValue, setSearchValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [openExportData, setOpenExportData] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [userData, setUserData] = useState({
    fullName: "",
    phone: "",
    password: "",
    address: "",
    email: "",
    status: "",
    role: "",
    isAdmistrator: false,
    rolesName: "",
    permissionAccess: [],
  });
  let isExport = false;

  const [action, setAction] = useState({
    search: {
      isComponent: true,
      reassignStyle: {
        placeholder: `Search by Fullname`,
      },
      value: "",
      handleChangeValue: ({ target }) => {
        setAction((data) => {
          getAllUserList(
            page,
            rowsPerPage,
            isExport,
            "",
            target.value,
            selectedStatus,
            startDate,
            endDate,
            setLoading,
            setUserList,
            setTotalPage,
            setOpenExportData,
            setDataNotification
          );
          return {
            ...data,
            search: { ...data.search, value: target.value },
          };
        });
      },
    },
    export: {
      isComponent: true,
      open: false,
      handleData: (exportType) => {
        isExport = true;
        setAction((data) => {
          getAllUserList(
            page,
            rowsPerPage,
            isExport,
            exportType,
            data.search.value,
            selectedStatus,
            startDate,
            endDate,
            setLoading,
            setUserList,
            setTotalPage,
            setOpenExportData,
            setDataNotification
          );
          return {
            ...data,
            export: { ...data.export, open: false },
          };
        });
      },
      handleOpenClose: (value) =>
        setAction((data) => {
          return { ...data, export: { ...data.export, open: value } };
        }),
    },
    button: {
      isComponent: true,
      icon: <AddPersonIcon />,
      open: false,
      name: t(strings.textAddNewUser),
      handleOpen: () => {
        addNewUser();
        setAction((data) => {
          return { ...data, createData: { ...data.createData, open: true } };
        });
      },
      handleClose: () =>
        setAction((data) => {
          return { ...data, createData: { ...data.createData, open: false } };
        }),
    },
  });

  useEffect(() => {
    getUserList();
  }, []);

  const getUserList = () => {
    getAllUserList(
      page,
      rowsPerPage,
      isExport,
      "",
      searchValue,
      selectedStatus,
      startDate,
      endDate,
      setLoading,
      setUserList,
      setTotalPage,
      setOpenExportData,
      setDataNotification
    );
  };

  const addNewUser = () => {
    setUserData({
      fullName: "",
      phone: "",
      password: "",
      address: "",
      email: "",
      status: "",
      role: "",
      isAdmistrator: false,
      rolesName: "",
      permissionAccess: [],
    });
    setIsEdit(false);
    setOpenDialog(true);
  };

  const editUser = (selectedUser) => {
    setUserData({
      fullName: selectedUser.fullname,
      phone: selectedUser.phone.replace("+62", ""),
      address: selectedUser.address,
      email: selectedUser.email,
      status: selectedUser.status,
    });
    setIsEdit(true);
    setOpenDialog(true);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    getAllUserList(
      newPage,
      rowsPerPage,
      isExport,
      "",
      searchValue,
      selectedStatus,
      startDate,
      endDate,
      setLoading,
      setUserList,
      setTotalPage,
      setOpenExportData,
      setDataNotification
    );
  };

  const handleRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    getAllUserList(
      page,
      event.target.value,
      isExport,
      "",
      searchValue,
      selectedStatus,
      startDate,
      endDate,
      setLoading,
      setUserList,
      setTotalPage,
      setOpenExportData,
      setDataNotification
    );
  };

  const showUserListTable = () => {
    return (
      <TableContainer
        component={Paper}
        style={{
          width: openDialog ? "60%" : "100%",
          transition: "width 0.5s ease",
          overflow: "hidden",
          overflowX: "auto",
          boxShadow: "none",
        }}
      >
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {userListHeaders(props).map((data) => {
                return (
                  <TableCell
                    key={data.id}
                    className={
                      data.id > 1 && data.id < userListHeaders(props).length
                        ? classes.tableCellFullBorder
                        : classes.tableRow
                    }
                  >
                    <Typography className={classes.textBold14}>
                      {data.label}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>{printBodyData()}</TableBody>
        </Table>
        <TablePagination
          t={t}
          rowsPerPage={rowsPerPage}
          currentPage={page}
          totalPages={totalPage}
          handlePageChange={handlePageChange}
          handleRowsPerPage={handleRowsPerPage}
        />
      </TableContainer>
    );
  };

  const printBodyData = () => {
    if (loading) {
      return <LoadingState />;
    } else {
      if (userList?.length > 0) {
        return userList?.map((data) => {
          return (
            <TableRow key={data.id}>
              <TableCell className={classes.tableRow}>
                <Typography className={classes.textSemiBold14}>
                  {data.fullname}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCellFullBorder}>
                <Typography className={classes.textSemiBold14}>
                  {data.email}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCellFullBorder}>
                <Typography className={classes.textSemiBold14}>
                  {data.phone || "-"}
                </Typography>
              </TableCell>
              <TableCell
                className={classes.tableCellFullBorder}
                style={{ minWidth: "250px", maxWidth: "250px" }}
              >
                <Tooltip
                  title={data.address || "-"}
                  followCursor
                  style={{ cursor: "pointer", wordBreak: "break-word" }}
                >
                  <Typography className={classes.textSemiBold14}>
                    {data.address
                      ? data.address.length > 80
                        ? `${data.address.substring(0, 80)}...`
                        : data.address
                      : "-"}
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell className={classes.tableCellFullBorder}>
                <Typography className={classes.textSemiBold14}>
                  {" "}
                  {data.rolesUserAccess.name || "-"}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCellFullBorder}>
                {showStatusData(data.status)}
              </TableCell>
              <TableCell className={classes.tableRow}>
                <Button
                  className={classes.editButton}
                  onClick={() => editUser(data)}
                >
                  {t(strings.textEditData)}
                </Button>
              </TableCell>
            </TableRow>
          );
        });
      } else {
        return (
          <TableRow>
            <TableCell colSpan={7} align="center" className={classes.tableRow}>
              {t(strings.textRecordsNotFound)}
            </TableCell>
          </TableRow>
        );
      }
    }
  };

  const showUserListFilter = () => {
    return (
      <div className={classes.divFilter}>
        <UserAction action={action} />
      </div>
    );
  };

  const showStatusData = (statusUser) => {
    return (
      <div className={classes.divRowStatus}>
        {statusUser === "Active" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="6"
            viewBox="0 0 7 6"
            fill="none"
          >
            <circle cx="3.16602" cy="3" r="3" fill="#4FBA77" />
          </svg>
        )}
        {statusUser === "Inactive" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="6"
            viewBox="0 0 7 6"
            fill="none"
          >
            <circle cx="3.16602" cy="3" r="3" fill="#EB5757" />
          </svg>
        )}
        {statusUser === "Suspend" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="6"
            viewBox="0 0 7 6"
            fill="none"
          >
            <circle cx="3.16602" cy="3" r="3" fill="#EFAF42" />
          </svg>
        )}
        <Typography className={classes.textSemiBold12}>{statusUser}</Typography>
      </div>
    );
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangeUser = (e, newValue) => {
    let targetName = e.target.name;
    if (targetName) {
      if (targetName === "role") {
        setUserData({
          ...userData,
          [targetName]: e.target.value,
          isAdmistrator:
            e.target.value === "Super Administrator" ? true : false,
          rolesName:
            e.target.value === "Super Administrator"
              ? "Super Administrator"
              : e.target.value,
        });
      } else {
        setUserData({ ...userData, [targetName]: e.target.value });
      }
    } else {
      setUserData({
        ...userData,
        permissionAccess: newValue,
      });
    }
  };

  const handleSubmitUserData = () => {
    submitUserData(
      t,
      userData,
      setLoadingSubmit,
      setDataNotification,
      handleCloseDialog,
      getUserList,
      isEdit
    );
  };

  const handleCloseNotification = () => {
    handleCloseNotif(setDataNotification);
  };

  return (
    <div className={classes.divContainer}>
      <SnackbarNotification
        open={dataNotification?.open}
        handleClose={handleCloseNotification}
        severity={dataNotification?.status}
        message={dataNotification?.message}
      />

      <div>
        <Typography className={classes.textBold24}>
          {t(strings.textUserList)}
        </Typography>
        <Typography className={classes.textGray16Color60}>
          {t(strings.textManageTeam)}
        </Typography>
      </div>
      <div className={classes.divTableData}>
        {showUserListFilter()}
        {showUserListTable()}
      </div>

      <AddEditUserDataDrawer
        t={t}
        isEdit={isEdit}
        loadingSubmit={loadingSubmit}
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        userData={userData}
        handleChangeUser={handleChangeUser}
        handleSubmitUserData={handleSubmitUserData}
      />
    </div>
  );
};

export default withTranslation("User")(ListUser);
