import { Link, Breadcrumbs as MUIBreadcrumbs, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const BreadCrumbs = ({ route }) => {
  const BreadCrumbsLink = (props) => {
    return <Link {...props} component={RouterLink} />;
  };
  return (
    <div>
      <MUIBreadcrumbs>
        {route?.map((el, index) => {
          return el.redirectTo ? (
            <BreadCrumbsLink to={el.redirectTo} key={index}>
              {el.name}
            </BreadCrumbsLink>
          ) : (
            <Typography key={index}>{el.name}</Typography>
          );
        })}
      </MUIBreadcrumbs>
    </div>
  );
};

export default BreadCrumbs;
