export const kycStrings = {
  textProviderTitle: "Provider",
  textProviderDescription: "Manage your Provider here.",
  textThresholdTitle: "Threshold",
  textThresholdDescription: "Manage your Threshold here.",
  textProviderName: "Provider Name",
  textProviderId: "Provider Id",
  textName: "Name",
  textHashKey: "Hash Key",
  textSecretKey: "Secret Key",
  textChannelId: "Channel ID",
  textToken: "Token",
  textPassword: "Password",
  textViewPassword: "View Password",
  textSystemId: "System ID",
  textThresholdValue: "Threshold Value",
  textAPIKey: "API Key",
  textBasicPrice: "Basic Price",
  textVATMerchant: "VAT Merchant",
  textStatus: "Status",
  textDescription: "Description",
  textAction: "Action",
  textViewDetails: "View Details",
  textEditData: "Edit Data",
  textDataNotFound: "Data Not Found",
  textActive: "Active",
  textInactive: "Inactive",
  textCreatedAt: "Created At",
  textDeletedProviderInfo: "Successfully deleted provider data",
  textCreatedProviderInfo: "Successfully created new provider data",
  textUpdatedProviderInfo: "Successfully updated provider data",
  textAddProvider: "Add E-KYC Provider",
  textAddNewProvider: "Add New E-KYC Provider",
  textEditProvider: "Edit E-KYC Provider",
  textProviderDetail: "E-KYC Provider Detail",
  textSubtitleProvider1:
    "Please complete your data below to add a new E-KYC Provider.",
  textSubtitleProvider2: "Update the data E-KYC Provider below.",
  textSearchProvider: "Search by E-KYC Provider name",
  textCreatedThresholdInfo: "Successfully created new threshold data",
  textUpdatedThresholdInfo: "Successfully updated threshold data",
  textAddThreshold: "Add E-KYC Threshold",
  textAddNewThreshold: "Add New E-KYC Threshold",
  textEditThreshold: "Edit E-KYC Threshold",
  textSubtitleThreshold1:
    "Please complete your data below to add a new E-KYC Threshold.",
  textSubtitleThreshold2: "Update the data E-KYC Threshold below.",
  textSearchThreshold: "Search by E-KYC Threshold name",
  textRecordsNotFound: "Records Not Found",
  textDeleteProvider: "Delete Provider",
  textSubDeleteProvider:
    "Deleting the Provider will permanently remove it from the Provider list.",
  textCancelled: "No, Keep Provider",
  textAccepted: "Yes, Delete Provider",
  textDelete: "Delete",
  textEdit: "Edit",
  textEnterProviderName: "Enter a provider name",
  textEnterSecretKey: "Enter a secret key",
  textEnterHashKey: "Enter a hash key",
  textEnterSystemId: "Enter a system id",
  textEnterAPIKey: "Enter an API key",
  textEnterToken: "Enter a token",
  textEnterPassword: "Enter a Password",
  textEnterName: "Enter a Name",
  textEnterThresholdValue: "Enter a threshold value",
  textEnterDescription: "Enter a description",
  textEnterChannelId: "Enter a channel id",
  textEnterProviderId: "Enter a provider id",
  // validation
  textInvalidProviderName: "Provider Name should not be empty",
  textInvalidName: "Name should not be empty",
  textInvalidNameFormat:
    "Incorrect name format. The name must not contain spaces or numbers",
  textInvalidSecretKey: "Secret Key should not be empty",
  textInvalidHashKey: "Hash Key should not be empty",
  textInvalidSystemId: "System ID should not be empty",
  textInvalidAPIKey: "API Key should not be empty",
  textInvalidToken: "Token should not be empty",
  textInvalidPassword: "Password should not be empty",
  textInvalidChannelId: "Channel ID should not be empty",
  textInvalidDescription: "Description should not be empty",
  textInvalidThresholdValue:
    "Threshold Value should be a number between 0 and 100",
  textInvalidBasicPrice1:
    "Basic Price should not be empty. Basic Price minimum value is 0",
  textInvalidBasicPrice2: "Basic Price must be a positive number",
  textInvalidVATMerchant1: "VAT Merchant should not be empty",
  textInvalidVATMerchant2:
    "VAT Merchant must be a positive number and cannot contain commas",
};
