import Cookies from "js-cookie";
import removeAllCookies from "../../General/Helper/removeAllCookies";
import RequestHttpGatewayInternal from "../../General/Helper/RequestHttpGatewayInternal";
import encryptedSecretKey from "../../General/Helper/encryptedSecretKey";
import * as routes from "../../General/Constant/routes";
import { fetchUserDataAsync } from "../../Redux/userDataSlice";

const onSubmitLogin = (
  email,
  password,
  setLoading,
  setDataNotification,
  navigate,
  dispatch
) => {
  setLoading(true);
  const reqBody = {
    email: email.toLowerCase(),
    password: password,
  };

  removeAllCookies();
  localStorage.clear();
  if ("caches" in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }
  const xReqKey = encryptedSecretKey();

  let currentDate = new Date();
  const timestamp = Math.floor(currentDate.getTime() / 1000);

  RequestHttpGatewayInternal.post(`user-access/login`, reqBody, {
    headers: {
      "X-Req-Inapass": xReqKey,
      timestamp: timestamp,
    },
  })
    .then((res) => {
      let dataLogin = null;
      if (res.data) {
        dataLogin = res.data.data;
      }
      Cookies.set("access_token", dataLogin.jwt, {
        secure: true,
      });
      Cookies.set("client_email", email.toLowerCase());
      Cookies.set("X-Req-Inapass", xReqKey);
      dispatch(fetchUserDataAsync());
      navigate(routes.DASHBOARD);
    })
    .catch((err) => {
      setDataNotification({
        open: true,
        status: "error",
        message: err.response.data.response_message,
      });
    })
    .finally(() => {
      setLoading(false);
    });
};

export default onSubmitLogin;
