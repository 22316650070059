import {
  Button,
  CircularProgress,
  Drawer,
  TextField,
  Typography,
} from "@mui/material";
import strings from "../../General/Constant/Locales/Strings/UserManageStrings";
import classes from "../Styles/usermanage.module.css";
import MultipleChipSelection from "../../General/Component/MultipleChipSelection";
import userPermissionMenu from "../Constant/userPermissionMenu";
import CloseIcon from "../../General/Component/CloseIcon";

const EditRoleDrawer = ({
  t,
  loadingEdit,
  openDialog,
  handleCloseDialog,
  userRoleEdit,
  handleChangeEditRole,
  handleSubmitEditRole,
}) => {
  const handleEditClasses = () => {
    if (
      !userRoleEdit.roleName ||
      userRoleEdit.permissionAccess?.length === 0 ||
      loadingEdit
    ) {
      return `${classes.disabledContainedButton}`;
    } else {
      return `${classes.containedButton}`;
    }
  };

  const handleEditButton = () => {
    if (
      !userRoleEdit.roleName ||
      userRoleEdit.permissionAccess?.length === 0 ||
      loadingEdit
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handlingSelectedItemsValue = () => {
    const result = [];

    userRoleEdit?.permissionAccess?.forEach((m) => {
      if (m.allowedSubMenuUserAccess?.length) {
        m.allowedSubMenuUserAccess.forEach((sm) => {
          if (sm.is_access) {
            result.push({
              id: sm.id,
              menu: sm.name,
              is_access: sm.is_access,
            });
          }
        });
      } else if (m.is_access) {
        result.push(m);
      }
    });
    return result;
  };

  const showFormField = () => {
    return (
      <div className={classes.divForm}>
        <div className={classes.divFormField}>
          <Typography className={classes.textBold14}>
            {t(strings.textRoleName)}
          </Typography>
          <TextField
            fullWidth
            className={classes.field}
            variant="standard"
            type="text"
            name="roleName"
            value={userRoleEdit.roleName}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.placeholder },
            }}
            onChange={handleChangeEditRole}
          />
          {!userRoleEdit.roleName && (
            <Typography className={classes.textAlert12}>
              {t(strings.textRoleNameIsRequired)}
            </Typography>
          )}
        </div>

        <div className={classes.divFormField}>
          <Typography className={classes.textBold14}>
            {t(strings.textUserAccessPermission)}
          </Typography>
          <MultipleChipSelection
            options={userPermissionMenu()}
            selectedItem={handlingSelectedItemsValue()}
            handleChangeCheckedMenu={handleChangeEditRole}
          />
        </div>

        <Button
          fullWidth
          className={handleEditClasses()}
          onClick={() => handleSubmitEditRole()}
          disabled={handleEditButton()}
        >
          {loadingEdit ? <CircularProgress size={"1em"} /> : null}

          {t(strings.textEditData)}
        </Button>
      </div>
    );
  };

  return (
    <Drawer
      anchor="right"
      open={openDialog}
      onClose={handleCloseDialog}
      variant="persistent"
    >
      <div className={classes.divBoxDrawer}>
        <div className={classes.divDialogEditTitle}>
          <div className={classes.divCloseEnd}>
            <CloseIcon handleCloseDialog={handleCloseDialog} />
          </div>
          <Typography className={classes.textBold16}>
            {t(strings.textEditUserRole)}
          </Typography>
          <Typography className={classes.textGray16Color60}>
            {t(strings.textEditUserRoleSubtitle)}
          </Typography>
        </div>
        <div className={classes.divDialogEdit}>
          <div>{showFormField()}</div>
        </div>
      </div>
    </Drawer>
  );
};

export default EditRoleDrawer;
