const IndonesianFlagIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="8"
    viewBox="0 0 18 8"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.571533H18V7.42868H0V0.571533Z"
      fill="#D0091E"
    />
  </svg>
);

export default IndonesianFlagIcon;
