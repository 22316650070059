import { FormControl, MenuItem, Select } from "@mui/material";
import * as React from "react";
import classes from "../Styles/general.module.css";
import strings from "../Constant/Locales/Strings/AppStrings";
import { withTranslation } from "react-i18next";

function FilterProduct({ t, reassignStyle, data, value, handleChangeValue }) {
  return (
    <FormControl
      className={`${classes.searchField} ${reassignStyle?.width}`}
      size="small"
      sx={{
        minWidth: 120,
        m: 1,
        "& fieldset": { border: "none" },
      }}
    >
      <Select
        value={value}
        onChange={handleChangeValue}
        inputProps={{
          className: classes.inputSearchField,
        }}
        IconComponent={() => (
          <img
            src="https://res.cloudinary.com/pastisah-id/image/upload/v1720605479/stack_lggokc.png"
            style={{
              width: "17px",
            }}
          />
        )}
      >
        <MenuItem className={classes.labelSelectOptions} value="All">
          {t(strings.textAllProducts)}
        </MenuItem>
        {data?.map((item, i) => {
          return (
            <MenuItem
              key={i}
              className={classes.labelSelectOptions}
              value={item.code}
            >
              {item.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default withTranslation("App")(FilterProduct);
