import strings from "../../General/Constant/Locales/Strings/MerchantManageStrings";
import RequestHttpGatewayInternal from "../../General/Helper/RequestHttpGatewayInternal";
import { getAuthenticatedServerRequest } from "../../General/Helper/auth";

const deleteProvider = async (
  t,
  providerActionData,
  setDataNotification,
  getMerchant,
  handleCloseViewProviderDialog
) => {
  const query = {
    id: providerActionData.id,
    merchant_id: providerActionData.merchant_id,
  };
  const result = await RequestHttpGatewayInternal({
    method: "DELETE",
    url: "/merchant-provider/delete",
    headers: getAuthenticatedServerRequest(),
    params: query,
  });
  if (result.data) {
    setDataNotification({
      open: true,
      status: "success",
      message: t(strings.textSuccessDeleteProvider),
    });
    handleCloseViewProviderDialog();
    getMerchant();
  }
};

export default deleteProvider;
