import { InputAdornment } from "@mui/material";
import { ApplyButton, FormInput } from "../../General/Component/FormInput";
import { PasswordEndAdornment } from "../../General/Component/PasswordEndAdornment";
import { FormView } from "../../General/Component/FormView";
import IncreaseDecreaseNumber from "../../General/Component/IncreaseDecreaseNumber";
import numberWithThousand from "../../General/Helper/numberWithThousand";
import classes from "../Styles/kyc.module.css";
import { DeleteButton, EditButton } from "../../General/Component/ButtonAction";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { kycStrings } from "../../General/Constant/Locales/Strings/EkycString";

const TranslatedProviderSetupData = ({
  t,
  actionData,
  onChangeValue,
  onSubmit,
  dialogOptions,
  labelOptions,
}) => {
  const {
    id,
    provider_name,
    secret_key,
    hash_key,
    system_id,
    apikey,
    token,
    password,
    channel_id,
    basic_price,
    vat_merchant,
    status,
    createdAt,
  } = actionData?.item;

  const [showPassword, setShowPassword] = useState(false);

  const formOptions = [
    {
      label: t(kycStrings.textProviderName),
      type: "text",
      key_value: "provider_name",
      value: provider_name,
      placeholder: t(kycStrings.textEnterProviderName),
    },
    {
      label: t(kycStrings.textSecretKey),
      type: "text",
      key_value: "secret_key",
      value: secret_key,
      placeholder: t(kycStrings.textEnterSecretKey),
    },
    {
      label: t(kycStrings.textHashKey),
      type: "text",
      key_value: "hash_key",
      placeholder: t(kycStrings.textEnterHashKey),
      value: hash_key,
    },
    {
      label: t(kycStrings.textSystemId),
      type: "text",
      key_value: "system_id",
      placeholder: t(kycStrings.textEnterSystemId),
      value: system_id,
    },
    {
      label: t(kycStrings.textAPIKey),
      type: "text",
      key_value: "apikey",
      placeholder: t(kycStrings.textEnterAPIKey),
      value: apikey,
    },
    {
      label: t(kycStrings.textToken),
      type: "text",
      key_value: "token",
      placeholder: t(kycStrings.textEnterToken),
      value: token,
    },
    {
      label: t(kycStrings.textPassword),
      type: showPassword ? "text" : "password",
      key_value: "password",
      placeholder: t(kycStrings.textEnterPassword),
      value: password,
      endAdornment: (
        <PasswordEndAdornment
          options={{
            showPassword: showPassword,
            handleClickShowPassword: () => setShowPassword(!showPassword),
          }}
        />
      ),
    },
    {
      label: t(kycStrings.textChannelId),
      type: "text",
      key_value: "channel_id",
      placeholder: t(kycStrings.textEnterChannelId),
      value: channel_id,
    },

    {
      label: t(kycStrings.textBasicPrice),
      type: "text",
      key_value: "basic_price",
      value:
        id && !dialogOptions?.openEdit
          ? `Rp. ${numberWithThousand(`${basic_price}`)}`
          : basic_price,
      startAdornment: <InputAdornment position="start">Rp</InputAdornment>,
      endAdornment: (
        <IncreaseDecreaseNumber
          fieldName={"basic_price"}
          value={basic_price}
          onChangeValue={(fieldName, value) => onChangeValue(fieldName, value)}
        />
      ),
    },
    {
      label: t(kycStrings.textVATMerchant),
      type: "text",
      key_value: "vat_merchant",
      value: id && !dialogOptions?.openEdit ? `${vat_merchant}%` : vat_merchant,
      startAdornment: <InputAdornment position="start">%</InputAdornment>,
      endAdornment: (
        <IncreaseDecreaseNumber
          fieldName={"vat_merchant"}
          value={vat_merchant}
          onChangeValue={(fieldName, value) => onChangeValue(fieldName, value)}
        />
      ),
    },
    {
      label: t(kycStrings.textStatus),
      type: "select",
      key_value: "status",
      value: status,
      select_options: [
        { label: t(kycStrings.textInactive), value: "false" },
        { label: t(kycStrings.textActive), value: "true" },
      ],
    },
  ];

  if (id && !dialogOptions?.openEdit) {
    const resultForm = formOptions.filter((el) => el.key_value !== "password");
    resultForm.push({
      label: t(kycStrings.textCreatedAt),
      value: createdAt,
    });

    return (
      <FormView
        formOptions={resultForm}
        buttonAction={
          <div className={classes.divGroupButton}>
            <DeleteButton
              handleOpenDialog={() =>
                dialogOptions?.handleOpenDrawerAction("delete")
              }
              text={labelOptions?.textDelete || t(kycStrings.textDelete)}
            />
            <EditButton
              handleOpenDialog={() =>
                dialogOptions?.handleOpenDrawerAction("edit")
              }
              text={labelOptions?.textEdit || t(kycStrings.textEdit)}
            />
          </div>
        }
      />
    );
  }

  return (
    <FormInput
      buttonAction={
        <ApplyButton
          buttonOptions={{
            label:
              labelOptions?.textEdit && id
                ? labelOptions?.textEdit
                : labelOptions?.textCreate
                ? labelOptions?.textCreate
                : t(`${id ? "Edit" : "Add New"} E-KYC Provider`),
            onSubmit: onSubmit,
            disabled: actionData?.loading,
            loading: actionData?.loading,
          }}
        />
      }
      formOptions={formOptions}
      onChangeValue={(fieldName, value) => onChangeValue(fieldName, value)}
    />
  );
};

const TranslatedThresholdSetupData = ({
  t,
  actionData,
  onChangeValue,
  onSubmit,
}) => {
  const { id, name, status, threshold_value, description } = actionData?.item;
  const formOptions = [
    {
      label: t(kycStrings.textName),
      type: "text",
      key_value: "name",
      value: name,
      placeholder: t(kycStrings.textEnterName),
    },
    {
      label: t(kycStrings.textStatus),
      type: "select",
      key_value: "status",
      value: status,
      select_options: [
        { label: t(kycStrings.textInactive), value: "false" },
        { label: t(kycStrings.textActive), value: "true" },
      ],
    },
    {
      label: t(kycStrings.textThresholdValue),
      type: "text",
      key_value: "threshold_value",
      placeholder: t(kycStrings.textEnterThresholdValue),
      value: threshold_value,
      startAdornment: <InputAdornment position="start">%</InputAdornment>,
      endAdornment: (
        <IncreaseDecreaseNumber
          fieldName={"threshold_value"}
          value={threshold_value}
          onChangeValue={(fieldName, value) => onChangeValue(fieldName, value)}
        />
      ),
    },
    {
      label: t(kycStrings.textDescription),
      type: "description",
      key_value: "description",
      placeholder: t(kycStrings.textEnterDescription),
      value: description,
    },
  ];
  return (
    <FormInput
      buttonAction={
        <ApplyButton
          buttonOptions={{
            label: t(`${id ? "Edit" : "Add New"} E-KYC Threshold`),
            onSubmit: onSubmit,
            disabled: actionData?.loading,
            loading: actionData?.loading,
          }}
        />
      }
      formOptions={formOptions}
      onChangeValue={(fieldName, value) => onChangeValue(fieldName, value)}
    />
  );
};

const TranslatedMerchantThresholdSetupData = ({
  t,
  actionData,
  onChangeValue,
  onSubmit,
  dialogOptions,
  labelOptions,
}) => {
  const { provider_name, id, thresholdValue, status, description } =
    actionData?.item;
  const formOptions = [
    {
      label: t(kycStrings.textProviderName),
      type: "text",
      key_value: "provider_name",
      value: provider_name,
      placeholder: t(kycStrings.textEnterProviderName),
      disabled: true,
    },
    {
      label: t(kycStrings.textStatus),
      type: "select",
      key_value: "status",
      value: status,
      select_options: [
        { label: t(kycStrings.textInactive), value: "false" },
        { label: t(kycStrings.textActive), value: "true" },
      ],
    },
    {
      label: t(kycStrings.textThresholdValue),
      type: "text",
      key_value: "thresholdValue",
      placeholder: t(kycStrings.textEnterThresholdValue),
      value: thresholdValue?.toString(),
      startAdornment: <InputAdornment position="start">%</InputAdornment>,
      endAdornment: (
        <IncreaseDecreaseNumber
          fieldName={"thresholdValue"}
          value={thresholdValue}
          onChangeValue={(fieldName, value) => onChangeValue(fieldName, value)}
        />
      ),
    },
    {
      label: t(kycStrings.textDescription),
      type: "description",
      key_value: "description",
      placeholder: t(kycStrings.textEnterDescription),
      value: description,
    },
  ];

  if (id && !dialogOptions?.openEdit) {
    const resultForm = formOptions.filter((el) => el.key_value !== "password");

    return (
      <FormView
        formOptions={resultForm}
        buttonAction={
          <div className={classes.divGroupButton}>
            <EditButton
              handleOpenDialog={() =>
                dialogOptions?.handleOpenDrawerAction("edit")
              }
              text={labelOptions?.textEdit || t(kycStrings.textEdit)}
            />
          </div>
        }
      />
    );
  }

  return (
    <FormInput
      buttonAction={
        <ApplyButton
          buttonOptions={{
            label: t(`${id ? "Edit" : "Add"}  Threshold`),
            onSubmit: onSubmit,
            disabled: actionData?.loading,
            loading: actionData?.loading,
          }}
        />
      }
      formOptions={formOptions}
      onChangeValue={(fieldName, value) => onChangeValue(fieldName, value)}
    />
  );
};

export const ProviderSetupData = withTranslation("Kyc")(
  TranslatedProviderSetupData
);
export const ThresholdSetupData = withTranslation("Kyc")(
  TranslatedThresholdSetupData
);

export const MerchantThresholdSetupData = withTranslation("Kyc")(
  TranslatedMerchantThresholdSetupData
);
