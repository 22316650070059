const EditIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
  >
    <path
      d="M15.9827 5.65887L12.8412 2.51661C12.7367 2.41211 12.6127 2.32922 12.4762 2.27267C12.3397 2.21612 12.1933 2.18701 12.0456 2.18701C11.8978 2.18701 11.7515 2.21612 11.615 2.27267C11.4785 2.32922 11.3545 2.41211 11.25 2.51661L2.57977 11.1875C2.47485 11.2916 2.39167 11.4155 2.33506 11.5521C2.27844 11.6886 2.24953 11.835 2.25001 11.9828V15.125C2.25001 15.4234 2.36853 15.7096 2.57951 15.9205C2.79049 16.1315 3.07664 16.25 3.37501 16.25H6.51727C6.66506 16.2505 6.81147 16.2216 6.94799 16.165C7.08451 16.1084 7.20842 16.0252 7.31251 15.9203L15.9827 7.25004C16.0872 7.14558 16.1701 7.02154 16.2267 6.88504C16.2832 6.74853 16.3123 6.60222 16.3123 6.45446C16.3123 6.3067 16.2832 6.16039 16.2267 6.02388C16.1701 5.88737 16.0872 5.76334 15.9827 5.65887ZM6.51727 15.125H3.37501V11.9828L9.56251 5.79528L12.7048 8.93754L6.51727 15.125ZM13.5 8.14161L10.3577 5.00004L12.0452 3.31254L15.1875 6.45411L13.5 8.14161Z"
      fill="#707070"
    />
  </svg>
);
export default EditIcon;
