import RequestHttpAuth from "../../General/Helper/RequestHttpAuth";
import addEditMerchant from "./addEditMerchant";

const uploadImage = (
  t,
  merchantData,
  setMerchantData,
  setLoadingSubmit,
  setDataNotification,
  handleCloseDialog,
  getMerchant,
  setUploadedData,
  openMerchantDialog
) => {
  setLoadingSubmit(true);
  const requestBodyImage = {
    base64File: `data:image/png;base64,${merchantData.base64Image}`,
    type_cloudinary: "logo",
  };

  RequestHttpAuth.post(`/cloudinary-service/upload`, requestBodyImage, {})
    .then((res) => {
      setMerchantData({ ...merchantData, imageURL: res.data.url });
      addEditMerchant(
        t,
        setLoadingSubmit,
        setDataNotification,
        { ...merchantData, imageURL: res.data.url },
        getMerchant,
        handleCloseDialog,
        setUploadedData,
        openMerchantDialog
      );
    })
    .catch((err) => {
      setLoadingSubmit(false);
      setDataNotification({
        open: true,
        status: "error",
        message:
          err.response.data.response_message || "Service is not available",
      });
    });
};

export default uploadImage;
