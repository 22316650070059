const ThreeDotsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M12.1875 10C12.1875 10.4326 12.0592 10.8556 11.8188 11.2153C11.5785 11.575 11.2368 11.8554 10.8371 12.021C10.4374 12.1866 9.99757 12.2299 9.57324 12.1455C9.14891 12.0611 8.75913 11.8527 8.45321 11.5468C8.14728 11.2409 7.93894 10.8511 7.85453 10.4268C7.77013 10.0024 7.81345 9.5626 7.97901 9.16288C8.14458 8.76317 8.42496 8.42153 8.78469 8.18116C9.14442 7.9408 9.56735 7.8125 10 7.8125C10.5802 7.8125 11.1366 8.04297 11.5468 8.45321C11.957 8.86344 12.1875 9.41984 12.1875 10ZM10 5.9375C10.4326 5.9375 10.8556 5.80921 11.2153 5.56884C11.575 5.32848 11.8554 4.98683 12.021 4.58712C12.1866 4.18741 12.2299 3.74757 12.1455 3.32324C12.0611 2.89891 11.8527 2.50913 11.5468 2.2032C11.2409 1.89728 10.8511 1.68894 10.4268 1.60453C10.0024 1.52013 9.56259 1.56345 9.16288 1.72901C8.76317 1.89458 8.42153 2.17496 8.18116 2.53469C7.9408 2.89442 7.8125 3.31735 7.8125 3.75C7.8125 4.33016 8.04297 4.88656 8.45321 5.2968C8.86344 5.70703 9.41984 5.9375 10 5.9375ZM10 14.0625C9.56735 14.0625 9.14442 14.1908 8.78469 14.4312C8.42496 14.6715 8.14458 15.0132 7.97901 15.4129C7.81345 15.8126 7.77013 16.2524 7.85453 16.6768C7.93894 17.1011 8.14728 17.4909 8.45321 17.7968C8.75913 18.1027 9.14891 18.3111 9.57324 18.3955C9.99757 18.4799 10.4374 18.4366 10.8371 18.271C11.2368 18.1054 11.5785 17.825 11.8188 17.4653C12.0592 17.1056 12.1875 16.6826 12.1875 16.25C12.1875 15.6698 11.957 15.1134 11.5468 14.7032C11.1366 14.293 10.5802 14.0625 10 14.0625Z"
      fill="#707070"
    />
  </svg>
);

export default ThreeDotsIcon;
