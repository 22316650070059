import {
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import classes from "../Styles/merchantmanage.module.css";
import strings from "../../General/Constant/Locales/Strings/MerchantManageStrings";
import FileUpload from "../../General/Component/FileUpload";
import emailValidation from "../../General/Helper/emailValidation";
import numericValidation from "../../General/Helper/numericValidation";
import CloseIcon from "../../General/Component/CloseIcon";
import IndonesianFlagIcon from "../../General/Icon/IndonesianFlagIcon";

const AddEditMerchantDrawer = ({
  t,
  merchantData,
  loadingSubmit,
  openDialog,
  handleCloseDialog,
  handleChangeMerchant,
  handleSubmitUserData,
  setUploadedData,
  uploadedData,
  setDataNotification,
}) => {
  const handleDisabledClasses = () => {
    if (
      !merchantData.merchantId ||
      !merchantData.companyEmail ||
      !emailValidation(merchantData.companyEmail) ||
      (merchantData.phoneNumber &&
        !numericValidation(merchantData.phoneNumber)) ||
      (merchantData.phoneNumber && merchantData.phoneNumber.length > 13) ||
      !merchantData.companyName ||
      loadingSubmit
    ) {
      return `${classes.disabledContainedButton}`;
    } else {
      return `${classes.containedButton}`;
    }
  };

  const handleDisabledButton = () => {
    if (
      !merchantData.merchantId ||
      !merchantData.companyEmail ||
      !emailValidation(merchantData.companyEmail) ||
      (merchantData.phoneNumber &&
        !numericValidation(merchantData.phoneNumber)) ||
      (merchantData.phoneNumber && merchantData.phoneNumber.length > 13) ||
      !merchantData.companyName ||
      loadingSubmit
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validatePhoneNumber = (phoneNumber) => {
    if (phoneNumber) {
      if (!numericValidation(phoneNumber)) {
        return (
          <Typography className={classes.textAlert12}>
            {t(strings.textInvalidPhoneNumber)}
          </Typography>
        );
      } else if (phoneNumber.length > 13) {
        return (
          <Typography className={classes.textAlert12}>
            {t(strings.textInvalidPhoneNumberLength)}
          </Typography>
        );
      } else {
        return null;
      }
    }
  };

  const showFormField = () => {
    return (
      <div className={classes.divForm}>
        <div className={classes.divFormField}>
          <Typography className={classes.textBold14}>
            {t(strings.textMerchantID)}
          </Typography>
          <TextField
            fullWidth
            className={classes.field}
            variant="standard"
            type="text"
            name="merchantId"
            value={merchantData.merchantId || ""}
            placeholder={t(strings.textPlaceholderMerchantID)}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.textSemiBold14 },
            }}
            onChange={handleChangeMerchant}
          />
        </div>
        <div className={classes.divFormField}>
          <Typography className={classes.textBold14}>
            {t(strings.textCompanyName)}
          </Typography>
          <TextField
            fullWidth
            className={classes.field}
            placeholder={t(strings.textPlaceholderCompanyName)}
            variant="standard"
            type="text"
            name="companyName"
            value={merchantData.companyName || ""}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.textSemiBold14 },
            }}
            onChange={handleChangeMerchant}
          />
        </div>
        <div className={classes.divFormField}>
          <Typography className={classes.textBold14}>
            {t(strings.textCompanyEmail)}
          </Typography>
          <TextField
            fullWidth
            className={classes.field}
            placeholder={t(strings.textPlaceholderCompanyEmail)}
            variant="standard"
            type="text"
            name="companyEmail"
            value={merchantData.companyEmail || ""}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.textSemiBold14 },
            }}
            onChange={handleChangeMerchant}
          />
          {merchantData.companyEmail &&
            !emailValidation(merchantData.companyEmail) && (
              <Typography className={classes.textAlert12}>
                {t(strings.textInvalidEmail)}
              </Typography>
            )}
        </div>
        <div className={classes.divFormField}>
          <Typography className={classes.textBold14}>
            {t(strings.textPICName)}
          </Typography>
          <TextField
            fullWidth
            className={classes.field}
            placeholder={t(strings.textPlaceholderCompanyName)}
            variant="standard"
            type="text"
            name="picName"
            value={merchantData.picName || ""}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.textSemiBold14 },
            }}
            onChange={handleChangeMerchant}
          />
        </div>
        <div className={classes.divFormField}>
          <Typography className={classes.textBold14}>
            {t(strings.textPhoneNumber)}
          </Typography>
          <TextField
            fullWidth
            className={classes.field}
            placeholder={t(strings.textPlaceholderPhone)}
            variant="standard"
            type="text"
            name="phoneNumber"
            value={merchantData.phoneNumber || ""}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.textSemiBold14 },
              startAdornment: (
                <div className={classes.startAdornmentNumber}>
                  <IndonesianFlagIcon />
                  <Typography className={classes.textBold14}>+62</Typography>
                </div>
              ),
            }}
            onChange={handleChangeMerchant}
          />
          {validatePhoneNumber(merchantData.phoneNumber)}
        </div>
        <div className={classes.divFormField}>
          <Typography className={classes.textBold14}>
            {t(strings.textAddress)}
          </Typography>
          <TextField
            fullWidth
            className={classes.addressField}
            placeholder={t(strings.textPlaceholderCompanyAddress)}
            variant="standard"
            type="text"
            name="address"
            multiline
            maxRows={4}
            value={merchantData.address || ""}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.textSemiBold14 },
            }}
            onChange={handleChangeMerchant}
          />
        </div>
        <div className={classes.divFormField}>
          <Typography className={classes.textBold14}>
            {t(strings.textUploadLogoCompany)}
          </Typography>
          <div className={classes.divFullLogo}>
            {merchantData?.imageURL && (
              <div className={classes.divLogoCompany}>
                <img
                  src={merchantData.imageURL}
                  className={classes.logoCompany}
                  alt="company logo"
                />
              </div>
            )}
            <FileUpload
              setUploadedData={setUploadedData}
              fileName={uploadedData.fileName}
              placeholderName={
                merchantData?.imageURL
                  ? t(strings.textChangeLogo)
                  : t(strings.textPlaceholderCompanyLogo)
              }
              extensionFile={".png, .jpg, .jpeg"}
              maxFile={1024 * 1024 * 10}
              setDataNotification={setDataNotification}
            />
          </div>
          <Typography className={classes.textGray12}>
            {t(strings.textInfoLogoQuality)}
          </Typography>
        </div>
        <Button
          fullWidth
          className={handleDisabledClasses()}
          onClick={() => handleSubmitUserData()}
          disabled={handleDisabledButton()}
        >
          {loadingSubmit ? <CircularProgress size={"1em"} /> : null}
          {openDialog.create
            ? t(strings.textRegisterMerchant)
            : openDialog.edit
            ? t(strings.textEditMerchant)
            : null}
        </Button>
      </div>
    );
  };
  return (
    <Drawer
      anchor="right"
      open={openDialog.create || openDialog.edit}
      onClose={handleCloseDialog}
      variant="persistent"
    >
      <div className={classes.divBoxDrawer}>
        <div className={classes.divDialogEditTitle}>
          <div className={classes.divCloseEnd}>
            <CloseIcon handleCloseDialog={handleCloseDialog} />
          </div>
          <Typography className={classes.textBold16}>
            {openDialog.edit
              ? t(strings.textEditMerchant)
              : t(strings.textRegisterMerchant)}
          </Typography>
          <Typography className={classes.textGray16Color60}>
            {t(strings.textRegisterMerchantSubtitle)}
          </Typography>
        </div>
        <div className={classes.divDialogEdit}>
          <div>{showFormField()}</div>
        </div>
      </div>
    </Drawer>
  );
};

export default AddEditMerchantDrawer;
