import onSubmitLogin from "./onSubmitLogin";

const validateLogin = (
  email,
  password,
  setDataNotification,
  setLoading,
  navigate,
  dispatch
) => {
  let error = 0;
  if (email) {
  } else {
    error = error + 1;
    setDataNotification({
      open: true,
      status: "error",
      message: "Email is Required",
    });
  }

  if (password) {
  } else {
    error = error + 1;
    setDataNotification({
      open: true,
      status: "error",
      message: "Password is Required",
    });
  }

  if (error === 0) {
    return onSubmitLogin(
      email,
      password,
      setLoading,
      setDataNotification,
      navigate,
      dispatch
    );
  }
};

export default validateLogin;
