import axios from "axios";
import config from "../Constant/configAuth";
import Cookies from "js-cookie";

const RequestHttpGatewayInternal = axios.create({
  baseURL: config.urlGatewayInternal,
});

RequestHttpGatewayInternal.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error?.response?.data?.response_message
        ?.toLowerCase()
        .includes("token authorization")
    ) {
      Cookies.remove("access_token");
      Cookies.remove("user_email");
      Cookies.remove("client_id");
      localStorage.clear();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default RequestHttpGatewayInternal;
