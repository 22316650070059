import { withTranslation } from "react-i18next";
import { EkycContent } from "../Component/content";
import { kycStrings } from "../../General/Constant/Locales/Strings/EkycString";
import { useEffect, useState } from "react";
import {
  createThresholdData,
  fetchThresholdData,
  updateThresholdData,
} from "../Helper/http-request";
import exportFromJSON from "export-from-json";
import DrawerAction from "../../General/Component/DrawerAction";
import { ThresholdSetupData } from "../Component/action";
import { validateInputThreshold } from "../Helper/validation";
import { generateId } from "../../General/Helper/auth";
import { ButtonAction } from "../../General/Component/ButtonAction";

function EkycThreshold(props) {
  const { t } = props;

  const [openDialog, setOpenDialog] = useState({
    edit: false,
    create: false,
  });

  const [actionData, setActionData] = useState({
    item: {
      id: null,
      name: "",
      status: "false",
      threshold_value: "0",
      description: "",
    },
    loading: false,
  });

  const [notificationData, setNotificationData] = useState({
    id: "",
    status: "",
    message: "",
  });

  const [tableOptions, setTableOptions] = useState({
    openDialog: openDialog.edit || openDialog.create,
    rowsData: [
      kycStrings.textName,
      kycStrings.textStatus,
      kycStrings.textThresholdValue,
      kycStrings.textDescription,
      kycStrings.textAction,
    ],
    colomnsData: [],
    loading: false,
    paginationOptions: {
      page: 1,
      rowsPerPage: 5,
      totalPages: 0,
      handlePageChange: async (pageValue) => {
        setTableOptions((to) => {
          fetchThresholdDataRequest({
            page: pageValue,
            limit: to.paginationOptions.rowsPerPage,
            name: to.filter.name,
          });
          return {
            ...to,
            paginationOptions: {
              ...to.paginationOptions,
              page: pageValue,
            },
          };
        });
      },
      handleRowsPerPage: async ({ target }) => {
        setTableOptions((to) => {
          fetchThresholdDataRequest({
            page: 1,
            limit: target.value,
            name: to.filter.name,
          });

          return {
            ...to,
            paginationOptions: {
              ...to.paginationOptions,
              rowsPerPage: target.value,
            },
          };
        });
      },
    },
    filter: {
      name: "",
    },
  });

  const setIsLoadingData = (value = true) => {
    setTableOptions((t) => ({
      ...t,
      loading: value,
    }));
  };

  const fetchThresholdDataRequest = async (request) => {
    if (request.isExport) {
      const data = await fetchThresholdData(request);
      if (!data.length) {
        return setNotificationData({
          id: generateId(),
          status: "error",
          message: t(kycStrings.textDataNotFound),
        });
      }

      return exportFromJSON({
        data,
        fileName: "Ekyc Threshold",
        exportType: request.exportType,
      });
    } else {
      setIsLoadingData();
      const data = await fetchThresholdData(request);
      setTableOptions((to) => ({
        ...to,
        colomnsData: data.items?.map((item) => {
          const { id, name, status, threshold_value, description } = item;
          return [
            name,
            status ? kycStrings.textActive : kycStrings.textInactive,
            `${threshold_value}%`,
            description,
            <ButtonAction
              translation="Kyc"
              text={kycStrings.textEditData}
              handleAction={() => {
                setOpenDialog({ edit: true, create: false });
                setTableOptions((t) => ({ ...t, openDialog: true }));
                setActionData((ad) => ({
                  ...ad,
                  item: {
                    id: id,
                    name: name,
                    status: status ? "true" : "false",
                    threshold_value: `${threshold_value}`,
                    description: description || "",
                  },
                }));
              }}
            />,
          ];
        }),
        paginationOptions: {
          ...to.paginationOptions,
          page: request.page,
          rowsPerPage: request.limit,
          totalPages: data.meta.totalPages,
        },
        loading: false,
        filter: {
          name: request.name,
        },
      }));
    }
  };

  const handleCloseDrawerAction = () => {
    if (!actionData.loading) {
      setOpenDialog({
        create: false,
        edit: false,
      });
      setTableOptions((t) => ({ ...t, openDialog: false }));
    }
  };

  const handleOpenDialogCreate = () => {
    setActionData((ad) => ({
      ...ad,
      item: {
        id: null,
        name: "",
        status: "false",
        threshold_value: "0",
        description: "",
      },
    }));
    setOpenDialog({ create: true, edit: false });
    setTableOptions((t) => ({ ...t, openDialog: true }));
  };

  useEffect(() => {
    const resultFirstRenderedData = async () => {
      await fetchThresholdDataRequest({
        page: tableOptions.paginationOptions.page,
        limit: tableOptions.paginationOptions.rowsPerPage,
        name: tableOptions.filter.name,
      });
    };
    resultFirstRenderedData();
  }, []);

  const handleChangeValueData = (fieldName, value) => {
    setActionData((ad) => ({
      ...ad,
      item: { ...ad.item, [fieldName]: value },
    }));
  };

  const handleSubmitData = async () => {
    const isInvalid = validateInputThreshold(actionData.item);
    if (isInvalid.length) {
      setNotificationData({
        id: generateId(),
        status: "error",
        message: t(isInvalid[0]),
      });
    } else {
      try {
        setActionData((ad) => ({ ...ad, loading: true }));
        const { id, name, threshold_value, status, description } =
          actionData.item;

        const bodyRequest = {
          name: name,
          threshold_value: Number(threshold_value),
          status: status === "true",
          description: description,
        };

        if (id) {
          await updateThresholdData(id, bodyRequest);
        } else {
          await createThresholdData(bodyRequest);
        }
        setTableOptions((to) => {
          fetchThresholdDataRequest({
            page: to.paginationOptions.page,
            limit: to.paginationOptions.rowsPerPage,
            name: to.filter.name,
          });
          return {
            ...to,
            openDialog: false,
          };
        });
        setOpenDialog({
          create: false,
          edit: false,
        });
        setNotificationData({
          id: generateId(),
          status: "success",
          message: t(
            `Successfully ${id ? "updated" : "created new"} threshold data`
          ),
        });
      } catch (error) {
        setNotificationData({
          id: generateId(),
          status: "error",
          message: t(error.response?.data?.response_message),
        });
      } finally {
        setActionData((ad) => ({ ...ad, loading: false }));
      }
    }
  };

  return (
    <>
      <EkycContent
        t={t}
        text={{
          title: kycStrings.textThresholdTitle,
          description: t(kycStrings.textThresholdDescription),
        }}
        notificationData={notificationData}
        tableOptions={tableOptions}
        fetchDataRequest={fetchThresholdDataRequest}
        handleOpenDialogCreate={handleOpenDialogCreate}
      />
      <DrawerAction
        text={{
          title: openDialog.create
            ? t(kycStrings.textAddThreshold)
            : t(kycStrings.textEditThreshold),
          subtitle: openDialog.create
            ? t(kycStrings.textSubtitleThreshold1)
            : t(kycStrings.textSubtitleThreshold2),
        }}
        openDialog={openDialog.create || openDialog.edit}
        handleCloseDrawer={handleCloseDrawerAction}
        content={
          <ThresholdSetupData
            actionData={actionData}
            onChangeValue={handleChangeValueData}
            onSubmit={handleSubmitData}
          />
        }
      />
    </>
  );
}

export default withTranslation("Kyc")(EkycThreshold);
