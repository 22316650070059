import {
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
} from "@mui/material";
import classes from "../Styles/merchantmanage.module.css";
import { useState } from "react";
import merchantActions from "../Constant/merchantActions";
import strings from "../../General/Constant/Locales/Strings/MerchantManageStrings";

import AddIcon from "../../General/Icon/AddIcon";
import EditIcon from "../../General/Icon/EditIcon";
import ThreeDotsIcon from "../../General/Icon/ThreeDotsIcon";

const ThreeeDotsButton = ({
  t,
  handleOpenProviderDrawer,
  handleOpenThresholdDrawer,
  handleOpenMerchantDrawer,
  optionData,
  setOpenProviderDialog,
  setOpenMerchantDialog,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const isHavingThreshold = optionData?.filter((data) => {
    return data.merchantThreshold !== null;
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeItems = (item) => {
    if (item.id === "ViewProviderDetail") {
      handleViewMerchantProvider(item);
    } else if ((item.id = "ViewThresholdDetail")) {
      handleViewMerchantThreshold(item);
    }
    handleClose();
  };

  const handleAddNewProvider = () => {
    // setOpenProviderDialog({
    //   view: false,
    //   create: true,
    //   edit: false,
    // });
    handleClose();
    handleOpenProviderDrawer("create");
  };

  const handleViewMerchantProvider = () => {
    // setOpenProviderDialog({
    //   view: true,
    //   create: false,
    //   edit: false,
    // });
    handleClose();
    handleOpenProviderDrawer("view");
  };

  const handleEditMerchant = () => {
    handleClose();
    handleOpenMerchantDrawer("edit");
  };

  const handleViewMerchantThreshold = () => {
    handleClose();
    handleOpenThresholdDrawer("view");
  };

  const customButton = [
    {
      id: "EditMerchant",
      label: t(strings.textEditMerchant),
      icon: <EditIcon />,
      handleChangeButton: () => handleEditMerchant(),
    },
    {
      id: "AddProvider",
      label: t(strings.textAddNewProvider),
      icon: <AddIcon />,
      handleChangeButton: () => handleAddNewProvider(),
    },
  ];

  const showActionButton = () => {
    return (
      <div>
        <Button
          className={classes.languageButton}
          aria-describedby={id}
          onClick={handleClick}
        >
          <ThreeDotsIcon />
        </Button>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          className={classes.popoverUserSetting}
          classes={{ paper: classes.paperUserSetting }}
        >
          <List className={classes.listItemAction}>
            {optionData.length > 0 &&
              merchantActions(t).map((data, index) => {
                if (
                  isHavingThreshold.length === 0 &&
                  data.id === "ViewThresholdDetail"
                ) {
                  return null;
                } else {
                  return (
                    <div key={index}>
                      <ListItemButton
                        key={data.id}
                        onClick={() => handleChangeItems(data)}
                        className={classes.itemActionButton}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          {data.icon}
                        </ListItemIcon>
                        <ListItemText primary={data.label} />
                      </ListItemButton>
                    </div>
                  );
                }
              })}
            {customButton.map((data, index) => (
              <div key={index}>
                {data.id === "AddProvider" && (
                  <div className={classes.grayLine} />
                )}
                <ListItemButton
                  key={data.id}
                  onClick={() => data.handleChangeButton()}
                  className={classes.itemActionButton}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    {data.icon}
                  </ListItemIcon>
                  <ListItemText primary={data.label} />
                </ListItemButton>
              </div>
            ))}
          </List>
        </Popover>
      </div>
    );
  };

  return showActionButton();
};

export default ThreeeDotsButton;
