import { Typography } from "@mui/material";
import classes from "../Styles/dashboard.module.css";

const pieColors = [
  { name: "SSO", value: "#4FBA77" },
  { name: "Digital Card", value: "#EB5757" },
  { name: "E-KYC", value: "#EFAF42" },
];

const getColor = (type) => {
  const color = pieColors.find((item) => item.name === type);
  return color ? color.value : "#8884d8";
};

const getLinearColor = () => {
  return (
    <defs>
      <linearGradient id="digitalCardColor" x1="0" y1="0" x2="0" y2="1">
        <stop offset="0.34709" stop-color="#EB5757" stop-opacity="0.1" />
        <stop offset="0.662592" stop-color="#EB5757" stop-opacity="0" />
      </linearGradient>
      <linearGradient id="ekycColor" x1="0" y1="0" x2="0" y2="1">
        <stop offset="0.005" stop-color="#EFAF42" stop-opacity="0.1" />
        <stop offset="1" stop-color="#EFAF42" stop-opacity="0" />
      </linearGradient>
      <linearGradient id="ssoColor" x1="0" y1="0" x2="0" y2="1">
        <stop offset="0.005" stop-color="#4FBA77" stop-opacity="0.1" />
        <stop offset="1" stop-color="#4FBA77" stop-opacity="0" />
      </linearGradient>
    </defs>
  );
};

const CustomPieTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0];
    const fillColor = getColor(data.name);

    return (
      <div className={classes.customTooltip}>
        <div
          className={classes.tooltipCircle}
          style={{ backgroundColor: fillColor }}
        />
        <div>
          <Typography
            className={classes.textRegular10}
            style={{ color: "#FFF" }}
          >
            {data.name}
          </Typography>
          <Typography className={classes.textBold10} style={{ color: "#FFF" }}>
            {data.value}
          </Typography>
        </div>
      </div>
    );
  }

  return null;
};

const CustomAreaTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className={classes.customAreaTooltip}>
        <Typography className={classes.textBold12} style={{ color: "#FFF" }}>
          {label}
        </Typography>
        {payload.map((entry, index) => (
          <div key={index} className={classes.tooltipRowItem}>
            <div
              className={classes.tooltipCircle}
              style={{ backgroundColor: entry.color }}
            />
            <div className={classes.tooltipRowItem}>
              <div className={classes.textRegular10} style={{ color: "#FFF" }}>
                {entry.name}
              </div>
              <div className={classes.textBold10} style={{ color: "#FFF" }}>
                {entry.value}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

export { getColor, CustomPieTooltip, CustomAreaTooltip, getLinearColor };
