import Cookies from "js-cookie";

const removeAllCookies = () => {
  // Get an array of all cookie names
  const cookieNames = Object.keys(Cookies.get());

  // Iterate through the array and remove each cookie
  cookieNames.forEach((cookieName) => {
    Cookies.remove(cookieName);
  });
};

export default removeAllCookies;
