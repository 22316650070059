import RequestHttpGatewayInternal from "../../General/Helper/RequestHttpGatewayInternal";
import { getAuthenticatedServerRequest } from "../../General/Helper/auth";

const fetchProviderData = async (
  query = {
    page: 1,
    limit: 5,
    provider_name: "",
    isExport: false,
  }
) => {
  const result = await RequestHttpGatewayInternal({
    method: "GET",
    url: "/ekyc-provider/list",
    headers: getAuthenticatedServerRequest(),
    params: { ...query, order: "DESC" },
  });
  return result.data?.data;
};

const createOrUpdateProviderData = async (
  bodyRequest = {
    ekyc_provider: [
      {
        // id: 1,  update by id required
        provider_name: "", // update by name required
        secret_key: "",
        hash_key: "",
        system_id: "",
        apikey: "",
        token: "",
        password: "",
        channel_id: "",
        basic_price: 0, // Positive Number
        vat_merchant: 0, // Positive Number
        status: false,
      },
    ],
  }
) => {
  const result = await RequestHttpGatewayInternal({
    method: "POST",
    url: "/ekyc-provider/create/update",
    headers: getAuthenticatedServerRequest(),
    data: bodyRequest,
  });
  return result.data;
};

const deleteProviderData = async (
  id // number of provider
) => {
  const result = await RequestHttpGatewayInternal({
    method: "DELETE",
    url: "/ekyc-provider/delete",
    headers: getAuthenticatedServerRequest(),
    params: {
      id,
    },
  });
  return result.data;
};

const fetchThresholdData = async (
  query = {
    page: 1,
    limit: 5,
    name: "",
    isExport: false,
  }
) => {
  const result = await RequestHttpGatewayInternal({
    method: "GET",
    url: "/ekyc-threshold/list",
    headers: getAuthenticatedServerRequest(),
    params: { ...query, order: "DESC" },
  });
  return result.data?.data;
};

const createThresholdData = async (
  bodyRequest = {
    // all required
    name: "", // contains the name excluding the space
    threshold_value: "", // x = 0  < 100
    status: false,
    description: "",
  }
) => {
  const result = await RequestHttpGatewayInternal({
    method: "POST",
    url: "/ekyc-threshold/create",
    headers: getAuthenticatedServerRequest(),
    data: bodyRequest,
  });
  return result.data;
};

const updateThresholdData = async (
  id,
  bodyRequest = {
    // all required
    name: "", // contains the name excluding the space
    threshold_value: "", // x = 0  < 100
    status: false,
    description: "",
  }
) => {
  const result = await RequestHttpGatewayInternal({
    method: "PUT",
    url: "/ekyc-threshold/edit",
    headers: getAuthenticatedServerRequest(),
    params: {
      id,
    },
    data: bodyRequest,
  });
  return result.data;
};

export {
  fetchProviderData,
  createOrUpdateProviderData,
  deleteProviderData,
  fetchThresholdData,
  createThresholdData,
  updateThresholdData,
};
