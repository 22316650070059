import React, { useEffect, useState } from "react";
import classes from "../Styles/product.module.css";
import SnackbarNotification from "../../General/Component/SnackbarNotification";
import { Typography } from "@mui/material";
import { UserAction } from "../../General/Component/UserAction";
import { TableView } from "../../General/Component/table";
import LocationOnMaps from "../../General/Component/LocationOnMaps";
import DrawerAction from "../../General/Component/DrawerAction";
import { fetchDataProductActivityType } from "../Helper/http-request";
import getDateFromTo from "../../General/Helper/getDateFromTo";
import moment from "moment";

export const ProductContent = (props) => {
  const {
    t,
    setNotificationData,
    notificationData,
    text,
    tableOptions,
    fetchDataRequest,
    detailTrx,
    openDialog,
    handleCloseDrawer,
  } = props;
  const [notificationAction, setNotificationAction] = useState({
    open: false,
    handleClose: () =>
      setNotificationAction({ ...notificationAction, open: false }),
  });

  useEffect(() => {
    const resultRenderListType = async () => {
      const result = await fetchDataProductActivityType(setNotificationData);
      setAction((a) => ({
        ...a,
        product: {
          ...a.product,
          data: result,
        },
      }));
    };

    resultRenderListType();
  }, []);

  useEffect(() => {
    const value_default = "l30day";
    const { dtFrom, dtTo } = getDateFromTo(value_default);
    setAction((a) => ({
      ...a,
      date: {
        ...a.date,
        start_date: dtFrom,
        end_date: dtTo,
        selected_value: value_default,
      },
    }));
  }, []);

  const [action, setAction] = useState({
    search: {
      isComponent: true,
      reassignStyle: {
        placeholder: t(text.search),
        width: classes.searchWidth,
      },
      value: "",
      handleChangeValue: async ({ target }) => {
        setAction((a) => {
          const request = {
            page: tableOptions.paginationOptions.page,
            limit: tableOptions.paginationOptions.rowsPerPage,
            search: target.value,
            startDate: moment(a.date.start_date).format("YYYY-MM-DD HH:mm:ss"),
            endDate: moment(a.date.end_date).format("YYYY-MM-DD HH:mm:ss"),
            export: tableOptions.params.export,
            productActivityType:
              a.product.value === "All" ? "" : a.product.value,
          };
          fetchDataRequest(request);
          return {
            ...a,
            search: { ...a.search, value: target.value },
          };
        });
      },
    },
    date: {
      translation: "Product",
      isComponent: true,
      start_date: null,
      end_date: null,
      selected_value: "",
      anchorEl: null,
      reassignStyle: {
        width: classes.searchDateWidth,
        title: text.activity_date,
        l7day: text.l7day,
        l30day: text.l30day,
        custom_date: text.custom_date,
        desc_custom_date: text.desc_custom_date,
        start_date: text.start_date,
        end_date: text.end_date,
        apply_filter: text.apply_filter,
      },
      handleChangeRadioButtonDateRange: async ({ target }) => {
        setAction((a) => {
          const { dtFrom, dtTo } = getDateFromTo(target.value);
          return {
            ...a,
            date: {
              ...a.date,
              selected_value: target.value,
              start_date: dtFrom,
              end_date: dtTo,
            },
          };
        });
      },
      handleChangeCustomDateRange: async (key, value) => {
        setAction((a) => {
          if (key === "start_date") {
            return {
              ...a,
              date: {
                ...a.date,
                start_date: value,
                selected_value: "",
              },
            };
          } else {
            return {
              ...a,
              date: {
                ...a.date,
                end_date: value,
                selected_value: "",
              },
            };
          }
        });
      },
      handleChangeDate: async ({ target }) => {
        setAction((a) => {
          const request = {
            page: tableOptions.paginationOptions.page,
            limit: tableOptions.paginationOptions.rowsPerPage,
            search: a.search.value,
            startDate: moment(a.date.start_date).format("YYYY-MM-DD HH:mm:ss"),
            endDate: moment(a.date.end_date).format("YYYY-MM-DD HH:mm:ss"),
            export: tableOptions.params.export,
            productActivityType:
              a.product.value === "All" ? "" : a.product.value,
          };
          fetchDataRequest(request);
          return {
            ...a,
            date: {
              ...a.date,
              start_date: a.date.start_date,
              end_date: a.date.end_date,
              anchorEl: null,
            },
          };
        });
      },
      handleClick: async (event) => {
        setAction((a) => {
          return {
            ...a,
            date: {
              ...a.date,
              anchorEl: event.currentTarget,
            },
          };
        });
      },
      handleClose: async (_action) => {
        setAction((a) => {
          return {
            ...a,
            date: {
              ...a.date,
              anchorEl: null,
            },
          };
        });
      },
    },
    product: {
      isComponent: true,
      reassignStyle: {
        width: classes.searcProducthWidth,
      },
      data: [],
      value: "All",
      handleChangeValue: async ({ target }) => {
        setAction((a) => {
          const request = {
            page: tableOptions.paginationOptions.page,
            limit: tableOptions.paginationOptions.rowsPerPage,
            search: a.search.value,
            startDate: moment(a.date.start_date).format("YYYY-MM-DD HH:mm:ss"),
            endDate: moment(a.date.end_date).format("YYYY-MM-DD HH:mm:ss"),
            export: tableOptions.params.export,
            productActivityType: target.value === "All" ? "" : target.value,
          };
          fetchDataRequest(request);
          return {
            ...a,
            product: { ...a.product, value: target.value },
          };
        });
      },
    },
    export: {
      isComponent: true,
      open: false,
      handleData: (exportType) => {
        setAction((a) => {
          const request = {
            page: tableOptions.paginationOptions.page,
            limit: tableOptions.paginationOptions.rowsPerPage,
            search: a.search.value,
            startDate: moment(a.date.start_date).format("YYYY-MM-DD HH:mm:ss"),
            endDate: moment(a.date.end_date).format("YYYY-MM-DD HH:mm:ss"),
            productActivityType:
              a.product.value === "All" ? "" : a.product.value,
            export: true,
            exportType,
          };
          fetchDataRequest(request);
          return {
            ...a,
            export: { ...a.export, open: false },
          };
        });
      },
      handleOpenClose: (value) =>
        setAction((a) => {
          return {
            ...a,
            export: { ...a.export, open: value },
          };
        }),
    },
  });

  const ContentDetail = () => {
    return (
      <div className={classes.paddingContentDetail}>
        {detailTrx?.map((item) => {
          return (
            <div className={classes.divLabelandText}>
              <Typography className={classes.textLabelDetail}>
                {item.title}
              </Typography>
              {item.desc === "loc" ? (
                <>
                  {item.value !== null ? (
                    <>
                      <Typography className={classes.textValueDetail}>
                        {item.address ? item.address : "-"}
                      </Typography>
                      <LocationOnMaps location={item.value} />
                    </>
                  ) : (
                    <Typography className={classes.textValueDetail}>
                      {text.invalid_loc}
                    </Typography>
                  )}
                </>
              ) : (
                <>
                  <Typography className={classes.textValueDetail}>
                    {item.value ? item.value : "-"}
                  </Typography>
                </>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    if (notificationData.open) {
      setNotificationAction((na) => ({ ...na, open: true }));
    }
  }, [notificationData.open]);

  return (
    <>
      <div className={classes.divContainer}>
        <SnackbarNotification
          open={notificationAction.open}
          handleClose={notificationAction.handleClose}
          severity={notificationData.status}
          message={notificationData.message}
        />

        <DrawerAction
          text={{ title: text.title }}
          openDialog={openDialog.view}
          handleCloseDrawer={handleCloseDrawer}
          content={<ContentDetail />}
        />

        <div>
          <Typography className={classes.textTitle}>{text.title}</Typography>
          <Typography className={classes.textSubtitle}>
            {text.description}
          </Typography>
          <div className={classes.divTableData}>
            <UserAction translation={"Product"} action={action} />
            <TableView t={t} tableOptions={tableOptions} />
          </div>
        </div>
      </div>
    </>
  );
};
