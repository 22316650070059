import {
  Button,
  CircularProgress,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import classes from "../Styles/general.module.css";

export const Canvas = ({ content }) => {
  return (
    <div className={classes.divDialogEdit}>
      <div>
        <div className={classes.divForm}>{content}</div>
      </div>
    </div>
  );
};

export const FormInputCanvas = ({ content }) => {
  return <div className={classes.divFormField}>{content}</div>;
};

export const ApplyButton = ({ buttonOptions }) => {
  return (
    <Button
      fullWidth
      className={`${classes.containedFormButton}`}
      onClick={buttonOptions?.onSubmit}
      disabled={buttonOptions?.disabled}
    >
      {buttonOptions?.loading ? <CircularProgress size={"1em"} /> : null}
      {buttonOptions?.label}
    </Button>
  );
};

export const FormInput = ({ buttonAction, formOptions, onChangeValue }) => {
  return (
    <Canvas
      content={
        <>
          {formOptions?.map((option, key) => {
            let component = null;
            if (option?.type === "select") {
              component = (
                <FormControl fullWidth>
                  <Select
                    SelectDisplayProps={{ className: classes.field }}
                    sx={{
                      boxShadow: "none",
                      ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      "& .MuiSvgIcon-root": {
                        color: "black",
                      },
                      "&:hover .Mui-component-select-status": {
                        color: "red",
                      },
                    }}
                    name={option?.key_value}
                    value={option?.value}
                    onChange={({ target }) =>
                      onChangeValue(target.name, target.value)
                    }
                    //   MenuProps={{ padding: "0px" }}
                  >
                    {option?.select_options?.map((data, i) => {
                      return (
                        <MenuItem value={data?.value} key={i}>
                          <ListItemText className={classes.textSemiBold14}>
                            {data?.label}
                          </ListItemText>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              );
            } else if (option.type === "description") {
              component = (
                <TextField
                  fullWidth
                  className={classes.descriptionField}
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.textSemiBold14 },
                  }}
                  placeholder={option?.placeholder}
                  variant="standard"
                  type="text"
                  multiline
                  maxRows={4}
                  name={option?.key_value}
                  value={option?.value}
                  onChange={({ target }) =>
                    onChangeValue(target.name, target.value)
                  }
                />
              );
            } else {
              component = (
                <TextField
                  fullWidth
                  className={classes.field}
                  InputProps={{
                    disableUnderline: true,
                    classes: { input: classes.textSemiBold14 },
                    startAdornment: option?.startAdornment,
                    endAdornment: option?.endAdornment,
                    sx: {},
                  }}
                  disabled={option?.disabled}
                  variant="standard"
                  placeholder={option?.placeholder}
                  type={option?.type}
                  name={option?.key_value}
                  value={option?.value}
                  onChange={({ target }) =>
                    onChangeValue(target.name, target.value)
                  }
                />
              );
            }

            return (
              <FormInputCanvas
                key={key}
                content={
                  <>
                    <Typography className={classes.textBold14}>
                      {option?.label}
                    </Typography>
                    {component}
                  </>
                }
              />
            );
          })}
          {buttonAction ? buttonAction : null}
        </>
      }
    />
  );
};
