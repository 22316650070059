import strings from "../../General/Constant/Locales/Strings/MerchantManageStrings";
import ViewIcon from "../../General/Icon/ViewIcon";

const merchantActions = (t) => {
  return [
    {
      id: "ViewProviderDetail",
      label: t(strings.textViewProviderDetail),
      icon: <ViewIcon />,
    },
    {
      id: "ViewThresholdDetail",
      label: t(strings.textViewThresholdDetail),
      icon: <ViewIcon />,
    },
  ];
};

export default merchantActions;
