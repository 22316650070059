import { withTranslation } from "react-i18next";
import { EkycContent } from "../Component/content";
import { kycStrings } from "../../General/Constant/Locales/Strings/EkycString";
import { useEffect, useState } from "react";
import {
  createOrUpdateProviderData,
  deleteProviderData,
  fetchProviderData,
} from "../Helper/http-request";
import exportFromJSON from "export-from-json";
import numberWithThousand from "../../General/Helper/numberWithThousand";
import DrawerAction from "../../General/Component/DrawerAction";
import { ProviderSetupData } from "../Component/action";
import moment from "moment";
import { validateInputProvider } from "../Helper/validation";
import { generateId } from "../../General/Helper/auth";
import DeletePopUp from "../../General/Component/DeletePopUp";
import { ButtonAction } from "../../General/Component/ButtonAction";

function EkycProvider(props) {
  const { t } = props;

  const [openDialog, setOpenDialog] = useState({
    view: false,
    edit: false,
    delete: false,
    create: false,
  });

  const [actionData, setActionData] = useState({
    item: {
      id: null,
      provider_name: "",
      secret_key: "",
      hash_key: "",
      system_id: "",
      apikey: "",
      token: "",
      password: "",
      channel_id: "",
      basic_price: "0",
      vat_merchant: "0",
      status: "false",
    },
    loading: false,
  });

  const [notificationData, setNotificationData] = useState({
    status: "",
    message: "",
  });

  const [tableOptions, setTableOptions] = useState({
    openDialog: openDialog.view || openDialog.create,
    rowsData: [
      kycStrings.textName,
      kycStrings.textHashKey,
      kycStrings.textSystemId,
      kycStrings.textAPIKey,
      kycStrings.textBasicPrice,
      kycStrings.textVATMerchant,
      kycStrings.textStatus,
      kycStrings.textAction,
    ],
    colomnsData: [],
    loading: false,
    paginationOptions: {
      rowsPerPage: 5,
      page: 1,
      totalPages: 0,
      handlePageChange: async (pageValue) => {
        setTableOptions((to) => {
          fetchProviderDataRequest({
            page: pageValue,
            limit: to.paginationOptions.rowsPerPage,
            provider_name: to.filter.provider_name,
          });
          return {
            ...to,
            paginationOptions: {
              ...to.paginationOptions,
              page: pageValue,
            },
          };
        });
      },
      handleRowsPerPage: async ({ target }) => {
        setTableOptions((to) => {
          fetchProviderDataRequest({
            page: 1,
            limit: target.value,
            provider_name: to.filter.provider_name,
          });

          return {
            ...to,
            paginationOptions: {
              ...to.paginationOptions,
              rowsPerPage: target.value,
            },
          };
        });
      },
    },
    filter: {
      provider_name: "",
    },
  });

  const setIsLoadingData = (value = true) => {
    setTableOptions((t) => {
      return {
        ...t,
        loading: value,
      };
    });
  };

  const fetchProviderDataRequest = async (request) => {
    request["provider_name"] = request.provider_name || request.name;
    delete request["name"];

    if (request.isExport) {
      const data = await fetchProviderData(request);
      if (!data.length) {
        return setNotificationData({
          id: generateId(),
          status: "error",
          message: t(kycStrings.textDataNotFound),
        });
      }
      return exportFromJSON({
        data,
        fileName: "Ekyc Provider",
        exportType: request.exportType,
      });
    } else {
      setIsLoadingData();
      const data = await fetchProviderData(request);
      setTableOptions((to) => {
        return {
          ...to,
          colomnsData: data.items?.map((item) => {
            const {
              id,
              provider_name,
              hash_key,
              system_id,
              apikey,
              basic_price,
              vat_merchant,
              status,
              secret_key,
              token,
              password,
              channel_id,
              createdAt,
            } = item;
            return [
              provider_name,
              hash_key || "-",
              system_id || "-",
              apikey || "-",
              `Rp. ${numberWithThousand(`${basic_price}`)}`,
              `${vat_merchant * 100}%` || "-",
              status ? kycStrings.textActive : kycStrings.textInactive,
              <ButtonAction
                translation={"Kyc"}
                text={kycStrings.textViewDetails}
                handleAction={() => {
                  setOpenDialog({
                    view: true,
                    create: false,
                    edit: false,
                    delete: false,
                  });
                  setTableOptions((t) => ({ ...t, openDialog: true }));
                  setActionData((pd) => ({
                    ...pd,
                    item: {
                      id: id,
                      provider_name: provider_name,
                      secret_key: secret_key || "",
                      hash_key: hash_key || "",
                      system_id: system_id || "",
                      apikey: apikey || "",
                      token: token || "",
                      password: password || "",
                      channel_id: channel_id || "",
                      basic_price: `${basic_price}`,
                      vat_merchant: `${vat_merchant * 100}`,
                      status: status ? "true" : "false",
                      createdAt: moment(createdAt).format(
                        "YYYY-MM-DD HH:mm:ss"
                      ),
                    },
                  }));
                }}
              />,
            ];
          }),
          paginationOptions: {
            ...to.paginationOptions,
            page: request.page,
            rowsPerPage: request.limit,
            totalPages: data.meta.totalPages,
          },
          loading: false,
          filter: {
            provider_name: request.provider_name || request.name,
          },
        };
      });
    }
  };

  const handleOpenDrawerAction = (action) => {
    if (action === "edit") {
      setOpenDialog((od) => ({ ...od, edit: true }));
    } else if (action === "delete") {
      setOpenDialog((od) => ({ ...od, delete: true }));
    } else if (action === "create") {
      setActionData({
        item: {
          id: null,
          provider_name: "",
          secret_key: "",
          hash_key: "",
          system_id: "",
          apikey: "",
          token: "",
          password: "",
          channel_id: "",
          basic_price: "0",
          vat_merchant: "0",
          status: "false",
        },
        loading: false,
      });
      setOpenDialog({
        create: true,
        view: false,
        edit: false,
        delete: false,
      });
      setTableOptions((t) => ({ ...t, openDialog: true }));
    }
  };

  const handleCloseDrawerAction = (action) => {
    if (!actionData.loading) {
      if (action === "delete") {
        setOpenDialog((od) => ({ ...od, delete: false }));
      } else if (openDialog.edit === true) {
        setOpenDialog((od) => ({ ...od, edit: false }));
      } else {
        setOpenDialog({
          create: false,
          view: false,
          edit: false,
          delete: false,
        });
        setTableOptions((t) => ({ ...t, openDialog: false }));
      }
    }
  };

  useEffect(() => {
    const resultFirstRenderedData = async () => {
      await fetchProviderDataRequest({
        page: tableOptions.paginationOptions.page,
        limit: tableOptions.paginationOptions.rowsPerPage,
        provider_name: tableOptions.filter.provider_name,
      });
    };
    resultFirstRenderedData();
  }, []);

  const handleChangeValueData = (fieldName, value) => {
    setActionData((ad) => ({
      ...ad,
      item: { ...ad.item, [fieldName]: value },
    }));
  };

  const handleSubmitData = async (action) => {
    const { id } = actionData.item;
    if (action === "delete") {
      setOpenDialog({
        view: false,
        edit: false,
        delete: false,
        create: false,
      });
      await deleteProviderData(id);
      setTableOptions((to) => {
        fetchProviderDataRequest({
          page: 1,
          limit: to.paginationOptions.rowsPerPage,
          name: to.filter.provider_name,
        });
        return {
          ...to,
          openDialog: false,
          paginationOptions: {
            ...to.paginationOptions,
            page: 1,
          },
        };
      });
      setNotificationData({
        id: generateId(),
        status: "success",
        message: t(kycStrings.textDeletedProviderInfo),
      });
    } else {
      const isInvalid = validateInputProvider(actionData.item);
      if (isInvalid.length) {
        setNotificationData({
          id: generateId(),
          status: "error",
          message: t(isInvalid[0]),
        });
      } else {
        try {
          setActionData((ad) => ({ ...ad, loading: true }));
          const bodyRequest = {
            ...actionData.item,
            status: actionData.item.status === "true",
          };
          if (!bodyRequest.id) {
            delete bodyRequest.id;
          }

          bodyRequest.basic_price = Number(bodyRequest.basic_price);
          bodyRequest.vat_merchant = Number(bodyRequest.vat_merchant);

          await createOrUpdateProviderData({
            ekyc_provider: [bodyRequest],
          });

          setTableOptions((to) => {
            fetchProviderDataRequest({
              page: to.paginationOptions.page,
              limit: to.paginationOptions.rowsPerPage,
              name: to.filter.provider_name,
            });
            return {
              ...to,
              openDialog: false,
            };
          });
          setOpenDialog({
            view: false,
            edit: false,
            delete: false,
            create: false,
          });
          setNotificationData({
            id: generateId(),
            status: "success",
            message: t(
              `Successfully ${id ? "updated" : "created new"} provider data`
            ),
          });
        } catch (error) {
          setNotificationData({
            id: generateId(),
            status: "error",
            message: t(error.response?.data?.response_message),
          });
        } finally {
          setActionData((ad) => ({ ...ad, loading: false }));
        }
      }
    }
  };

  return (
    <>
      <EkycContent
        t={t}
        text={{
          title: kycStrings.textProviderTitle,
          description: t(kycStrings.textProviderDescription),
        }}
        notificationData={notificationData}
        tableOptions={tableOptions}
        fetchDataRequest={fetchProviderDataRequest}
        handleOpenDialogCreate={(action) => handleOpenDrawerAction(action)}
      />
      <DrawerAction
        text={{
          title: openDialog.create
            ? t(kycStrings.textAddProvider)
            : openDialog.edit
            ? t(kycStrings.textEditProvider)
            : t(kycStrings.textProviderDetail),
          subtitle: openDialog.create
            ? t(kycStrings.textSubtitleProvider1)
            : openDialog.edit
            ? t(kycStrings.textSubtitleProvider2)
            : "",
        }}
        openDialog={openDialog.create || openDialog.view}
        handleCloseDrawer={handleCloseDrawerAction}
        content={
          <ProviderSetupData
            actionData={actionData}
            onChangeValue={handleChangeValueData}
            onSubmit={handleSubmitData}
            dialogOptions={{
              openEdit: openDialog.edit,
              handleOpenDrawerAction: (action) =>
                handleOpenDrawerAction(action),
            }}
          />
        }
      />

      <DeletePopUp
        t={t}
        openDialog={openDialog.delete}
        deletedObject="Provider"
        handleCancelAction={() => handleCloseDrawerAction("delete")}
        handleConfirmAction={() => handleSubmitData("delete")}
      />
    </>
  );
}

export default withTranslation("Kyc")(EkycProvider);
