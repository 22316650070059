import { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { Typography } from "@mui/material";
import classes from "../Styles/dashboard.module.css";
import { CustomAreaTooltip, getColor, getLinearColor } from "./TooltipChart";
import { getDataBasedOnYear } from "../Helper/getDashboardData";
import { useTranslation } from "react-i18next";
import strings from "../../General/Constant/Locales/Strings/DashboardStrings";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const getMonthName = (monthNumber) => {
  return months[monthNumber - 1];
};

const CardAreaChart = () => {
  const { t } = useTranslation("Dashboard");
  const [dataYearly, setDataYearly] = useState([]);

  useEffect(() => {
    getDataBasedOnYear(setDataYearly);
  }, []);

  const transformedData = dataYearly.map((item) => ({
    ...item,
    month: getMonthName(item.month),
  }));

  return (
    <div className={classes.card}>
      <div className={classes.spaceBetweenFullRow}>
        <Typography className={classes.textBold16}>
          {t(strings.textActivityOverTime)}
        </Typography>
        <Typography className={classes.textGrayRegular14Color60}>
          *{t(strings.textAllDataDisplayedByYearly)}
        </Typography>
      </div>
      <ResponsiveContainer className={classes.responsiveChartContainer}>
        <AreaChart
          data={transformedData}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          {getLinearColor()}
          <XAxis dataKey="month" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />

          <Legend
            verticalAlign="bottom"
            align="center"
            height={20}
            iconType="circle"
            iconSize={8}
          />
          <Tooltip content={<CustomAreaTooltip />} />
          <Area
            type="monotone"
            dataKey="SSO"
            stackId="1"
            stroke={getColor("SSO")}
            strokeWidth={2}
            fillOpacity={1}
            fill="url(#ssoColor)"
          />
          <Area
            type="monotone"
            dataKey="Digital Card"
            stackId="2"
            stroke={getColor("Digital Card")}
            strokeWidth={2}
            fillOpacity={1}
            fill="url(#digitalCardColor)"
          />
          <Area
            type="monotone"
            dataKey="E-KYC"
            stackId="3"
            stroke={getColor("E-KYC")}
            strokeWidth={2}
            fillOpacity={1}
            fill="url(#ekycColor)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CardAreaChart;
