import React from "react";
import { AppBar, Toolbar, IconButton, Typography, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import classes from "../Styles/dashboard.module.css";
import LanguageSettings from "../../General/Component/LanguageSettings";
import { useSelector } from "react-redux";
import UserSettings from "./UserSettings";

const AppBarDashboard = ({ t, handleDrawerOpen }) => {
  const userManage = useSelector((state) => state.user_data.userData);
  //get initials
  const nameArray = userManage?.fullname.split(" ");
  const initials = nameArray
    .map((name) => name[0])
    .join("")
    .toUpperCase();

  const showDataLogin = () => {
    if (userManage) {
      return (
        <div className={classes.divUserName}>
          {initialsNameCircle()}
          <div className={classes.divRow}>
            <div>
              <Typography className={classes.textRegular14}>
                {userManage.fullname || "Anonymous"}
              </Typography>

              {/* <Typography className={classes.textSemiRegularGray14}>
                {userManage.rolesUserAccess.name || "Anonymous"}
              </Typography> */}
            </div>
            <UserSettings t={t} />
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const initialsNameCircle = () => {
    return (
      <Box className={classes.box1}>
        <Box className={classes.box2}>
          <Typography className={classes.textRegularWhite14}>
            {initials || "AZ"}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      className={`${classes.appBarDashboard}`}
    >
      <Toolbar className={classes.toolbar}>
        <div className={classes.spaceBetweenRow}>
          <IconButton
            color="#707070"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <img
            src="https://res.cloudinary.com/pastisah-id/image/upload/v1715594707/Group_120_f88ccn.png"
            className={classes.imageLogo}
            alt="logo"
          />
        </div>
        <div className={classes.spaceBetweenRow}>
          <LanguageSettings />
          {showDataLogin()}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarDashboard;
