import exportFromJSON from "export-from-json";
import RequestHttpGatewayInternal from "../../General/Helper/RequestHttpGatewayInternal";
import { getAuthenticatedServerRequest } from "../../General/Helper/auth";

const getAllMerchantList = async (
  page,
  rowsPerPage,
  isExport,
  exportType,
  searchValue,
  setLoading,
  setMerchantList,
  setTotalPage,
  setOpenExportData,
  setDataNotification
) => {
  const query = {
    page: page,
    limit: rowsPerPage,
    isExport: isExport,
    merchant_id: searchValue,
    order: "DESC",
  };

  const result = await RequestHttpGatewayInternal({
    method: "GET",
    url: "/merchant/list",
    headers: getAuthenticatedServerRequest(),
    params: query,
  });

  const data = result.data.data;
  if (data) {
    if (isExport) {
      const fileName = "Merchant List";
      exportFromJSON({ data, fileName, exportType });
    } else {
      setMerchantList(data.items);
      setTotalPage(data.meta.totalPages);
    }
  }

  setLoading(false);
  setOpenExportData(false);
};

export default getAllMerchantList;
