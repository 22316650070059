import { useTranslation } from "react-i18next";
import strings from "../Constant/Locales/Strings/AppStrings";

const GetDateNow = () => {
  const { t } = useTranslation("App");

  const days = [
    t(strings.textSunday),
    t(strings.textMonday),
    t(strings.textTuesday),
    t(strings.textWednesday),
    t(strings.textThursday),
    t(strings.textFriday),
    t(strings.textSaturday),
  ];
  const months = [
    t(strings.textJanuary),
    t(strings.textFebruary),
    t(strings.textMarch),
    t(strings.textApril),
    t(strings.textMay),
    t(strings.textJune),
    t(strings.textJuly),
    t(strings.textAugust),
    t(strings.textSeptember),
    t(strings.textOctober),
    t(strings.textNovember),
    t(strings.textDecember),
    ,
  ];

  const today = new Date();
  const dayName = days[today.getDay()];
  const day = today.getDate();
  const monthName = months[today.getMonth()];
  const year = today.getFullYear();

  return `${dayName}, ${day} ${monthName} ${year}`;
};

export default GetDateNow;
