import React, { useEffect, useState } from "react";
import classes from "./Styles/transaction.module.css";
import { Typography } from "@mui/material";
import strings from "../General/Constant/Locales/Strings/TransactionString";

import { TransactionContent } from "./Component/content";
import {
  fetchTransactionData,
  fetchDetailTransaction,
} from "./Helper/http-request";
import exportFromJSON from "export-from-json";
import { ButtonAction } from "../General/Component/ButtonAction";
import { withTranslation } from "react-i18next";
import getDateFromTo from "../General/Helper/getDateFromTo";
import moment from "moment";

const TransactionActivity = (props) => {
  const { t } = props;

  const [notificationData, setNotificationData] = useState({
    status: "",
    message: "",
  });

  const [openDialog, setOpenDialog] = useState({
    view: false,
    create: false,
    delete: false,
  });
  const value_default = "l30day";
  const { dtFrom, dtTo } = getDateFromTo(value_default);

  const [detailTrx, setDetailTrx] = useState([]);

  const [tableOptions, setTableOptions] = useState({
    openDialog: openDialog.view || openDialog.create || openDialog.delete,
    rowsData: [
      strings.textTrxId,
      strings.textProduct,
      strings.textMerchant,
      strings.textFullName,
      strings.textNik,
      strings.textTrxDate,
      strings.textAction,
    ],
    colomnsData: [],
    loading: false,
    paginationOptions: {
      rowsPerPage: 5,
      page: 1,
      totalPages: 0,
      handlePageChange: async (pageValue) => {
        setTableOptions((to) => {
          const request = {
            page: pageValue,
            limit: tableOptions.paginationOptions.rowsPerPage,
          };
          const body = {
            search: to.body.search,
            startDate: to.body.startDate,
            endDate: to.body.endDate,
            isExport: to.body.isExport,
          };
          fetchTransactionActivityData(request, body);
          return {
            ...to,
            paginationOptions: {
              ...to.paginationOptions,
              page: pageValue,
            },
          };
        });
      },
      handleRowsPerPage: async ({ target }) => {
        setTableOptions((to) => {
          const request = {
            page: 1,
            limit: target.value,
          };
          const body = {
            search: to.body.search,
            startDate: to.body.startDate,
            endDate: to.body.endDate,
            isExport: to.body.isExport,
          };
          fetchTransactionActivityData(request, body);

          return {
            ...to,
            paginationOptions: {
              ...to.paginationOptions,
              rowsPerPage: target.value,
            },
          };
        });
      },
    },
    body: {
      search: "",
      startDate: dtFrom,
      endDate: dtTo,
      isExport: false,
    },
  });

  const setIsLoadingData = (value = true) => {
    setTableOptions((t) => {
      return {
        ...t,
        loading: value,
      };
    });
  };

  const fetchTransactionActivityData = async (request, body) => {
    if (!body.isExport) {
      setIsLoadingData();
    }

    const data = await fetchTransactionData(request, body, setNotificationData);

    if (body.isExport) {
      exportFromJSON({
        data,
        fileName: "Transaction Activity",
        exportType: request.exportType,
      });
    } else {
      setTableOptions((to) => {
        return {
          ...to,
          colomnsData: data.items?.map((item) => {
            const {
              transaction_id,
              productType,
              merchantLogo,
              merchantName,
              fullname,
              nik,
              createdAt,
            } = item;
            return [
              transaction_id,
              productType?.name ? productType?.name : "-",
              <div className={classes.divFlexMerchant}>
                <img
                  src={merchantLogo}
                  alt="logo_merchant"
                  className={classes.widthLogoMerchant}
                />
                <Typography>{merchantName ? merchantName : "-"}</Typography>
              </div>,
              fullname ? fullname : "-",
              nik ? nik : "-",
              moment(createdAt).format("YYYY-MM-DD HH:mm:ss"),
              <ButtonAction
                translation={"Transaction"}
                text={strings.textViewDetail}
                handleAction={() => {
                  setOpenDialog({ ...openDialog, view: true });
                  setTableOptions((to) => {
                    return {
                      ...to,
                      openDialog: true,
                    };
                  });
                  fetchDetailTransaction(
                    transaction_id,
                    setDetailTrx,
                    strings,
                    t,
                    setNotificationData
                  );
                }}
              />,
            ];
          }),
          paginationOptions: {
            ...to.paginationOptions,
            page: request.page,
            rowsPerPage: request.limit,
            totalPages: data.meta.totalPages,
          },
          loading: false,
          body: {
            ...to.body,
            search: body.search,
            startDate: body.startDate,
            endDate: body.endDate,
            isExport: body.isExport,
          },
        };
      });
    }
  };

  const handleCloseDrawer = () => {
    setOpenDialog({ ...openDialog, view: false });
    setTableOptions((to) => {
      return {
        ...to,
        openDialog: false,
      };
    });
  };

  useEffect(() => {
    const resultFirstRenderedData = async () => {
      const request = {
        page: tableOptions.paginationOptions.page,
        limit: tableOptions.paginationOptions.rowsPerPage,
      };
      const body = {
        search: tableOptions.body.search,
        startDate: dtFrom,
        endDate: dtTo,
        isExport: tableOptions.body.isExport,
      };
      await fetchTransactionActivityData(request, body);
    };
    resultFirstRenderedData();
  }, []);

  return (
    <>
      <TransactionContent
        t={t}
        text={{
          title: t(strings.textTransaction),
          description: t(strings.textMonitorYourTransaction),
          search: t(strings.textSearch),
          search_date: t(strings.textSelectTransactionDate),
          trans_detail: t(strings.textTransactionDetail),
          l7day: t(strings.textLast7Day),
          l30day: t(strings.textLast30Day),
          custom_date: t(strings.textCustomDate),
          desc_custom_date: t(strings.textDescCustomDate),
          start_date: t(strings.textStartDate),
          end_date: t(strings.textEndDate),
          apply_filter: t(strings.textApplyFilter),
          trx_date: t(strings.textTrxDate),
          invalid_loc: t(strings.textInvalidLocation),
        }}
        detailTrx={detailTrx}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        handleCloseDrawer={handleCloseDrawer}
        notificationData={notificationData}
        setNotificationData={setNotificationData}
        tableOptions={tableOptions}
        fetchDataRequest={fetchTransactionActivityData}
      />
    </>
  );
};

export default withTranslation("Transaction")(TransactionActivity);
