const strings = {
  textDashboard: "Dashboard",
  textTotalProductActivity: "Total Product Activity",
  textSSO: "SSO",
  textDigitalCard: "Digital Card",
  textSSOActivity: "SSO Activity",
  textSSOAuthenticate: "SSO Authenticate",
  textSSOGenerate: "SSO Generate",
  textYearlyTotalActivity: "Yearly Total Activity of",
  textActivityOverTime: "Activity Over Time",
  textAllDataDisplayedBy: "All data displayed by",
  textAllDataDisplayedByYearly: "All data displayed by Yearly",
  textActivitySummary: "Activity Summary",

  //month
  textJanuary: "January",
  textFebruary: "February",
  textMarch: "March",
  textApril: "April",
  textMay: "May",
  textJune: "June",
  textJuly: "July",
  textAugust: "August",
  textSeptember: "September",
  textOctober: "October",
  textNovember: "November",
  textDecember: "December",

  //period
  textDaily: "Daily",
  textWeekly: "Weekly",
  textMonthly: "Monthly",
};

export default strings;
