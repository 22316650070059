const strings = {
  textTransaction: "Transaction",
  textMonitorYourTransaction: "Monitor your transaction activities.",
  textTrxId: "Transaction ID",
  textFullName: "Full Name",
  textNik: "ID Card",
  textEmail: "Email",
  textAddress: "Address",
  textPhoneNumber: "Phone Number",
  textTarget: "Target",
  textScanTime: "Scan Time",
  textAction: "Action",
  textRecordsNotFound: "Records Not Found",
  textViewDetail: "View Detail",
  textProduct: "Product",
  textMerchant: "Merchant",
  textTrxDate: "Transaction Date",
  textSearch: "Search",
  textOrderId: "Order ID",
  textLocation: "Location",
  textTransactionDetail: "Transaction Detail",
  textDob: "Date of Birth",
  textThreshold: "Threshold",
  textFinalScore: "Final Score",
  textPackage: "Package",
  textProvider: "Provider",
  textSelectTransactionDate: "Select Transaction Dates",
  textLast7Day: "Last 7 days",
  textLast30Day: "Last 30 days",
  textCustomDate: "Custom Date",
  textDescCustomDate:
    "Custom period for transaction dates can be up to one month earlier or later than the selected date.",
  textStartDate: "Start date",
  textEndDate: "End date",
  textApplyFilter: "Apply Filter",
  textInvalidLocation: "Invalid Location",
};

export default strings;
