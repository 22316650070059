import { Typography } from "@mui/material";
import classes from "../Styles/general.module.css";
import { Canvas, FormInputCanvas } from "./FormInput";

export const FormView = ({ buttonAction, formOptions }) => {
  return (
    <Canvas
      content={
        <>
          {formOptions?.map((option, key) => {
            return (
              <FormInputCanvas
                key={key}
                content={
                  <>
                    <Typography className={classes.textGray14Color60}>
                      {option?.label}
                    </Typography>
                    <Typography className={classes.textBold16}>
                      {option?.value ? option.value : "-"}
                    </Typography>
                  </>
                }
              />
            );
          })}
          {buttonAction ? buttonAction : null}
        </>
      }
    />
  );
};
