const numberWithThousand = (value) => {
  // Remove all non-digit characters and leading zeros
  const numericValue = value.replace(/^0+/g, "").replace(/\D/g, "");

  // Add dots as thousand separators
  const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  return formattedValue;
};

export default numberWithThousand;
