import { IconButton, InputAdornment, Tooltip } from "@mui/material";
import strings from "../Constant/Locales/Strings/AppStrings";
import classes from "../../Login/Style/login.module.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const PasswordEndAdornment = ({ options }) => {
  return (
    <InputAdornment position="end">
      <Tooltip
        title={
          options?.showPassword
            ? strings.textHidePassword
            : strings.textViewPassword
        }
        componentsProps={{
          tooltip: { className: classes.tooltipStyle },
        }}
      >
        <IconButton
          aria-label="toggle password visibility"
          onClick={options?.handleClickShowPassword}
        >
          {options?.showPassword ? (
            <Visibility className={classes.visibilityIcon} />
          ) : (
            <VisibilityOff className={classes.visibilityIcon} />
          )}
        </IconButton>
      </Tooltip>
    </InputAdornment>
  );
};
