import RequestHttpGatewayInternal from "../../General/Helper/RequestHttpGatewayInternal";
import strings from "../../General/Constant/Locales/Strings/MerchantManageStrings";
import { getAuthenticatedServerRequest } from "../../General/Helper/auth";

const addEditProvider = async (t, providerData, setDataNotification) => {
  const requestBodySubmit = {
    merchant_id: providerData.merchant_id,
    merchant_provider: [
      {
        ...providerData.merchant_provider,
        basic_price: Number(providerData.merchant_provider.basic_price),
        vat_merchant: Number(providerData.merchant_provider.vat_merchant),
        status: providerData.merchant_provider.status === "true" ? true : false,
      },
    ],
  };

  const result = await RequestHttpGatewayInternal({
    method: "POST",
    url: `/merchant-provider/create/update`,
    headers: getAuthenticatedServerRequest(),
    data: requestBodySubmit,
  });

  if (result.data.data) {
    setDataNotification({
      open: true,
      status: "success",
      message: t(strings.textSuccessRegistMerchant),
    });
  }

  return result.data.data;
};

export default addEditProvider;
