import React from "react";
import {
  Autocomplete,
  TextField,
  Box,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import classes from "../Styles/general.module.css";

const MultipleChipSelection = ({
  options,
  selectedItem,
  handleChangeCheckedMenu,
}) => {
  // options contains array of objects with keys menu and values
  // selectedItem contains array of objects with keys menu and values default values is empty array

  const isOptionEqualToValue = (option, value) => {
    return option.menu === value.menu;
  };

  const styles = {
    autocomplete: {
      "& .MuiChip-root": {
        borderRadius: "4px",
      },
    },
  };

  return (
    <Autocomplete
      className={classes.multipleOptions}
      fullWidth
      sx={styles.autocomplete}
      multiple
      options={options}
      getOptionLabel={(option) => option.menu}
      value={selectedItem}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={handleChangeCheckedMenu}
      renderOption={(props, option, { selected }) => (
        <Box
          component="li"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "8px 16px",
          }}
          {...props}
        >
          <ListItemText primary={option.menu} />
          {selected && (
            <ListItemIcon>
              <CheckCircle sx={{ color: "green" }} />
            </ListItemIcon>
          )}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            "& fieldset": { border: "none" },
          }}
          placeholder={
            selectedItem?.length !== options?.length ? "Search..." : null
          }
        />
      )}
      clearIcon={null}
    />
  );
};

export default MultipleChipSelection;
