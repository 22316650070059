import { Box, Modal } from "@mui/material";
import classes from "../Styles/general.module.css";
import CloseIcon from "./CloseIcon";

const ModalPopUp = ({ openDialog, closeDialog, content }) => {
  return (
    <Modal
      open={openDialog}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.boxModal}>
        <div className={classes.divCloseEnd}>
          <CloseIcon handleCloseDialog={closeDialog} />
        </div>
        {content}
      </Box>
    </Modal>
  );
};

export default ModalPopUp;
