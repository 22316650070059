const getDateFromTo = (idLabel) => {
  const dtFrom = new Date();
  const dtTo = new Date();
  if (idLabel === "today") {
    dtFrom.setHours(0);
    dtFrom.setMinutes(0);
    dtFrom.setSeconds(0);

    dtTo.setHours(23);
    dtTo.setMinutes(59);
    dtTo.setSeconds(59);

    return {
      dtFrom,
      dtTo,
    };
  }
  if (idLabel === "yesterday") {
    dtFrom.setDate(dtFrom.getDate() - 1);
    dtFrom.setHours(0);
    dtFrom.setMinutes(0);
    dtFrom.setSeconds(0);

    dtTo.setDate(dtTo.getDate() - 1);
    dtTo.setHours(23);
    dtTo.setMinutes(59);
    dtTo.setSeconds(59);

    return {
      dtFrom,
      dtTo,
    };
  }
  if (idLabel === "l7day") {
    dtFrom.setDate(dtFrom.getDate() - 7);
    dtFrom.setHours(0);
    dtFrom.setMinutes(0);
    dtFrom.setSeconds(0);

    dtTo.setDate(dtTo.getDate());
    dtTo.setHours(23);
    dtTo.setMinutes(59);
    dtTo.setSeconds(59);

    return {
      dtFrom,
      dtTo,
    };
  }

  if (idLabel === "l30day") {
    dtFrom.setDate(dtFrom.getDate() - 30);
    dtFrom.setHours(0);
    dtFrom.setMinutes(0);
    dtFrom.setSeconds(0);

    dtTo.setDate(dtTo.getDate());
    dtTo.setHours(23);
    dtTo.setMinutes(59);
    dtTo.setSeconds(59);

    return {
      dtFrom,
      dtTo,
    };
  }

  if (idLabel === "l90day") {
    dtFrom.setDate(dtFrom.getDate() - 90);
    dtFrom.setHours(0);
    dtFrom.setMinutes(0);
    dtFrom.setSeconds(0);

    dtTo.setDate(dtTo.getDate());
    dtTo.setHours(23);
    dtTo.setMinutes(59);
    dtTo.setSeconds(59);

    return {
      dtFrom,
      dtTo,
    };
  }

  return {
    dtFrom,
    dtTo,
  };
};

export default getDateFromTo;
