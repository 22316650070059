import Cookies from "js-cookie";
import RequestHttpGatewayInternal from "../../General/Helper/RequestHttpGatewayInternal";
import strings from "../../General/Constant/Locales/Strings/UserManageStrings";

const submitUserData = (
  t,
  userData,
  setLoadingSubmit,
  setDataNotification,
  handleCloseDialog,
  getUserList,
  isEdit
) => {
  setLoadingSubmit(true);

  const currentDate = new Date();
  const timestamp = Math.floor(currentDate.getTime() / 1000);

  const requestBody = {
    email: userData.email.toLowerCase(),
    password: userData.password,
    fullname: userData.fullName,
    phone: `+62${userData.phone}`,
    address: userData.address,
    status: userData.status,
    rolesName: userData.rolesName,
    isAdmistrator: userData.isAdmistrator,
  };

  if (isEdit) {
    RequestHttpGatewayInternal.put(`user-access/edit`, requestBody, {
      headers: {
        Authorization: Cookies.get("access_token"),
        "X-Req-Inapass": Cookies.get("X-Req-Inapass"),
        timestamp: timestamp,
      },
    })
      .then((res) => {
        setDataNotification({
          open: true,
          status: "success",
          message: t(strings.textSuccessEditPermission),
        });
        getUserList();
        handleCloseDialog();
      })
      .catch((err) => {
        setDataNotification({
          open: true,
          status: "error",
          message: err.response.data.response_message,
        });
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  } else {
    RequestHttpGatewayInternal.post(`user-access/register`, requestBody, {
      headers: {
        Authorization: Cookies.get("access_token"),
        "X-Req-Inapass": Cookies.get("X-Req-Inapass"),
        timestamp: timestamp,
      },
    })
      .then((res) => {
        setDataNotification({
          open: true,
          status: "success",
          message: t(strings.textSuccessEditPermission),
        });
        getUserList();
        handleCloseDialog();
      })
      .catch((err) => {
        setDataNotification({
          open: true,
          status: "error",
          message: err.response.data.response_message,
        });
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  }
};

export default submitUserData;
