import RequestHttpGatewayInternal from "../../General/Helper/RequestHttpGatewayInternal";
import strings from "../../General/Constant/Locales/Strings/MerchantManageStrings";
import { getAuthenticatedServerRequest } from "../../General/Helper/auth";

const addEditThreshold = async (t, thresholdData, setDataNotification) => {
  const thresholds = thresholdData.request;
  let requestBodySubmit = {
    thresholdValue: Number(thresholds.thresholdValue),
    status: thresholds.status === "true" ? true : false,
    description: thresholds.description,
  };

  if (thresholdData.isEdit) {
    requestBodySubmit["thresholdId"] = Number(thresholds.id);
  } else {
    requestBodySubmit["providerId"] = Number(thresholdData.providerId);
  }

  const result = await RequestHttpGatewayInternal({
    method: thresholdData.isEdit ? "PUT" : "POST",
    url: `/merchant-threshold`,
    headers: getAuthenticatedServerRequest(),
    data: requestBodySubmit,
  });

  if (result.data.data) {
    setDataNotification({
      open: true,
      status: "success",
      message: "Success add threshold data",
    });
  }
};

export default addEditThreshold;
