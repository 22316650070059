const strings = {
  textMerchantManage: "Merchant Management",
  textMerchantManageSubtitle:
    "Manage your merchant management and their provider here.",
  textRegisterMerchant: "Merchant Registration Account",
  textRegisterMerchantSubtitle:
    "Please complete your data below to register an account.",
  textAddProviderSubtitle:
    "Please complete the data below to added provider on merchant.",

  textMerchantLogo: "Merchant Logo",
  textMerchantID: "Merchant ID",
  textCompany: "Company",
  textCompanyName: "Company Name",
  textCompanyEmail: "Company Email",
  textEmail: "Email",
  textAddress: "Address",
  textMerchantProvider: "Merchant Provider",
  textAction: "Action",
  textAddThreshold: "Add Threshold",
  textEditProvider: "Edit Provider",
  textEditThreshold: "Edit Threshold",
  textEditMerchant: "Edit Merchant",
  textAddNewProvider: "Add New Provider",
  textAddNewMerchant: "Add New Merchant",
  textAddProvider: "Add Provider",
  textAddMerchant: "Add Merchant",
  textViewProviderDetail: "View Provider Detail",
  textViewThresholdDetail: "View Threshold Detail",
  textViewMerchantDetail: "View Merchant Detail",
  textPICName: "PIC Name",
  textPhoneNumber: "Phone Number",
  textUploadLogoCompany: "Upload Logo Company",
  textInfoLogoQuality:
    "For better quality, please upload your company logo in PNG/JPG/JPEG format and ensure the size is no larger than 10MB.",

  textSuccessRegistMerchant: "New merchant registration is successful.",
  textProviderName: "Provider Name",
  textSecretKey: "Secret Key",
  textHashKey: "Hash Key",
  textSystemId: "System Id",
  textAPIKey: "API Key",
  textToken: "Token",
  textPassword: "Password",
  textViewPassword: "View Password",
  textHidePassword: "Hide Password",
  textChannelId: "Channel Id",
  textBasicPrice: "Basic Price",
  textVATMerchant: "VAT Merchant",
  textStatus: "Status",
  textDataShowPercentage: "Data will be show in %.",
  textDetailMerchantProvider: "Detail Merchant Provider",
  textListProvider: "List Provider",
  textDetailProviderSubtitle:
    "Please select the provider used by the merchant.",
  textActive: "Active",
  textInactive: "Inactive",
  textDeleteProvider: "Delete Provider",
  textSuccessDeleteProvider: "Merchant provider deletion is successful.",
  textProviderWithoutThreshold:
    "This provider does not have a threshold. Please add threshold to this provider.",
  textChangeLogo: "Replace Logo",
  textRecordsNotFound: "Records Not Found",

  //placeholder
  textPlaceholderMerchantID: "Enter merchant id",
  textPlaceholderCompanyName: "Enter company name",
  textPlaceholderCompanyEmail: "company@gmail.com",
  textPlaceholderCompanyAddress: "Enter company address here",
  textPlaceholderCompanyLogo: "Upload or drag your company logo to this area.",
  textPlaceholderPICName: "Enter PIC name",
  textPlaceholderPhone: "812345690",
  textPlaceholderProviderName: "Enter provider name",
  textPlaceholderSecretKey: "Enter secret key",
  textPlaceholderHashKey: "Enter hash key",
  textPlaceholderSystemId: "Enter system id",
  textPlaceholderAPIKey: "Enter API key",
  textPlaceholderToken: "Enter token",
  textPlaceholderPassword: "Enter password",
  textPlaceholderChannelId: "Enter channel Id",
  textPlaceholderSelectStatus: "Select status",
  // textPlaceholderBasicPrice:""

  //invalid text
  textInvalidEmail: "Invalid e-mail format",
  textInvalidPhoneNumber: "Invalid phone number format",
  textInvalidPhoneNumberLength: "Phone number must be less than 13 digits",
};

export default strings;
