import strings from "../../General/Constant/Locales/Strings/UserManageStrings";

const userListHeaders = (props) => {
  const { t } = props;
  return [
    {
      id: 1,
      label: t(strings.textFullName),
    },
    {
      id: 2,
      label: t(strings.textEmail),
    },
    {
      id: 3,
      label: t(strings.textPhoneNumber),
    },
    {
      id: 4,
      label: t(strings.textAddress),
    },
    {
      id: 5,
      label: t(strings.textRoleName),
    },
    {
      id: 6,
      label: t(strings.textStatus),
    },
    {
      id: 7,
      label: t(strings.textAction),
    },
  ];
};

export default userListHeaders;
