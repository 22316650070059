const ViewIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
  >
    <path
      d="M17.389 9.27219C17.3644 9.21664 16.7688 7.89547 15.4448 6.57148C13.6807 4.80734 11.4525 3.875 8.99999 3.875C6.54749 3.875 4.31929 4.80734 2.55515 6.57148C1.23117 7.89547 0.632806 9.21875 0.611009 9.27219C0.579026 9.34413 0.5625 9.42198 0.5625 9.5007C0.5625 9.57943 0.579026 9.65728 0.611009 9.72922C0.635618 9.78477 1.23117 11.1052 2.55515 12.4292C4.31929 14.1927 6.54749 15.125 8.99999 15.125C11.4525 15.125 13.6807 14.1927 15.4448 12.4292C16.7688 11.1052 17.3644 9.78477 17.389 9.72922C17.421 9.65728 17.4375 9.57943 17.4375 9.5007C17.4375 9.42198 17.421 9.34413 17.389 9.27219ZM8.99999 14C6.83578 14 4.94507 13.2132 3.37992 11.6621C2.73771 11.0235 2.19134 10.2952 1.75781 9.5C2.19123 8.70472 2.73761 7.97645 3.37992 7.33789C4.94507 5.7868 6.83578 5 8.99999 5C11.1642 5 13.0549 5.7868 14.6201 7.33789C15.2635 7.9763 15.8111 8.70457 16.2457 9.5C15.7387 10.4464 13.5302 14 8.99999 14ZM8.99999 6.125C8.33248 6.125 7.67996 6.32294 7.12494 6.69379C6.56993 7.06464 6.13735 7.59174 5.8819 8.20844C5.62645 8.82514 5.55962 9.50374 5.68984 10.1584C5.82007 10.8131 6.14151 11.4145 6.61351 11.8865C7.08551 12.3585 7.68688 12.6799 8.34156 12.8102C8.99625 12.9404 9.67485 12.8735 10.2916 12.6181C10.9083 12.3626 11.4354 11.9301 11.8062 11.375C12.1771 10.82 12.375 10.1675 12.375 9.5C12.3741 8.60518 12.0182 7.74728 11.3855 7.11454C10.7527 6.48181 9.89481 6.12593 8.99999 6.125ZM8.99999 11.75C8.55499 11.75 8.11997 11.618 7.74996 11.3708C7.37995 11.1236 7.09156 10.7722 6.92127 10.361C6.75097 9.9499 6.70641 9.4975 6.79323 9.06105C6.88004 8.62459 7.09434 8.22368 7.409 7.90901C7.72367 7.59434 8.12458 7.38005 8.56104 7.29323C8.9975 7.20642 9.4499 7.25097 9.86103 7.42127C10.2722 7.59157 10.6236 7.87996 10.8708 8.24997C11.118 8.61998 11.25 9.05499 11.25 9.5C11.25 10.0967 11.0129 10.669 10.591 11.091C10.169 11.5129 9.59673 11.75 8.99999 11.75Z"
      fill="#707070"
    />
  </svg>
);

export default ViewIcon;
