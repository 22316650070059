const AddIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
  >
    <path
      d="M15.75 9.5C15.75 9.64918 15.6907 9.79226 15.5852 9.89775C15.4798 10.0032 15.3367 10.0625 15.1875 10.0625H9.5625V15.6875C9.5625 15.8367 9.50324 15.9798 9.39775 16.0852C9.29226 16.1907 9.14918 16.25 9 16.25C8.85082 16.25 8.70774 16.1907 8.60225 16.0852C8.49676 15.9798 8.4375 15.8367 8.4375 15.6875V10.0625H2.8125C2.66332 10.0625 2.52024 10.0032 2.41475 9.89775C2.30926 9.79226 2.25 9.64918 2.25 9.5C2.25 9.35082 2.30926 9.20774 2.41475 9.10225C2.52024 8.99676 2.66332 8.9375 2.8125 8.9375H8.4375V3.3125C8.4375 3.16332 8.49676 3.02024 8.60225 2.91475C8.70774 2.80926 8.85082 2.75 9 2.75C9.14918 2.75 9.29226 2.80926 9.39775 2.91475C9.50324 3.02024 9.5625 3.16332 9.5625 3.3125V8.9375H15.1875C15.3367 8.9375 15.4798 8.99676 15.5852 9.10225C15.6907 9.20774 15.75 9.35082 15.75 9.5Z"
      fill="#707070"
    />
  </svg>
);

export default AddIcon;
